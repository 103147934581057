import DateHelper from '../helpers/dateHelper';
import React from 'react';

import {StoreMonthYear} from '../typescript/storeTypes';

export class IncompleteMonthsToast extends React.PureComponent<Props> {
    sortIncompleteMonths = () => {
        const pad = (n: number) => n < 10 ? '0' + n : n;

        return this.props.incompleteMonths.slice().sort((m1: StoreMonthYear, m2: StoreMonthYear) => {
            if(`${m1.year}${pad(m1.month)}` < `${m2.year}${pad(m2.month)}`) return -1;
            if(`${m1.year}${pad(m1.month)}` > `${m2.year}${pad(m2.month)}`) return 1;
            return 0;
        });
    };

    getMonthButtons = (): React.ReactElement[] => {
        const buttons: React.ReactElement[] = [];

        this.sortIncompleteMonths().map((monthYear, index) => {
            const value = `Go to ${DateHelper.monthName(monthYear.month)} ${monthYear.year}`;
            buttons.push(<input key={monthYear.month + '-' + monthYear.year + '-' + index} className="go-to-month-button" type="submit" value={value} onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => this.props.getTimesheet(monthYear.month, monthYear.year, event as unknown as Event)}/>);
        });

        return buttons;
    };

    render() {
        return (
            <div>
                <div className="incomplete-months-message">You&apos;ve got some incomplete months:</div>
                {this.getMonthButtons()}
            </div>
        );
    }
}

type Props = {
    incompleteMonths: StoreMonthYear[],
    getTimesheet: (month: number, year: number, event: Event) => void
};

export default IncompleteMonthsToast;
