import React from 'react';

class ImportActualsTemplateLegend extends React.PureComponent<any, State> {
    state: State = {
        isLegendTooltipOpen: false
    };

    render() {
        return (
            <div className="import-actuals-template-legend">
                <div className="import-actuals-template-legend-button" onClick={() => this.setState({isLegendTooltipOpen: !this.state.isLegendTooltipOpen})} >
                    <div className="float"><i className="fas fa-info" aria-hidden="true"/> Need some help ?</div>
                    <i className={'fas fa-angle-' + (this.state.isLegendTooltipOpen ? 'up' : 'down')} aria-hidden="true"/>
                </div>
                {this.state.isLegendTooltipOpen ?
                    <div className="import-actuals-template">
                        <div className="import-actuals-template-row rowheader">
                            <div className="import-actuals-template-cell">Username</div>
                            <div className="import-actuals-template-cell tscode">TsCode</div>
                            <div className="import-actuals-template-cell">WorkDay</div>
                            <div className="import-actuals-template-cell">WorkMonth</div>
                            <div className="import-actuals-template-cell">WorkYear</div>
                            <div className="import-actuals-template-cell">ActualHours</div>
                            <div className="import-actuals-template-cell">ExtraHours</div>
                            <div className="import-actuals-template-cell">StandbyHours</div>
                            <div className="import-actuals-template-cell">InterventionHours</div>
                            <div className="import-actuals-template-cell">Comments</div>
                        </div>
                        <div className="import-actuals-template-row">
                            <div className="import-actuals-template-cell">user_name</div>
                            <div className="import-actuals-template-cell tscode">DCG-TsCode-Name</div>
                            <div className="import-actuals-template-cell">10</div>
                            <div className="import-actuals-template-cell">02</div>
                            <div className="import-actuals-template-cell">{new Date().getFullYear()}</div>
                            <div className="import-actuals-template-cell">7.6</div>
                            <div className="import-actuals-template-cell">1.0</div>
                            <div className="import-actuals-template-cell">3.33</div>
                            <div className="import-actuals-template-cell">0.5</div>
                            <div className="import-actuals-template-cell">a comment</div>
                        </div>
                        <div className="import-actuals-template-row">
                            <div className="import-actuals-template-cell">user_name</div>
                            <div className="import-actuals-template-cell tscode">DCG-TsCode-Name</div>
                            <div className="import-actuals-template-cell">11</div>
                            <div className="import-actuals-template-cell">02</div>
                            <div className="import-actuals-template-cell">{new Date().getFullYear()}</div>
                            <div className="import-actuals-template-cell"/>
                            <div className="import-actuals-template-cell">8</div>
                            <div className="import-actuals-template-cell"/>
                            <div className="import-actuals-template-cell">99.99</div>
                            <div className="import-actuals-template-cell"/>
                        </div>
                        <div className="import-actuals-template-row placeholder"/>
                        <div className="import-actuals-template-row">
                            <div className="import-actuals-template-cell required">Required</div>
                            <div className="import-actuals-template-cell required tscode">Required</div>
                            <div className="import-actuals-template-cell required">Required</div>
                            <div className="import-actuals-template-cell required">Required</div>
                            <div className="import-actuals-template-cell required">Required</div>
                            <div className="import-actuals-template-cell">Required *</div>
                            <div className="import-actuals-template-cell">Required *</div>
                            <div className="import-actuals-template-cell">Required *</div>
                            <div className="import-actuals-template-cell">Required *</div>
                            <div className="import-actuals-template-cell optional">Optional</div>
                        </div>
                        <div className="import-actuals-template-row">* A minimum of 1 of the 4 columns is required.</div>
                        <div className="import-actuals-template-row">* A maximum amount of 2 numbers is permitted for the decimal and whole part of hours. (eg. 99.99)</div>
                        <div className="import-actuals-template-row">
                            <div className="import-actuals-template-cell noborder">Example document</div>
                            <div className="import-actuals-template-cell noborder excel-logo"><a href="/resources/Import_Actuals_Example_Template.xlsx"><img src="/images/excel-logo@20px.png"/></a></div>
                        </div>
                    </div>
                : null}
            </div>
        );
    }
}

type State = {
    isLegendTooltipOpen: boolean
};

export default ImportActualsTemplateLegend;
