import React from 'react';

import ManageClientTimesheetModal from './ManageClientTimesheetModal';


class ManageClientTimesheet extends React.PureComponent<Props, State> {
    state = {
        isManageClientTimesheetModalOpen: false
    };

    render() {
        return (
            <div className="manage-client-timesheet">
                <input className="manage-client-timesheet-submit input-submit-darkskyblue"
                    type="submit" value="MANAGE TIMESHEETS FROM CLIENT"
                    onClick={() => this.setState({isManageClientTimesheetModalOpen: !this.state.isManageClientTimesheetModalOpen})}
                    disabled={!this.props.canUpload ? true : undefined}/>
                {this.state.isManageClientTimesheetModalOpen ?
                    <ManageClientTimesheetModal closeModal={() => this.setState({isManageClientTimesheetModalOpen: false})}/>
                : null}
            </div>
        );
    }
}

type Props = {
    canUpload: boolean
};

type State = {
    isManageClientTimesheetModalOpen: boolean
};

export default ManageClientTimesheet;
