import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import userApi from '../api/userApi';

import { MessageTypes } from '../types/messageTypes';
import { StoreVisibleUser } from '../typescript/storeTypes';
import MessageHelper from '../helpers/messageHelper';


function* loginUser(action: Action): Generator<any, any, any> {
    try {
        const user = yield call(userApi.loginUser, action.payload);

        localStorage.setItem('login_Username', action.payload.username.toUpperCase());
        yield put({type: ActionTypes.USER_LOGIN_SUCCEEDED, payload: user});
    } catch (e) {
        yield put({type: ActionTypes.USER_LOGIN_FAILED, payload: e.message});
    }
}

function* loginAdfsUser(action: Action): Generator<any, any, any> {
    try {
        const user = yield call(userApi.loginAdfsUser, action.payload);

        localStorage.setItem('login_Username', user.username.toUpperCase());
        yield put({type: ActionTypes.USER_ADFS_LOGIN_SUCCEEDED, payload: user});
    } catch (e) {
        yield put({type: ActionTypes.USER_ADFS_LOGIN_FAILED, payload: e.message});
    }
}

function* forceRefreshToken(): Generator<any, any, any> {
    try {
        yield call(userApi.forceRefreshToken);
    } catch (e) {
        yield put({type: ActionTypes.USER_LOGIN_FAILED, payload: e});
    }
}

function* getUser(action: Action): Generator<any, any, any> {
    try {
        const user = yield call(userApi.getUser, action.payload);
        yield put({type: ActionTypes.USER_SUCCEEDED, payload: user});
    } catch (e) {
        yield put({type: ActionTypes.USER_FAILED, payload: e.message});
    }
}

function* resetPassword(action: Action): Generator<any, any, any> {
    try {
        const result = yield call(userApi.resetPassword, action.payload);
        MessageHelper.showMessage({type: MessageTypes.INFO, message: result.notification});

        yield put({type: ActionTypes.RESET_PASSWORD_SUCCEEDED, payload: null});
    } catch (e) {
        //do nothing yet
    }
}

function* getPermissions(): Generator<any, any, any> {
    try {
        const permissions = yield call(userApi.getPermissions);
        yield put({type: ActionTypes.PERMISSIONS_SUCCEEDED, payload: permissions});
    } catch (e) {
        yield put({type: ActionTypes.PERMISSIONS_FAILED, payload: e.message});
    }
}

function* getVisibleUsers(): Generator<any, any, any> {
    try {
        const visibleUsers = yield call(userApi.getVisibleUsers);

        const filtered = visibleUsers.filter((e: StoreVisibleUser) => e.username !== '');

        yield put({type: ActionTypes.VISIBLE_USERS_SUCCEEDED, payload: filtered});
    } catch (e) {
        yield put({type: ActionTypes.VISIBLE_USERS_FAILED, payload: e.message});
    }
}

function* getVisibleSuppliers(): Generator<any, any, any> {
    try {
        const visibleSuppliers = yield call(userApi.getVisibleSuppliers);
        yield put({type: ActionTypes.VISIBLE_SUPPLIERS_SUCCEEDED, payload: visibleSuppliers});
    } catch (e) {
        yield put({type: ActionTypes.VISIBLE_SUPPLIERS_FAILED, payload: e.message});
    }
}

function* getInvoiceViewerSuppliers(): Generator<any, any, any> {
    try {
        const suppliers = yield call(userApi.getInvoiceViewerSuppliers);
        yield put({type: ActionTypes.INVOICEVIEWER_SUPPLIERS_SUCCEEDED, payload: suppliers});
    } catch (e) {
        yield put({type: ActionTypes.INVOICEVIEWER_SUPPLIERS_FAILED, payload: e.message});
    }
}

function* getActiveUsersWithGroups(action: Action): Generator<any, any, any> {
    try {
        const userGroups = yield call(userApi.getActiveUsersWithGroups, action.payload);
        yield put({type: ActionTypes.USER_GROUPS_SUCCEEDED, payload: userGroups});
    } catch (e) {
        yield put({type: ActionTypes.USER_GROUPS_FAILED, payload: e.message});
    }
}

function* userSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.FORCE_REFRESH_TOKEN_REQUESTED, forceRefreshToken);
    yield takeEvery(ActionTypes.USER_LOGIN_REQUESTED, loginUser);
    yield takeEvery(ActionTypes.USER_ADFS_LOGIN_REQUESTED, loginAdfsUser);
    yield takeEvery(ActionTypes.USER_REQUESTED, getUser);
    yield takeEvery(ActionTypes.PERMISSIONS_REQUESTED, getPermissions);
    yield takeEvery(ActionTypes.VISIBLE_USERS_REQUESTED, getVisibleUsers);
    yield takeEvery(ActionTypes.VISIBLE_SUPPLIERS_REQUESTED, getVisibleSuppliers);
    yield takeEvery(ActionTypes.INVOICEVIEWER_SUPPLIERS_REQUESTED, getInvoiceViewerSuppliers);
    yield takeEvery(ActionTypes.RESET_PASSWORD_REQUESTED, resetPassword);
    yield takeEvery(ActionTypes.USER_GROUPS_REQUESTED, getActiveUsersWithGroups);
}

export default userSaga;
