class FileHelper {
    static enableReadAsBinaryStringOnFileReaderForLegacy() { // Internet Explorer
        if (FileReader.prototype.readAsBinaryString === undefined) {
            FileReader.prototype.readAsBinaryString = (fileData) => {
                let binary = '';
                const pt = (): any => this;
                const reader = new FileReader();
                reader.onload = () => {
                    const bytes = new Uint8Array(reader.result as any);
                    const length = bytes.byteLength;
                    for (let i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    //this.result  - readonly so assign content to another property
                    pt().content = binary;
                    pt().onload();
                };
                reader.readAsArrayBuffer(fileData);
            };
        }
    }
}

export default FileHelper;
