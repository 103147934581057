import {AnyAction, Dispatch, bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {clearActivePreset, deletePreset, getPresets, loadPreset, savePreset} from '../actions/presetActions';
import ClosableModal from './ClosableModal';
import ObjectHelper from '../helpers/objectHelper';
import React, {PropsWithChildren} from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import Select, {OptionProps} from 'react-select';
import TextInput from './TextInput';

import {SelectedOptionInfo} from '../typescript/infoTypes';
import {StoreDisplayProperty, StoreSavedPreset, StoreState} from '../typescript/storeTypes';

export class Preset extends React.PureComponent<Props & MappedProps, State> {
    state: State = {
        isModalOpen: false,
        presetName: ''
    };

    savePreset = () => {
        this.props.savePreset(this.props.location, this.state.presetName, this.props.presetData);
        this.props.getPresets();
        this.props.loadPreset(this.props.location, this.state.presetName, this.props.presetData);
        this.setState({isModalOpen: false});
    };

    onPresetChange = (selection: SelectedOptionInfo | SelectedOptionInfo[] | null) => {
        if(selection === null || Array.isArray(selection)) return;
        this.props.loadPreset(this.props.location, selection.label, selection.value);
    };

    getPresetsForSelect = (): SelectedOptionInfo[] => {
        return this.props.presets.map(p => {
            return {label: p.name, value: p.data};
        });
    };

    getPresetForDisplay = (): StoreDisplayProperty => {
        const displayObject: StoreDisplayProperty = { property: '', displayName: ''};

        this.props.displayProperties.forEach(p => {
            displayObject[p.displayName] = this.props.presetData[p.property].map((pr: { value: any }) => pr.value);
        });

        return displayObject;
    };

    renderPreset = () => {
        const displayObject = this.getPresetForDisplay();
        const items: React.ReactElement[] = [];
        Object.keys(displayObject).forEach((p,i) =>
            {
                if(Array.isArray(displayObject[p]) && displayObject[p].length > 0) {
                    items.push(<div key={`preset__modal_container_showpreset__title__${p}__${i}`}>
                                    <div className="preset__modal_container_showpreset__title">{p}</div>
                                    <div>{displayObject[p].map((d: React.ReactNode, i: number) => <div key={i} className="preset__modal_container_showpreset__item">
                                            {d}
                                        </div>)}
                                    </div>
                                </div>);
                }
            }
        );
        return items;
    };

    onDeletePreset = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, name: string) => {
        event.stopPropagation();
        this.props.deletePreset(this.props.location, name);
        this.props.getPresets();
        if(this.props.currentPreset.name === name) {
            this.props.clearActivePreset();
        }
    };

    onPresetNameChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
        this.setState({presetName: event.currentTarget.value});
    };

    saveOnEnter =  (event: KeyboardEvent): void => {
        if (event.key === 'Enter' && this.state.presetName !== '') {
            this.savePreset();
        }
    };

    componentDidUpdate(prevProps: Props & MappedProps): void {
        if(prevProps.currentPreset !== this.props.currentPreset) {
            this.props.onPresetChange(this.props.currentPreset.data);
        }
    }

    componentDidMount() {
        this.props.getPresets();
        this.props.clearActivePreset();

        (document.body as any).addEventListener('keydown', this.saveOnEnter);
    }

    componentWillUnmount() {
        (document.body as any).removeEventListener('keydown', this.saveOnEnter);
    }

    render() {
        const PresetOption = (props: PropsWithChildren<OptionProps<{ label: string; value: any }, false>>): any => {
            return (<div className="Select-value" onClick={() => props.setValue(props.data, 'select-option')}>
                <div className={`Select-value__option ${props.data.label === this.props.defaultEmptyPreset.name ? 'default': ''}`}>{props.data.label}</div>
                {props.data.label !== this.props.defaultEmptyPreset.name ?
                    <div className="Select-value__action" onClick={(ev) => this.onDeletePreset(ev, props.data.label)}><i className="fas fa-times"/></div>
                    : null
                }
            </div>);
        };

        return (
            <div className={`preset ${this.props.location}`}>
                {this.state.isModalOpen ?
                <ClosableModal className="preset__modal" title="SAVE CUSTOM PRESET" closeModal={() => this.setState({isModalOpen: false})}>
                    <div className="preset__modal_container">
                        <div className="preset__modal_container_showpreset">
                            <ScrollBar className="preset__modal_container_showpreset--scrollheight">
                                {this.renderPreset()}
                            </ScrollBar>
                        </div>
                        <TextInput name="presetName" placeholder="Enter a name for your custom preset" onChange={this.onPresetNameChange} value={this.state.presetName} />
                        <button disabled={this.state.presetName === ''} onClick={this.savePreset}>Save</button>
                    </div>
                </ClosableModal>: null}
                <div>
                    <Select name="preset__select" className="preset__select" classNamePrefix="Select"
                                value={{label: this.props.currentPreset.name, value: this.props.currentPreset.data === undefined ? this.props.defaultEmptyPreset.data : this.props.currentPreset.data}}
                                isClearable={false}
                                isSearchable={false}
                                components={{ Option: PresetOption}}
                                onChange={(opt) => this.onPresetChange(opt as SelectedOptionInfo)}
                                options={this.getPresetsForSelect()}/>
                </div>
                <button type="submit" className="preset__submit" disabled={ObjectHelper.isEmpty(this.props.presetData)}
                    onClick={() => this.setState({isModalOpen: true, presetName: this.props.currentPreset.name})}>
                        <i className="fas fa-tasks"/>
                </button>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    location: string,

    displayProperties: StoreDisplayProperty[],
    defaultEmptyPreset: StoreSavedPreset,
    presetData: any,

    onPresetChange: (data: any) => void
};

type State = {
    isModalOpen: boolean,
    presetName: string
};

function mapStateToProps(state: StoreState, props: Props) {
    return {
        currentPreset: state.userPresets.activePreset,
        presets: [props.defaultEmptyPreset, ...state.userPresets.presets.filter(p => p.location.toLowerCase() === props.location.toLowerCase())]
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({loadPreset, getPresets, savePreset, deletePreset, clearActivePreset}, dispatch);
}

export default connector(Preset);
