import {ConnectedProps, connect } from 'react-redux';
import { WithRouterProps, withRouter } from '../../../common/WithRouter';
import Identity from './identity/Identity';
import Profile from './profile/Profile';
import React from 'react';
import TimesheetNavigation from '../content/timesheet/TimesheetNavigation';
import Weekend from './weekend/Weekend';

import { LocationTypes } from '../../../types/locationTypes';
import {Period} from '../../../typescript/dateTypes';
import {StoreState} from '../../../typescript/storeTypes';
import DateHelper from '../../../helpers/dateHelper';

export class Header extends React.Component<Props> {

    resizeElementOnScroll = (elementIds: string[]) => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop;
        const shrinkOn = 35;

        elementIds.forEach((id: string) => {
            const element = document.getElementById(id);

            if(!element) return;
            if (distanceY >= shrinkOn) {
                element.classList.add('small');
            } else {
                element.classList.remove('small');
            }
        });
    };

    moveElementsOnResize = () => {
        const distanceY = window.pageYOffset || (document.documentElement === null ?  0 : document.documentElement.scrollTop);

        let element = document.getElementById('js-header');
        if(!element) return;
        const headerHeight = element.offsetHeight;
        element.style.top = `${distanceY}px`;

        element = document.getElementById('js-timesheetnavigation');
        if(!element) return;
        if (window.getComputedStyle(element).position === 'fixed') {
            element.style.top = '';
        } else {
            element.style.top = `${(headerHeight + distanceY)}px`;
        }
    };

    resizeHeaderOnScroll = () => {
        this.resizeElementOnScroll(['js-header', 'js-timesheetnavigation', 'js-profile-backdrop', 'js-timesheetelement']);
        this.moveElementsOnResize();
    };

    getWeekNumberText = (period: Period) => {
        const weekNumber = DateHelper.getWeekNumber(DateHelper.getNormalizedDate(new Date(period.days[0].dateString)));
        return `Week ${weekNumber} (${DateHelper.getFullFormattedDate(period.startDate,' ')} - ${DateHelper.getFullFormattedDate(period.endDate,' ')})`;
    };

    isHome = () => {
        return this.props.location.pathname === LocationTypes.HOME;
    };

    getHeader = () => {
        return (<div id="js-header" className="header">
                    <Identity/>
                    <Weekend/>
                    <Profile/>
                </div>);
    };

    componentDidMount() {
        window.addEventListener('scroll', this.resizeHeaderOnScroll);
        window.addEventListener('resize', this.moveElementsOnResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.resizeHeaderOnScroll);
        window.removeEventListener('resize', this.moveElementsOnResize);
    }

    render() {
        return this.isHome() ? (
            <div className="topheader">
                {this.getHeader()}
                <TimesheetNavigation/>
                {!this.props.settings.monthViewSelected ? (<div className="weekNumber">{this.getWeekNumberText(this.props.period)}</div>) : ''}
            </div>
        ) : this.getHeader();
    }
}

const connector = connect(mapStateToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState, props: WithRouterProps) {
    return {
        period: state.timesheet.period,
        settings: state.settings,
        location: props.location // routed
    };
}

export default withRouter(connector(Header));
