import React from 'react';

import ApiHelper from '../../../../helpers/apiHelper';
import TimesheetCodeDayAddHoursComment from './TimesheetCodeDayAddHoursComment';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {Day} from '../../../../typescript/dateTypes';
import {MultiEdit, NavigationEvent} from '../../../../typescript/timesheetTypes';
import {StoreSettings, StoreTimesheetEntry} from '../../../../typescript/storeTypes';
import HourHelper from '../../../../helpers/hourHelper';
import ObjectHelper from '../../../../helpers/objectHelper';
import WindowHelper from '../../../../helpers/windowHelper';

class TimesheetCodeDay extends React.PureComponent<Props, State> {
    state: State = {
        entry: Object.assign({}, this.props.entry),
        normalHours: this.props.entry.normalHours ? this.props.entry.normalHours.toString() : '',
        extraHours: this.props.entry.extraHours ? this.props.entry.extraHours.toString() : '',
        standByHours: this.props.entry.standByHours ? this.props.entry.standByHours.toString() : '',
        interventionHours: this.props.entry.interventionHours ? this.props.entry.interventionHours.toString() : '',
        changeRequested: false,
        commentVisible: false,
        scrollIntoView: false,
        isActive: false
    };

    dayHoursContainer: HTMLElement | undefined;
    normalHoursInput: HTMLInputElement | undefined;
    normalHoursExpandedInput: HTMLInputElement | undefined;
    extraHoursExpandedInput: HTMLInputElement | undefined;
    standByHoursExpandedInput: HTMLInputElement | undefined;
    interventionHoursExpandedInput: HTMLInputElement | undefined;

    onCommentAdd = (): void => {
        if (this.props.isCompleted || this.props.isReadOnly || this.props.commentMandatory) return;

        if (this.state.commentVisible) {
            this.onCommentCancel();
        } else {
            this.setState({commentVisible: true});
        }
    };

    onCommentCancel = (): void => {
        this.setState({commentVisible: false});
        this.selectActiveInputLevel();
    };

    onEntryExpand = (): void => {
        this.props.onEntryExpand(true);
    };

    onEntryChange = (entry: StoreTimesheetEntry): void => {
        if (this.state.changeRequested) return;

        if (!TimesheetHelper.changeInEntries(entry, this.props.entry)) return;

        this.setState({changeRequested: true});
        this.props.onEntryChange(entry, this.props.day);
    };

    onEntryChangeDebounce: (entry: StoreTimesheetEntry) => void = ApiHelper.debounce(this.onEntryChange, 1000);

    onNavigateEntry = (event: NavigationEvent & { currentTarget: HTMLInputElement } | React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        let level = 0;

        this.setState({scrollIntoView: !(event.type === 'click' || event.type.startsWith('mouse'))});

        switch (event.currentTarget) {
            case this.normalHoursInput:
                level = 1;
                break;
            case this.normalHoursExpandedInput:
                level = 2;
                break;
            case this.extraHoursExpandedInput:
                level = 3;
                break;
            case this.standByHoursExpandedInput:
                level = 4;
                break;
            case this.interventionHoursExpandedInput:
                level = 5;
                break;
        }

        // This is here because of an issue in Edge. (SetState Batching messes up UI rendering (i think))
        if ((event as NavigationEvent).key === ' ' && event.type === 'keydown'  && level > 1 && this.normalHoursInput) this.normalHoursInput.select();

        this.props.onNavigateEntry(event as NavigationEvent, this.props.dayIndex, this.props.day, level);
    };

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        if (this.state.changeRequested) return;

        if(event.currentTarget.value.length > 1 && ObjectHelper.charAt(event.currentTarget.value, 0) === '.') {
            this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);
            return;
        }

        if (event.currentTarget.value.length === 1 && (ObjectHelper.charAt(event.currentTarget.value, 0) === ',' ||  ObjectHelper.charAt(event.currentTarget.value, 0) === '.')) {
            event.currentTarget.value = '0.';
        }

        if (HourHelper.isValidHour(event.currentTarget.value)) {
            event.currentTarget.value = HourHelper.getHoursInNumberFormat(event.currentTarget.value);

            const entry = Object.assign({}, this.state.entry);
            (entry  as {[key: string]: any})[event.currentTarget.name] = event.currentTarget.value ? parseFloat(event.currentTarget.value) : 0;
            entry.totalHours = entry.normalHours + entry.extraHours + entry.standByHours + entry.interventionHours;

            if ((entry  as {[key: string]: any})[event.currentTarget.name] !== (this.state.entry  as {[key: string]: any})[event.currentTarget.name]) {
                this.setState({entry});
                 this.onEntryChangeDebounce(entry);
            }

            this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);
        }
    };

    onBlur = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        this.onEntryChange(this.state.entry);

        if (event.currentTarget.value.endsWith('.') || event.currentTarget.value.endsWith(':')) {
            event.currentTarget.value = event.currentTarget.value.slice(0, -1);
            this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);
        }
    };

    scrollIntoView = (input: HTMLInputElement): void => {
        if (this.state.scrollIntoView) {
            if (input) input.scrollIntoView(false);
            this.setState({scrollIntoView: false});
        }
    };

    getOffset = (el: any) => {
        let x = 0;
        let y = 0;
        while(el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            x += el.offsetLeft - el.scrollLeft;
            y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: y, left: x };
    };

    selectActiveInputLevel = (): void => {
        if (this.props.isActive) {
            switch (this.props.activeRowLevel) {
                case 1:
                    if(!this.normalHoursInput) return;
                    if (!this.isCommentMandatoryOpen() && this.normalHoursInput) {
                        this.normalHoursInput.select();
                    }

                    this.scrollIntoView(this.normalHoursInput);
                    if (this.props.settings.timesheetCodeOrder) {
                        if (this.normalHoursInput && this.normalHoursInput.getBoundingClientRect().top + 60 > window.innerHeight) {
                            window.scrollTo(this.getOffset(this.normalHoursInput).left, window.pageYOffset + 40);
                        }
                    } else {
                        if (!WindowHelper.isElementInViewport(this.normalHoursInput)) {
                            window.scrollTo(this.getOffset(this.normalHoursInput).left,this.getOffset(this.normalHoursInput).top - 999);
                        }
                    }
                    break;
                case 2:
                    if(!this.normalHoursExpandedInput) return;
                    if (!this.isCommentMandatoryOpen() && this.normalHoursExpandedInput) {
                        this.normalHoursExpandedInput.select();
                    }
                    this.scrollIntoView(this.normalHoursExpandedInput);
                    if (!WindowHelper.isElementInViewport(this.interventionHoursExpandedInput)) {
                        if (this.interventionHoursExpandedInput) this.interventionHoursExpandedInput.scrollIntoView(false);
                        if (this.props.settings.timesheetCodeOrder) {
                            window.scrollTo(this.getOffset(this.interventionHoursExpandedInput).left, window.pageYOffset + 30);
                        } else {
                            window.scrollTo(this.getOffset(this.interventionHoursExpandedInput).left,this.getOffset(this.interventionHoursExpandedInput).top - 999);
                        }
                    }
                    break;
                case 3:
                    if(!this.extraHoursExpandedInput) return;
                    if (!this.isCommentMandatoryOpen() && this.extraHoursExpandedInput) {
                        this.extraHoursExpandedInput.select();
                    }
                    this.scrollIntoView(this.extraHoursExpandedInput);
                    if (!WindowHelper.isElementInViewport(this.extraHoursExpandedInput)) {
                        if (!this.props.settings.timesheetCodeOrder) {
                            window.scrollTo(this.getOffset(this.interventionHoursExpandedInput).left,this.getOffset(this.interventionHoursExpandedInput).top - 999);
                        }
                    }
                    break;
                case 4:
                    if(!this.standByHoursExpandedInput) return;
                    if (!this.isCommentMandatoryOpen() && this.standByHoursExpandedInput) {
                        this.standByHoursExpandedInput.select();
                    }
                    this.scrollIntoView(this.standByHoursExpandedInput);
                    if (!WindowHelper.isElementInViewport(this.standByHoursExpandedInput)) {
                        if (!this.props.settings.timesheetCodeOrder) {
                            window.scrollTo(this.getOffset(this.interventionHoursExpandedInput).left,this.getOffset(this.interventionHoursExpandedInput).top - 999);
                        }
                    }
                    break;
                case 5:
                    if(!this.interventionHoursExpandedInput) return;
                    if (!this.isCommentMandatoryOpen() && this.interventionHoursExpandedInput) {
                        this.interventionHoursExpandedInput.select();
                    }
                    this.scrollIntoView(this.interventionHoursExpandedInput);
                    if (!WindowHelper.isElementInViewport(this.interventionHoursExpandedInput)) {
                        if (!this.props.settings.timesheetCodeOrder) {
                            window.scrollTo(this.getOffset(this.interventionHoursExpandedInput).left,this.getOffset(this.interventionHoursExpandedInput).top - 999);
                        }
                    }
                    break;
            }
        }
    };

    getHoursInputFixedClassNames = (): string => {
        let className = '';

        if ((this.props.isReadOnly && this.props.entry !== TimesheetHelper.defaultEntry) || this.props.isCompleted || !this.props.isTsCodeActive)
            className += ' readonly';

        if (this.props.isWeekend)
            className += ' weekend';

        if (this.props.isVacationOrIllness && this.props.entry !== TimesheetHelper.defaultEntry || !this.props.isTsCodeActive)
            className += ' vacation';

        return className;
    };

    getHoursInputFlexibleClassNames = (level: number): string => {
        let className = '';

        if (this.props.activeRowLevel === level) {
            className += ' active-row-level';

            if (this.props.isActive)
                className += ' active';
        }

        if (this.props.multiSelected && level === 1) {
            className += ' multi-selected';

            if (this.props.multiSelectLeftBorder)
                className += ' left-border';

            if (this.props.multiSelectRightBorder)
                className += ' right-border';
        }

        if (this.state.changeRequested)
            className += ' change-requested';

        return className;
    };

    getHoursInputClassNames = (level: number): string => {
        let className = 'hours-input';

        className += this.getHoursInputFixedClassNames();

        if (TimesheetHelper.hasExtraHours(this.state.entry) && level === 1)
            className += ' extra';

        className += this.getHoursInputFlexibleClassNames(level);

        return className;
    };

    getHours = (): string => {
        switch (this.props.activeRowLevel) {
            case 1:
            case 2: {
                return this.state.normalHours;
            }
            case 3: {
                return this.state.extraHours;
            }
            case 4: {
                return this.state.standByHours;
            }
            case 5: {
                return this.state.interventionHours;
            }
        }
        return '';
    };

    onSubmit = (hours: string, comments: string | undefined) => {
        const entry: StoreTimesheetEntry = Object.assign({}, this.state.entry);

        entry.comments = comments;

        switch (this.props.activeRowLevel) {
            case 1:
            case 2: {
                entry.normalHours = parseFloat(hours);
                this.setState({normalHours: hours});
                break;
            }
            case 3: {
                entry.extraHours = parseFloat(hours);
                this.setState({extraHours: hours});
                break;
            }
            case 4: {
                entry.standByHours = parseFloat(hours);
                this.setState({standByHours: hours});
                break;
            }
            case 5: {
                entry.interventionHours = parseFloat(hours);
                this.setState({interventionHours: hours});
                break;
            }
        }

        entry.totalHours = entry.normalHours + entry.extraHours + entry.standByHours + entry.interventionHours;

        this.onEntryChange(entry);
    };

    isCommentMandatoryOpen = (): boolean => this.props.isActive && this.props.commentMandatory && !this.props.multiSelectEntryPoint;

    onDayHoursClick = (event: Event & { keepEntryActive?: boolean }): void => {
        event.keepEntryActive = true;
    };

    componentDidMount() {
        (this.dayHoursContainer as HTMLElement).addEventListener('mousedown', this.onDayHoursClick);
        this.selectActiveInputLevel();
    }

    componentWillUnmount() {
        (this.dayHoursContainer as HTMLElement).removeEventListener('mousedown', this.onDayHoursClick);
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.isActive) {
            if (!prevProps.isActive || prevProps.activeRowLevel !== this.props.activeRowLevel) {
                if (this.props.activeRowLevel === 1) {
                    if (this.state.entry.normalHours !== this.state.entry.totalHours) {
                        if (!this.props.expanded) {
                            this.onEntryExpand();
                        } else {
                            this.props.onNavigateEntry(({type: 'click', key: '', shiftKey: false, altKey: false, ctrlKey: false, preventDefault: () => { return; }}), this.props.dayIndex, this.props.day, 2);
                        }
                        return;
                    }
                }

                if((!prevProps.setActiveEntryRequested && !this.props.setActiveEntryRequested) || !this.state.commentVisible) {
                    this.selectActiveInputLevel();
                }
            }
        }

        if(TimesheetHelper.changeInEntries(prevProps.entry, this.props.entry)) {
            this.setState({
                normalHours: HourHelper.getHoursAsText(this.props.entry.normalHours, ''),
                interventionHours: HourHelper.getHoursAsText(this.props.entry.interventionHours, ''),
                extraHours: HourHelper.getHoursAsText(this.props.entry.extraHours, ''),
                standByHours: HourHelper.getHoursAsText(this.props.entry.standByHours, ''),
            });
        }
    }

    onMultiEditSubmit = (hours: string, comments: string | undefined): void => {
        this.props.onMultiEditSubmit({normalHours: hours, comments: comments  ? comments : ''});
    };

    static getDerivedStateFromProps(nextProps: Props, state:State) {
        let intermediateState = null;
        if (!nextProps.isActive && state.commentVisible) {
            intermediateState = Object.assign({}, intermediateState === null ?  {} : intermediateState, {commentVisible: false});
        }

        if(state.entry !== nextProps.entry) {
            intermediateState = Object.assign({}, intermediateState === null ?  {} : intermediateState, {changeRequested: false, entry: nextProps.entry});
        }

        return intermediateState;
    }

    render() {
        return (
            <div
                className={'day-hours-container' + (!this.props.isReadOnly && !this.props.isCompleted && this.props.isTsCodeActive ? ' editable' : '')}
                ref={(div: HTMLDivElement) => this.dayHoursContainer = div}>
                <div className={(this.props.settings.monthViewSelected ? 'day-hours' : 'day-hours-weekly') +
                     ((this.props.settings.showCommentTooltips || this.props.isReadOnly || this.props.isCompleted) && this.props.entry.comments ? this.props.settings.monthViewSelected ? ' tooltip-data-content' : ' tooltip-data-content-weekly' : '')}
                     data-content={this.state.entry.comments}>
                    <input id={`normalHoursInput-${this.props.tsCodeId}-${this.props.day.dayOfMonth}`}
                           ref={(input: HTMLInputElement) => this.normalHoursInput = input}
                           name="normalHours"
                           className={this.getHoursInputClassNames(1)}
                           value={(!TimesheetHelper.hasExtraHours(this.state.entry) ? this.state.normalHours : +this.state.entry.totalHours.toFixed(2))}
                           onChange={this.onChange}
                           onClick={this.onNavigateEntry}
                           onKeyDown={this.onNavigateEntry}
                           onMouseDown={this.onNavigateEntry}
                           onMouseEnter={this.onNavigateEntry}
                           onMouseLeave={this.onNavigateEntry}
                           onMouseUp={this.onNavigateEntry}
                           onDoubleClick={this.onCommentAdd}
                           onBlur={this.onBlur}
                           readOnly={this.props.isReadOnly || this.props.isCompleted || this.props.commentMandatory || !this.props.isTsCodeActive}
                           autoComplete="off"/>
                    {this.state.entry.comments ? <i className="fas fa-circle comment-indicator "/> : null}
                </div>
                {this.props.isActive && !this.props.expanded && !this.props.commentMandatory ?
                    <div className={this.props.settings.monthViewSelected ? 'actions' : 'actions-weekly'}>
                        <div>
                            <i id="comment" className="fas fa-comment add-comment" aria-hidden="true"
                               onClick={this.onCommentAdd}/>
                            <i className="fas fa-plus add-extra-hours" aria-hidden="true"
                               onClick={this.onEntryExpand}/>
                        </div>
                    </div> : null}
                {this.props.isActive && this.state.commentVisible ?
                    <TimesheetCodeDayAddHoursComment
                        commentMandatory={false}
                        comments={this.state.entry.comments}
                        level={this.props.activeRowLevel}
                        getHours={this.getHours}
                        onSubmit={this.onSubmit}
                        resetToDefaultEntry={this.onCommentCancel}/> : null}
                {(this.isCommentMandatoryOpen()) ?
                    <TimesheetCodeDayAddHoursComment
                        commentMandatory
                        comments={this.state.entry.comments}
                        level={this.props.activeRowLevel}
                        getHours={this.getHours}
                        onSubmit={this.onSubmit}
                        resetToDefaultEntry={this.props.resetToDefaultEntry}/> : null}
                {this.props.multiSelectEntryPoint ?
                    <TimesheetCodeDayAddHoursComment
                        commentMandatory={this.props.commentMandatory}
                        level={this.props.activeRowLevel}
                        getHours={this.getHours}
                        onSubmit={this.onMultiEditSubmit}
                        resetToDefaultEntry={this.props.onMultiEditCancel}
                        />
                     : null}
                {this.props.expanded ?
                    <div className={this.props.settings.monthViewSelected ? 'day-hours-extra' : 'day-hours-extra-weekly'}>
                        <input  id={`normalHoursExpandedInput-${this.props.tsCodeId}-${this.props.day.dayOfMonth}`}
                                ref={(input: HTMLInputElement) => this.normalHoursExpandedInput = input}
                                name="normalHours"
                                className={this.getHoursInputClassNames(2)}
                                value={this.state.normalHours}
                                onChange={this.onChange}
                                onClick={this.onNavigateEntry}
                                onKeyDown={this.onNavigateEntry}
                                onMouseEnter={this.onNavigateEntry}
                                onDoubleClick={this.onCommentAdd}
                                onBlur={this.onBlur}
                                readOnly={this.props.isReadOnly || this.props.isCompleted || this.props.commentMandatory}
                                autoComplete="off"/>
                        <input id={`extraHoursExpandedInput-${this.props.tsCodeId}-${this.props.day.dayOfMonth}`}
                               ref={(input: HTMLInputElement) => this.extraHoursExpandedInput = input}
                               name="extraHours"
                               className={this.getHoursInputClassNames(3)}
                               value={this.state.extraHours}
                               onChange={this.onChange}
                               onClick={this.onNavigateEntry}
                               onKeyDown={this.onNavigateEntry}
                               onMouseEnter={this.onNavigateEntry}
                               onDoubleClick={this.onCommentAdd}
                               onBlur={this.onBlur}
                               readOnly={this.props.isReadOnly || this.props.isCompleted || this.props.commentMandatory}
                               autoComplete="off"/>
                        <input id={`standByHoursExpandedInput-${this.props.tsCodeId}-${this.props.day.dayOfMonth}`}
                               ref={(input: HTMLInputElement) => this.standByHoursExpandedInput = input}
                               name="standByHours"
                               className={this.getHoursInputClassNames(4)}
                               value={this.state.standByHours}
                               onChange={this.onChange}
                               onClick={this.onNavigateEntry}
                               onKeyDown={this.onNavigateEntry}
                               onMouseEnter={this.onNavigateEntry}
                               onDoubleClick={this.onCommentAdd}
                               onBlur={this.onBlur}
                               readOnly={this.props.isReadOnly || this.props.isCompleted || this.props.commentMandatory}
                               autoComplete="off"/>
                        <input id={`interventionHoursExpandedInput-${this.props.tsCodeId}-${this.props.day.dayOfMonth}`}
                               ref={(input: HTMLInputElement) => this.interventionHoursExpandedInput = input}
                               name="interventionHours"
                               className={this.getHoursInputClassNames(5)}
                               value={this.state.interventionHours}
                               onChange={this.onChange}
                               onClick={this.onNavigateEntry}
                               onKeyDown={this.onNavigateEntry}
                               onMouseEnter={this.onNavigateEntry}
                               onDoubleClick={this.onCommentAdd}
                               onBlur={this.onBlur}
                               readOnly={this.props.isReadOnly || this.props.isCompleted || this.props.commentMandatory}
                               autoComplete="off"/>
                    </div> : null}
                {this.props.expanded ?
                    <div
                        className={(this.props.settings.monthViewSelected ? 'actions-extra' : 'actions-extra-weekly') + this.getHoursInputFixedClassNames()}>
                        {this.props.isActive && !this.props.commentMandatory ?
                            <div><i id="extraComment" className="fas fa-comment add-comment" aria-hidden="true"
                                    onClick={this.onCommentAdd}/></div> : null}
                    </div> : null}
            </div>
        );
    }
}

type Props = {
    entry: StoreTimesheetEntry,
    day: Day,
    isWeekend: boolean,
    isReadOnly: boolean,
    isVacationOrIllness: boolean,
    isCompleted: boolean,
    expanded: boolean,
    settings: StoreSettings,
    onEntryChange: (entry: StoreTimesheetEntry, day: Day) => void,
    onEntryExpand: (fromEntry?: boolean) => void,

    visibleIndex: number,
    dayIndex: number,
    onNavigateEntry: (event: NavigationEvent, dayIndex?: number, day?: Day, level?: number) => void,

    activeRowLevel: number,
    isActive: boolean,
    setActiveEntryRequested: boolean,

    multiSelected: boolean,
    multiSelectEntryPoint: boolean,
    multiSelectLeftBorder: boolean,
    multiSelectRightBorder: boolean,

    commentMandatory: boolean,

    isTsCodeActive: boolean,
    tsCodeId: number,

    onMultiEditSubmit: (multiEdit: MultiEdit) => void,
    onMultiEditCancel: () => void,
    resetToDefaultEntry: () => void
};

type State = {
    entry: StoreTimesheetEntry,
    normalHours: string,
    extraHours: string,
    interventionHours: string,
    standByHours: string,
    changeRequested: boolean,
    commentVisible: boolean,
    scrollIntoView: boolean,
    isActive: boolean
};

export default TimesheetCodeDay;
