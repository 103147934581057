import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';
import React, {useRef, useState} from 'react';

export default function WeekPicker(props: Props) {
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

    const popperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const closePopper = () => setIsPopperOpen(false);

    return (
        <div className="week-picker">
            <div ref={popperRef}>
                <input type="text" value={selectedDate === undefined ? undefined : format(selectedDate, '\'Week\' I')} ref={inputRef}
                    placeholder={props.placeholder}
                    onClick={() => setIsPopperOpen(true)}
                />
            </div>
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper
                }}>
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="dialog-sheet"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <DayPicker
                            autoFocus={isPopperOpen}
                            mode="single"
                            showOutsideDays
                            showWeekNumber
                            ISOWeek

                            onSelect={(selectedDay) => {
                                props.onHandleChange(selectedDay);
                                setSelectedDate(selectedDay);
                                closePopper();
                            }}
                        />
                    </div>
                </FocusTrap>)
                }
        </div>);
}

type Props = {
    placeholder: string,
    onHandleChange: (date: Date| undefined) => void
}
