import React from 'react';

import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { PermissionTypes } from '../../../../../types/permissionTypes';
import { SigningHubStates } from '../../../../../types/enumTypes';
import { StoreSigningHubOverview, StoreState } from '../../../../../typescript/storeTypes';
import { cancelSigningHubQueue } from '../../../../../actions/signingHubActions';
import { clearSigningHubOverview, getSigningHubOverview } from '../../../../../actions/timesheetActions';
import { getAssignmentDocument } from '../../../../../actions/documentActions';

import AreYouSureModal from '../../../../../common/AreYouSureModal';
import ClosableModal from '../../../../../common/ClosableModal';
import DateHelper from '../../../../../helpers/dateHelper';
import PermissionHelper from '../../../../../helpers/permissionHelper';
import ScrollBar from 'react-custom-scrollbars-2';

export class DigitalSigningOverviewModal extends React.PureComponent<Props & MappedProps, State> {
    state: State = {
        toBeCancelledId: undefined
    };

    downloadDocument = (item: StoreSigningHubOverview): void => {
        if (item.assignmentDocumentId > 0) {
            this.props.getAssignmentDocument(item.assignmentDocumentId, item.documentName);
        }
    };

    confirmCancel = (): void => {
        if (this.state.toBeCancelledId) {
            this.props.cancelSigningHubQueue(this.state.toBeCancelledId);
            this.setState({ toBeCancelledId: undefined });
        }
    };

    canBeCancelled = (o: StoreSigningHubOverview): boolean => {
        if (!PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_CANCEL_SIGNINGHUBQUEUE)) return false;

        return ![
            SigningHubStates.Completed.toString(),
            SigningHubStates.Declined.toString(),
            SigningHubStates.Error.toString(),
            SigningHubStates.ErrorOnCreatingPackage.toString(),
            SigningHubStates.Cancelling.toString(),
            SigningHubStates.Cancelled.toString()
        ].includes(o.status);
    };

    componentDidMount() {
        this.props.getSigningHubOverview(this.props.assignmentIds);
    }

    componentWillUnmount() {
        this.props.clearSigningHubOverview();
    }

    render() {
        return (
            <ClosableModal className="digital-signing-overview-modal"
                title="DIGITAL SIGNING OVERVIEW"
                closeModal={this.props.closeModal}
                onClick={() => undefined}
                marginBottom={19}>
                <div className="digital-signing-overview-modal__header">
                    <div className="digital-signing-overview-modal__header__tscode">Timesheet Code(s)</div>
                    <div className="digital-signing-overview-modal__header__status">Status</div>
                    <div className="digital-signing-overview-modal__header__declinereason">Decline Reason</div>
                    <div className="digital-signing-overview-modal__header__statuschangeddate">Last Updated</div>
                    <div className="digital-signing-overview-modal__header__documentname">Document Name</div>
                    <div className="digital-signing-overview-modal__header__actions">Actions</div>
                </div>
                <div className="digital-signing-overview-modal__item__container">
                    <ScrollBar>
                        {this.props.overview.length > 0 ?
                            this.props.overview.map((o, i) =>
                                <div key={`digital-signing-overview-modal__item_${i}`} className="digital-signing-overview-modal__item">
                                    <div className="digital-signing-overview-modal__item__tscode tooltip-data-content-tscode" data-content={o.tsCode}><div className="digital-signing-overview-modal__item__tscode--overflow">{o.tsCode}</div></div>
                                    <div className="digital-signing-overview-modal__item__status">{o.status}</div>
                                    <div className="digital-signing-overview-modal__item__declinereason tooltip-data-content-declinereason" data-content={o.declineReason}><div className="digital-signing-overview-modal__item__declinereason--overflow">{o.declineReason}</div></div>
                                    <div className="digital-signing-overview-modal__item__statuschangeddate">{DateHelper.getFormattedDateWithTime(o.statusChangedDate, '/')}</div>
                                    <div className="digital-signing-overview-modal__item__documentname" data-showdocumentName={o.assignmentDocumentId && !([SigningHubStates.Cancelling.toString(), SigningHubStates.Cancelled.toString()].includes(o.status))} data-hasdocumentid={o.assignmentDocumentId} onClick={() => this.downloadDocument(o)}><div className="digital-signing-overview-modal__item__documentname__name">{o.documentName}</div></div>
                                    <div className="digital-signing-overview-modal__item__actions">
                                        <i className="fa fa-download" onClick={() => this.downloadDocument(o)} data-hasdocumentid={o.assignmentDocumentId} />
                                        <i className="fa fa-trash"
                                            onClick={() => this.setState({ toBeCancelledId: o.signingHubQueueId })}
                                            data-cancancel={this.canBeCancelled(o) ? true : undefined} />
                                    </div>
                                </div>)
                            :
                            <div className="digital-signing-overview-modal__no-items">No items found.</div>
                        }
                    </ScrollBar>
                    {this.state.toBeCancelledId ? <AreYouSureModal title={'Are you sure you want to cancel the request?'}
                        yes={this.confirmCancel}
                        closeModal={() => this.setState({ toBeCancelledId: undefined })} /> : null}
                </div>

            </ClosableModal>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    closeModal: () => void
};

type State = {
    toBeCancelledId: number | undefined
};

function mapStateToProps(state: StoreState, prevProps: Props) {
    return {
        assignmentIds: state.timesheet.timesheetCodes.map(t => t.assignmentIds).flat(1),
        overview: state.timesheet.signingHubOverview,
        user: state.user,

        closeModal: prevProps.closeModal
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({ cancelSigningHubQueue, clearSigningHubOverview, getSigningHubOverview, getAssignmentDocument }, dispatch);
}

export default connector(DigitalSigningOverviewModal);
