import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {addAssignment} from '../../../../actions/assignmentActions';
import {clearTsCodes, getTsCodes} from '../../../../actions/tsCodeActions';
import AddTimesheetCodeModal from './AddTimesheetCodeModal';
import ApiHelper from '../../../../helpers/apiHelper';
import Autosuggest from 'react-autosuggest';
import React from 'react';

import { ApprovalTypes } from '../../../../types/enumTypes';
import { KeyCodes } from '../../../../types/keyCodeTypes';
import {Period} from '../../../../typescript/dateTypes';
import {StoreState, StoreTimesheetCode, StoreTsCode, StoreTsCodes} from '../../../../typescript/storeTypes';


export class SearchTimesheetCode extends React.Component<Props & MappedProps, State> {
    static defaultState: State = {
        value: '',
        tsCode: {
            id: 0,
            name: '',
            description: '',
            internalApprovers: [],
            customerApprovers: [],
            internalApprovalType: ApprovalTypes.None,
            customerApprovalType: ApprovalTypes.None,
            isReadOnly: false,
            isOld: false,
            currentCount: 0,
            totalCount: 0,
            isBillable: false
        },
        isModalOpen: false
    };

    state = SearchTimesheetCode.defaultState;
    getTsCodes: (value: string) => void = ApiHelper.debounce(this.props.getTsCodes, 500);

    onChange = (event: React.FormEvent<HTMLElement>, {newValue}: {newValue: StoreTsCode|string}) => {
        if (newValue instanceof Object) {
            this.setState({ value: newValue.name, tsCode: newValue });
        } else {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({ value: target.value, tsCode: {id: 0, name: '', description: '', internalApprovers:[], customerApprovers:[], internalApprovalType: ApprovalTypes.None, customerApprovalType: ApprovalTypes.None, isReadOnly: false, isOld: false, currentCount: 0, totalCount: 0, isBillable: false} });
        }
    };

    onAddAssignment = () => {
        const assignment = {
            username: this.props.user.impersonatedUser.username,
            tsCodeId: this.state.tsCode.id,
            name: this.state.tsCode.name,
            description: this.state.tsCode.description,
            internalApprovalType: this.state.tsCode.internalApprovalType,
            internalApprovers: this.state.tsCode.internalApprovers,
            customerApprovalType: this.state.tsCode.customerApprovalType,
            customerApprovers: this.state.tsCode.customerApprovers,
            month: this.props.period.month,
            year: this.props.period.year,
            comments: '',
            isBillable: this.state.tsCode.isBillable
        };

        this.props.addAssignment(assignment, this.props.user);
        this.setState(SearchTimesheetCode.defaultState);
    };

    addTimesheetCodeOnEnter = (event: React.SyntheticEvent<HTMLDivElement> & {keyCode: number}): void => {
        if(event.keyCode === KeyCodes.ENTER) {
            this.onAddAssignment();
        }
    };

    getFilteredTimesheetCodes = (): StoreTsCodes => {
        return this.props.tsCodes.filter(tsc => this.props.currentTimesheetCodes.findIndex(tsc2 => tsc.id === tsc2.id) === -1);
    };

    onSuggestionsFetchRequested = ({value}: {value: string}) => this.getTsCodes(value);
    onSuggestionsClearRequested = this.props.clearTsCodes;
    getSuggestionValue = (tsCode: StoreTsCode): any => tsCode;
    renderSuggestion = (tsCode: StoreTsCode) => <div>{tsCode.name}</div>;

    render() {
        const inputProps = {
            value: this.state.value,
            onChange: (event: React.FormEvent<HTMLElement>, newValue: {newValue: any}) => this.onChange(event, newValue)
        };

        return (
            <div className="search-timesheetcode">
                <div className="timesheet-code timesheet-code-flex" onKeyUp={this.addTimesheetCodeOnEnter}>
                    <Autosuggest suggestions={this.getFilteredTimesheetCodes()}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}/>
                    {this.state.tsCode.id ?
                        <button className="add-timesheet-code-button" onClick={this.onAddAssignment}>
                            <i className="fas fa-plus-circle" aria-hidden="true"/>
                        </button> :
                        null
                    }
                    {this.state.isModalOpen ?
                        <AddTimesheetCodeModal closeModal={() => this.setState({isModalOpen: false})} value={this.state.value} period={this.props.period} /> : null
                    }
                    <button className="search-timesheet-code-button" onClick={() => this.setState({isModalOpen: true})}>
                        <i className="fas fa-search" aria-hidden="true"/>
                    </button>
                </div>
            </div>);
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    period: Period,
    currentTimesheetCodes: StoreTimesheetCode[]
};

type State = {
    value: string,
    tsCode: StoreTsCode,
    isModalOpen: boolean
};

function mapStateToProps(state: StoreState, prevProps: Props) {
    return {
        period: prevProps.period,
        user: state.user,
        tsCodes: state.tsCodes,
        currentTimesheetCodes: prevProps.currentTimesheetCodes
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({clearTsCodes, getTsCodes, addAssignment}, dispatch);
}

export default connector(SearchTimesheetCode);
