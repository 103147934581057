import React from 'react';

import DigitalSigningModal from './DigitalSigningModal';
import DigitalSigningOverviewModal from './DigitalSigningOverviewModal';
import DropDown from '../../../common/DropDown';

import { DropDownAction } from '../../../../../typescript/menuTypes';
import { StoreTimesheet } from '../../../../../typescript/storeTypes';

class DigitalSigning extends React.PureComponent<Props, State> {
    state = {
        wizardOpen: false,
        overviewOpen: false
    };

    hasEntries = (): boolean => {
        return this.props.timesheet.timesheetCodes
                    .filter(tsCode => tsCode.entries.length > 0).length > 0;
    };

    getActions = (): DropDownAction[] => {
        const actions : DropDownAction[] = [];
        actions.push({title: 'OVERVIEW', action: () => this.setState({overviewOpen: true})});

        if(this.props.isMonthView) {
            actions.push({title: 'MONTHLY', action: () => this.setState({wizardOpen: true})});
        } else {
            actions.push({title: 'WEEKLY', action: () => this.setState({wizardOpen: true})});
        }

        return actions;
    };

    render() {
        return (
            <div className="digital-signing">
                <DropDown mainButtonName="Digital Signing"
                    disabledCondition={() => !this.hasEntries()}
                    actions={this.getActions()} />

                {this.state.wizardOpen ?
                    <DigitalSigningModal
                        isWeekView={!this.props.isMonthView}
                        timesheetCodes={this.props.timesheet.timesheetCodes}
                        closeModal={()=> this.setState({wizardOpen: false})} />
                        : null
                }

                {this.state.overviewOpen ?
                    <DigitalSigningOverviewModal closeModal={() => this.setState({overviewOpen: false})} />
                : null}
            </div>
        );
    }
}

type State = {
    wizardOpen: boolean,
    overviewOpen: boolean
};

type Props = {
    timesheet: StoreTimesheet,
    isMonthView: boolean
};

export default DigitalSigning;
