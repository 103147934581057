import React from 'react';

import { PropertyNameIndexer } from '../../../typescript/customTypes';
import { Week } from '../../../typescript/dateTypes';

import DateHelper from '../../../helpers/dateHelper';
import WeekHelper from '../../../helpers/weekHelper';

import { StoreSettings, StoreTimesheet } from '../../../typescript/storeTypes';

class WeekSelection extends React.PureComponent<Props> {    
    onWeekClick = (week: Week): void => {
        if (WeekHelper.hasWeekNumber(this.props.timesheet, this.props.settings)) {
            return;
        }

        if(this.props.selectedWeek && this.props.selectedWeek.weekNumber === week.weekNumber) {
            this.props.updateSelectedWeek(undefined);
        } else {
            this.props.updateSelectedWeek(week);
        }        
    };

    isWeekSelected = (week: Week): boolean => {
        if (this.props.selectedWeek) {
            return this.props.selectedWeek.weekNumber === week.weekNumber;
        }
        
        return false;
    };

    render() {
        return (
            <div className="week-selection form-group">
                <div className="label-with-error-tooltip">
                    <label data-required={this.props.errors['selectedWeek']} className="digital-signing-modal__required">Select week:</label>
                    <div data-required={this.props.errors['selectedWeek']}   className="error-tooltip"><i className="fas fa-info-circle" /></div>
                </div>
                <div className="weeks-container">
                    {this.props.timesheet.period.weeks.map(week =>
                        <div key={week.weekNumber} className="week-select"
                            onClick={() => this.onWeekClick(week)}>
                            <div className={'week-select-checkbox' + (WeekHelper.hasWeekNumber(this.props.timesheet, this.props.settings) ? ' readonly': '')}>
                                <input type="checkbox"
                                        checked={this.isWeekSelected(week)}
                                        readOnly />
                            </div>
                            <div className="week-select-number">
                                <div className="week-select-weeknumber">Week {week.weekNumber}</div>
                                <div className="week-period">
                                    <div className="start-date week-period-date">
                                        {DateHelper.getFormattedDate(week.startDate,'/')}
                                    </div>
                                    <div className="week-period-dash">-</div>
                                    <div className="end-date week-period-date">
                                        {DateHelper.getFormattedDate(week.endDate,'/')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

type Props = {
    timesheet: StoreTimesheet,
    settings: StoreSettings,
    errors: PropertyNameIndexer,
    selectedWeek: Week | undefined,
    updateSelectedWeek: (selectedWeek: Week|undefined) => void 
};

export default WeekSelection;
