import React from 'react';

import { PropertyNameIndexer } from '../../../../../typescript/customTypes';
import { StoreApprover } from '../../../../../typescript/storeTypes';

import Approver from './Approver';
import ScrollBar from 'react-custom-scrollbars-2';

class DigitalSigningWizardManageApprovers extends React.PureComponent<Props> {
    errorsShown = (): boolean => Object.keys(this.props.errors).length > 0;
    approverIsEmpty = (approver: StoreApprover): boolean => approver.email === '' && approver.name === '';

    updateApprover = (index: number, approver: StoreApprover): void => {
        const approverList = this.props.approverList.slice();
        approverList[index] = approver;

        this.props.updateApproversList(approverList, this.errorsShown());
    };

    deleteApprover = (index: number): void => {
        if(this.props.approverList.length === 1 && this.approverIsEmpty(this.props.approverList[0])) return;

        const approverList = this.props.approverList.slice();
        approverList.splice(index, 1);

        this.props.updateApproversList(approverList, this.errorsShown());
    };

    addEmptyApprover = (): void => {
        const approverList = this.props.approverList.slice();
        approverList.push({name: '', email: ''});

        this.props.updateApproversList(approverList, this.errorsShown());
    };

    componentDidMount() {
        if(this.props.approverList.length === 0) {
            this.addEmptyApprover();
        }
    }

    componentDidUpdate() {
        if(this.props.approverList.length === 0) {
            this.addEmptyApprover();
        }
    }

    render() {
        return (
            <div className="wizard-screen-manage-approvers">
                <div className="form-group title label-with-error-tooltip">
                    <label data-required={this.props.errors[`${this.props.title}Approvers-NeedRecords`]} >{this.props.title} signatures</label>
                    <div data-required={this.props.errors[`${this.props.title}Approvers-NeedRecords`]} className="error-tooltip"><i className="fas fa-info-circle" /></div>
                </div>
                <div className="add-new-approver" onClick={this.addEmptyApprover}>
                    <i className="fas fa-plus" />
                    <div className="add-new-approver-text">Add new approver</div>
                </div>
                <div className="form-group approver-labels">
                    <label className="label-emailaddress">Email Address</label>
                    <label className="label-name">Name</label>
                </div>
                <div className="scroll-height">
                    <ScrollBar>
                        {this.props.approverList.map((a, idx) =>
                            <Approver approver={a}
                                      updateApprover={(approver: StoreApprover) => this.updateApprover(idx, approver)}
                                      deleteApprover={() => this.deleteApprover(idx)}
                                      emailError={this.props.errors[`${this.props.title}Approvers-Email-${idx}`]}
                                      nameError={this.props.errors[`${this.props.title}Approvers-Name-${idx}`]}
                                      key={`${this.props.title}-approver-${idx}`} />)}
                    </ScrollBar>
                </div>
                <div className="form-group digital-signing-checkbox" onClick={this.props.signedByAllClicked}>
                        <input type="checkbox" checked={this.props.signedByAll}  readOnly/>
                        <label>Everyone needs to sign</label>
                </div>
            </div>
        );
    }
}

type Props = {
    errors: PropertyNameIndexer,
    title: string,
    approverList: StoreApprover[],
    signedByAll: boolean,

    signedByAllClicked: () => void,
    updateApproversList: (approverList: StoreApprover[], updateErrors: boolean) => void
}

export default DigitalSigningWizardManageApprovers;
