import React from 'react';

class TimesheetCodeTotalTotal extends React.PureComponent<Props> {
    render() {
        const totalHours = this.props.totals.reduce((acc: number, cur: number) => acc + cur, 0);
        return (
            <div id="totalBox" className="timesheet-code-total-container">
                <div className="timesheet-code-total">{totalHours.toFixed(2)}
                    <span className="timesheet-code-total-text">{this.props.monthViewSelected ? ' TOTAL HOURS THIS MONTH' : ' TOTAL HOURS THIS WEEK' }</span>
                </div>
            </div>
        );
    }
}

type Props = {
    totals: number[],
    monthViewSelected: boolean
};

export default TimesheetCodeTotalTotal;
