import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {clearInvoiceNumbers, getInvoice, searchInvoiceNumbers} from '../../../../actions/invoiceActions';
import ApiHelper from '../../../../helpers/apiHelper';
import Autosuggest, {GetSuggestionValue} from 'react-autosuggest';
import React from 'react';

import {StoreInvoiceNumber, StoreState} from '../../../../typescript/storeTypes';

export class SearchInvoiceNumber extends React.Component<Props & MappedProps, State> {
    autoSuggest: Autosuggest<StoreInvoiceNumber, any> | undefined;
    static defaultState: State = {
        value: '',
        invoiceNumber: {
            id: 0,
            invoiceNumber: '',
            company : ''
        }
    };
    state = SearchInvoiceNumber.defaultState;
    searchInvoiceNumbers: (value: string) => void = ApiHelper.debounce(this.props.searchInvoiceNumbers, 500);

    onChange = (event: React.FormEvent<HTMLElement> & { currentTarget: HTMLElement }, {newValue}: {newValue: StoreInvoiceNumber|string}) => {
        if (newValue instanceof Object) {
            this.setState({ value: newValue.invoiceNumber, invoiceNumber: newValue });
        } else {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({ value: target.value, invoiceNumber: {id: 0, invoiceNumber: '', company: ''} });
        }
    };

    downloadInvoice = () => {
        if(this.state.invoiceNumber.id > 0) {
            this.props.getInvoice(this.state.invoiceNumber.id, this.state.invoiceNumber.invoiceNumber);
        }
    };

    static getDerivedStateFromProps(nextProps: Props & MappedProps) {
        if(nextProps.invoiceNumbers.length === 1) {
            return {value: nextProps.invoiceNumbers[0].invoiceNumber, invoiceNumber: nextProps.invoiceNumbers[0]};
        }

        return null;
    }

    onKeyPress = (event: any): void => {
        if(document.activeElement !== (this.autoSuggest as any).input) return;
        if(event.charCode === 13 || event.which === 13 || event.keyCode === 13) {
            this.downloadInvoice();
        }
    };

    componentWillUnmount() {
        document.body.removeEventListener('keydown', this.onKeyPress);
    }

    componentDidMount() {
        document.body.addEventListener('keydown', this.onKeyPress);
    }

    onSuggestionsFetchRequested = ({value}: {value: string}) => this.searchInvoiceNumbers(value);
    getSuggestionValue: GetSuggestionValue<StoreInvoiceNumber> = (invoiceNumber: StoreInvoiceNumber): any => invoiceNumber;
    renderSuggestion = (invoiceNumber: StoreInvoiceNumber) => <div>{invoiceNumber.invoiceNumber} ({invoiceNumber.company})</div>;

    render() {
        const inputProps = {
            value: this.state.value,
            placeholder: 'Invoice# eg VF/CRO/18/....',
            onChange: (event: React.FormEvent<HTMLElement>, newValue: {newValue: any}) => this.onChange(event, newValue)
        };

        return (
            <div className="search-invoicenumber">
                <div className="search-invoicenumber__autosuggest">
                    <Autosuggest ref={(el: Autosuggest<StoreInvoiceNumber, any>) => this.autoSuggest = el} suggestions={this.props.invoiceNumbers}
                                onSuggestionsClearRequested={this.props.clearInvoiceNumbers}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}/>
                </div>
                <button type="submit" className="search-invoicenumber__submit"
                    onClick={this.downloadInvoice} disabled={this.state.invoiceNumber.id === 0 || this.props.calls.invoiceRequested}>
                    <i className="fas fa-chevron-right"/>
                </button>
            </div>);
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    autoFocus: boolean
};

type State = {
    value: string,
    invoiceNumber: StoreInvoiceNumber
};

function mapStateToProps(state: StoreState) {
    return {
        invoiceNumbers: state.invoiceDetails.invoiceNumbers,
        calls: state.calls,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({getInvoice, searchInvoiceNumbers, clearInvoiceNumbers}, dispatch);
}

export default connector(SearchInvoiceNumber);
