import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import TimesheetCode from './TimesheetCode';
import TimesheetCodeDayTotal from './TimesheetCodeDayTotal';
import TimesheetCodeTotalTotal from './TimesheetCodeTotalTotal';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {Day} from '../../../../typescript/dateTypes';
import {StoreSettings, StoreTimesheet, StoreTimesheetEntry} from '../../../../typescript/storeTypes';

class TimesheetTotal extends React.PureComponent<Props> {
    render() {
        const totals: number[] = [];
        return (
            <div className="timesheet-row timesheet-total">
                <TimesheetCode name="Total" showClipboard={false}/>
                {this.props.settings.monthViewSelected ? '' : <div className="timesheet-row-filler-left" />}
                <div className={'timesheet-code-days' + (this.props.settings.monthViewSelected ? ' month-timesheet-code-days-width' : ' timesheet-days-weekly week-timesheet-code-days-width')}>
                    {this.props.days.map(day => {
                        const entries = TimesheetHelper.getEntries(this.props.timesheet.timesheetCodes, day);
                        totals.push(entries.reduce((acc: number, cur: StoreTimesheetEntry) => acc + cur.totalHours, 0));

                        if (!(DateHelper.isWeekend(day) && this.props.settings.hideWeekends))
                            return (<TimesheetCodeDayTotal key={day.dateString}
                                                           entries={entries}/>);
                        else
                            return (this.props.settings.monthViewSelected ? <div key={day.dateString} className="weekend-collapsed"/> : null);
                    })}
                </div>
                {this.props.settings.monthViewSelected ? '' : <div className="timesheet-row-filler-right" />}
                <TimesheetCodeTotalTotal totals={totals} monthViewSelected={this.props.settings.monthViewSelected}/>
            </div>
        );
    }
}

type Props = {
    timesheet: StoreTimesheet,
    days: Day[],
    settings: StoreSettings
};

export default TimesheetTotal;
