import {ConnectedProps, connect} from 'react-redux';
import {WithRouterProps, withRouter } from '../../../../common/WithRouter';
import React from 'react';
import StringHelper from '../../../../helpers/stringHelper';

import { LocationTypes } from '../../../../types/locationTypes';
import { StoreState } from '../../../../typescript/storeTypes';
import AccessibleUserHelper from '../../../../helpers/accessibleUserHelper';

export class SiteLocation extends React.Component<Props, State> {
    state: State = {
        location: ''
    };

    static getDerivedStateFromProps(nextProps: Props) {
        switch (nextProps.location.pathname) {
            case LocationTypes.HOME :
                if (nextProps.user.impersonatedUser.firstName &&
                    nextProps.user.impersonatedUser.lastName &&
                    nextProps.user.employee.username !== nextProps.user.impersonatedUser.username) {
                    return {location: `Viewing timesheet of ${StringHelper.getUserFullName(nextProps.user, nextProps.settings.firstNameLastName)}${AccessibleUserHelper.isAccessibleUser(nextProps.user) ? '' : ' (readonly)'}`};
                }
                else {
                    return {location: ''};
                }
            case LocationTypes.REPORTING:
                return {location: 'Reporting'};
            case LocationTypes.REPORTING_TIMESHEETS_FULL:
                return {location: 'Timesheets Report'};
            case LocationTypes.REPORTING_WEEKLY_TIMESHEETS_FULL:
                return {location: 'Timesheets weekly report'};
            case LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS_FULL:
                return {location: 'Timesheets Report (Landscape)'};
            case LocationTypes.REPORTING_TIME_LEFT_ON_ORDER_FULL:
                return {location: 'Time Left On Order'};
            case LocationTypes.REPORTING_ACTUALS_FULL:
                return {location: 'Actuals Report'};
            case LocationTypes.IMPORT_ACTUALS:
                return {location: 'Import Actuals'};
            case LocationTypes.SETTINGS:
                return {location: 'Settings'};
            case LocationTypes.INVOICE_VIEWER:
               return {location: 'Invoice Viewer'};
            case LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT_FULL:
                return {location: 'Project based timesheet report'};
        }

        return null;
    }

    render() {
        return (
            this.state.location ?
                <div className="site-location">{this.state.location}</div>
            : null
        );
    }
}

const connector = connect(mapStateToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    location: string
};

function mapStateToProps(state: StoreState, props: WithRouterProps) {
    return {
        user: state.user,
        settings: state.settings,
        location: props.location // routed
    };
}

export default withRouter(connector(SiteLocation));
