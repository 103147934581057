import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import documentApi from '../api/documentApi';

import {Action} from '../typescript/actionTypes';
import { Messages } from '../types/messageTypes';
import { blobToBase64 } from 'file64';
import MessageHelper from '../helpers/messageHelper';

function* getAssignmentDocument(action: Action): Generator<any,any,any> {
    try {
        const result = yield call(documentApi.getAssignmentDocument, action.payload);

        let base64;
        yield blobToBase64(result.blob).then(r => base64 = r);

        yield put({type: ActionTypes.GET_ASSIGNMENTDOCUMENT_SUCCEEDED, payload: { fileName: action.payload.filename, base64: base64}});
    } catch (e) {
        yield put({type: ActionTypes.GET_ASSIGNMENTDOCUMENT_FAILED, payload: e.message});
    }
}

function* deleteAssignmentDocument(action: Action): Generator<any,any,any> {
    try {
        yield call(documentApi.deleteAssignmentDocument, action.payload);

        MessageHelper.showMessage(Messages.DeletedDocumentSuccessfully);
        yield put({type: ActionTypes.DELETE_ASSIGNMENTDOCUMENT_SUCCEEDED, payload: action.payload});
    } catch (e) {
        yield put({type: ActionTypes.DELETE_ASSIGNMENTDOCUMENT_FAILED, payload: e.message});
    }
}

function* documentSaga(): Generator<any,any,any> {
    yield takeEvery(ActionTypes.GET_ASSIGNMENTDOCUMENT_REQUESTED, getAssignmentDocument);
    yield takeEvery(ActionTypes.DELETE_ASSIGNMENTDOCUMENT_REQUESTED, deleteAssignmentDocument);
}

export default documentSaga;
