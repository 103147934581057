import 'core-js/stable';
import {Provider} from 'react-redux';
import { createRoot } from 'react-dom/client';
import {store} from '../store/store.prod';
import App from './components/App';
import AuthorizedRequest from '../api/authorizedRequests';
import React from 'react';

AuthorizedRequest.setStore(store);

const container = document.getElementById('app');
const root = createRoot(container as Element);
root.render(
<Provider store={store}>
    <App/>
</Provider>);
