import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';

export function getAssignmentDocument(assignmentDocumentId: number, filename: string): Action {
    return {type: ActionTypes.GET_ASSIGNMENTDOCUMENT_REQUESTED, payload: {assignmentDocumentId, filename}};
}

export function deleteAssignmentDocument(assignmentId: number, assignmentDocumentId: number): Action {
    return {type: ActionTypes.DELETE_ASSIGNMENTDOCUMENT_REQUESTED, payload: {assignmentId: assignmentId, assignmentDocumentId: assignmentDocumentId}};
}
