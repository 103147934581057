import React from 'react';

class TimesheetCodeColumnPlaceHolder extends React.PureComponent {
    render() {
        return (
            <div className="timesheet-code-column-placeholder"/>
        );
    }
}

export default TimesheetCodeColumnPlaceHolder;
