import React from 'react';

import ScrollBar from 'react-custom-scrollbars-2';

import { ApprovalTypes } from '../../../../../types/enumTypes';
import { SigningHubObject } from '../../../../../typescript/signingHubTypes';
import { StoreSupplier, StoreTimesheetCode } from '../../../../../typescript/storeTypes';
import InfoContainer from '../../../common/InfoContainer';

class DigitalSigningWizardOverview extends React.PureComponent<Props> {
     getCompanyName = (): string => {
        const m = this.props.suppliers.find(e=>e.id === this.props.signingHubObject.companyId);
        if (m === undefined) return '';
        return m.name;
    };

    getTimesheetCodes = () => {
        return this.props.timesheetCodes.map(ts => <div key={ts.id} className="overview-item-value">{ts.name}</div>);
    };

    getInternalApprovers = () => {
        if(this.props.signingHubObject.internalApprovalType === ApprovalTypes.None) return <div className="overview-item-value">No internal approval needed</div>;
        return this.props.signingHubObject.internalApprovers.map(ia => <div key={ia.email} className="overview-item-value">{ia.name} ({ia.email})</div>);
    };

    getCustomerApprovers = () => {
        if(this.props.signingHubObject.customerApprovalType === ApprovalTypes.None) return <div className="overview-item-value">No customer approval needed</div>;
        return this.props.signingHubObject.customerApprovers.map(ca => <div key={ca.email} className="overview-item-value">{ca.name} ({ca.email})</div>);
    };

    render() {
        return (
            <div className="digital-signing-wizard-overview">
                <div className="overview-item oneline">
                    <div className="overview-item-title">Hours / week:</div>
                    <div className="overview-item-title">{this.props.signingHubObject.hoursPerWeek} H</div>
                </div>
                <div className="overview-container">
                    <div className="overview-container-column left">
                        <div className="overview-item list">
                            <div className="overview-item-title">Selected timesheet codes:</div>
                            <div className="scrollBarContainer">
                                <ScrollBar>
                                    {this.getTimesheetCodes()}
                                </ScrollBar>
                            </div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-title">Company background image:</div>
                            <div className="overview-item-value">{this.getCompanyName()}</div>
                        </div>
                        <div className="overview-item oneline">
                            <div className="overview-item-title">Hide comments:</div>
                            {this.props.signingHubObject.hideComments ? <i className="fas fa-check" /> : <i className="fas fa-times" />}
                        </div>
                        <InfoContainer message="Internal and Customer approvers and signing options can be saved to the selected timesheetcodes for future ease of use."/>
                    </div>
                    <div className="overview-container-column right">
                        <div className="overview-item">
                            <div className="overview-item-title">Consultant approval:</div>
                            <div className="overview-item-value">
                                {this.props.signingHubObject.consultantApprovalNeeded ? 'Consultant approval needed' : 'No consultant approval needed'}
                            </div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-title">Internal company approval:</div>
                            <div className="scrollBarContainer">
                                <ScrollBar>
                                    {this.getInternalApprovers()}
                                </ScrollBar>
                            </div>
                        </div>
                        <div className="overview-item">
                            <div className="overview-item-title">Customer company approval:</div>
                            <div className="scrollBarContainer">
                                <ScrollBar>
                                    {this.getCustomerApprovers()}
                                </ScrollBar>
                            </div>
                        </div>
                        <div className="overview-item oneline">
                            <div className="overview-item-title">Save approver settings:</div>
                            <input type="checkbox" checked={this.props.signingHubObject.saveSettings} onChange={() => this.props.updateSigningHubObject({... this.props.signingHubObject, saveSettings: !this.props.signingHubObject.saveSettings}, false)}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

type Props = {
    signingHubObject: SigningHubObject,
    suppliers: StoreSupplier[],
    timesheetCodes: StoreTimesheetCode[],
    updateSigningHubObject: (signingHubObject: SigningHubObject, revalidate: boolean) => void
};


export default DigitalSigningWizardOverview;
