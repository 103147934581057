import React from 'react';

import DateHelper from '../../../../helpers/dateHelper';

import {Period} from '../../../../typescript/dateTypes';
import MonthYearPicker from '../../common/MonthYearPicker';


class SwitchMonth extends React.PureComponent<Props> {
    minDate = new Date(2000, 0, 1);
    maxDate = DateHelper.addMonths(new Date(), 12);

    handleShift = (event: React.MouseEvent, direction: number) => {
        event.preventDefault();
        const newStartDate = DateHelper.getNormalizedDate(this.props.period.startDate);
        newStartDate.setMonth(this.props.period.startDate.getMonth() + direction);

        if(newStartDate.getFullYear() >= this.minDate.getFullYear() && newStartDate.getFullYear() <= this.maxDate.getFullYear()){
            this.props.getTimesheet(newStartDate);
        }
    };

    handleAMonthChange = (year: number, month: number) => {
        this.props.getTimesheet(DateHelper.getNormalizedDate(new Date(year, month - 1, 1)));
    };

    render() {
        return (this.props.monthViewSelected ?
            <div className="switch-month no-select">
                <button className="switch-month-select switch-month-select-left"
                        onClick={event => this.handleShift(event, -1)}><i className="fas fa-angle-left" aria-hidden="true"/></button>
                <div className="month-picker-box-container">
                    <MonthYearPicker
                        value={this.props.period.startDate}
                        minDate={this.minDate}
                        maxDate={this.maxDate}
                        period={this.props.period}
                        hideWeekends={this.props.hideWeekends}
                        onChange={(month: number, year: number) => this.handleAMonthChange(year, month)}/>
                </div>
                <button className="switch-month-select switch-month-select-right"
                        onClick={event => this.handleShift(event, 1)}><i className="fas fa-angle-right" aria-hidden="true"/></button>
            </div> :
            <div className="switch-month switch-month-weekly no-select">
                <div className="month-picker-box-container">
                <MonthYearPicker
                        value={this.props.period.startDate}
                        minDate={this.minDate}
                        maxDate={this.maxDate}
                        period={this.props.period}
                        hideWeekends={this.props.hideWeekends}
                        onChange={(month: number, year: number) => this.handleAMonthChange(year, month)}/>
                </div>
            </div>
        );
    }
}

type Props = {
    monthViewSelected: boolean,
    period: Period,
    hideWeekends: boolean,
    getTimesheet: (startDate: Date, monthViewSelected?: boolean) => void
};

export default SwitchMonth;
