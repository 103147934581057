import React from 'react';

import { DropDownAction } from '../../../typescript/menuTypes';

class DropDown extends React.PureComponent<Props, State> {
    state = {
        menuOpen: false
    };

    dropDownContainer: HTMLDivElement | undefined;

    clickEvent = (event: Event) => {
        if(this.dropDownContainer !== undefined && this.dropDownContainer !== null && this.dropDownContainer.contains(event.target as Node)) {
            return;
        }

        this.setState({menuOpen: false});
    };

    executeAction = (action: () => void): void => {
        action();
        this.setState({menuOpen: false});
    };

    componentDidMount() {
        if (document.body) {
            document.body.addEventListener('click', this.clickEvent);
        }
    }

    componentWillUnmount() {
        if (document.body) {
            document.body.removeEventListener('click', this.clickEvent);
        }
    }

    render() {
        let render : React.ReactElement;

        if(this.props.actions.length === 1) {
            render = (<div className="dropdown" ref={(el: HTMLDivElement) => this.dropDownContainer = el}>
                        <input className="dropdown-submit input-submit-darkskyblue"
                                type="submit" value={`${this.props.mainButtonName} ${this.props.actions[0].title}`} disabled={this.props.disabledCondition()}
                                onClick={() => this.executeAction(this.props.actions[0].action)} />
                    </div>);
        } else {
            render = (
                <div className="dropdown" ref={(el: HTMLDivElement) => this.dropDownContainer = el}>
                <input className={`dropdown-submit input-submit-darkskyblue ${this.state.menuOpen ? ' open' : ''}`}
                        type="submit" value={this.props.mainButtonName} disabled={this.props.disabledCondition()}
                        onClick={() => (this.props.disabledCondition() ? () => { return; }  : this.setState({menuOpen: !this.state.menuOpen}))} />
                {this.state.menuOpen ?
                    <div className={`dropdown-container ${this.state.menuOpen ? ' open' : ''} buttoncount${this.props.actions.length}`}>
                        {this.props.actions.map(dda => <div key={dda.title} className="dropdown-container-item" onClick={() => this.executeAction(dda.action)}>{dda.title}</div>)}

                    </div>
                    : null
                }
            </div>
            );
        }

        return render;
    }
}

type Props = {
    mainButtonName: string,
    actions: DropDownAction[],
    disabledCondition: () => boolean
};

type State = {
    menuOpen: boolean
};

export default DropDown;
