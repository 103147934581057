import React from 'react';

import { PropertyNameIndexer } from '../../../../../typescript/customTypes';
import { SigningHubObject } from '../../../../../typescript/signingHubTypes';
import { StoreTimesheetCode } from '../../../../../typescript/storeTypes';

import ContractHoursToggle from '../../../../../common/ContractHoursToggle';
import ScrollBar from 'react-custom-scrollbars-2';

import { ApprovalTypes } from '../../../../../types/enumTypes';
import DigitalSigningHelper from '../../../../../helpers/digitalSigningHelper';
import ObjectHelper from '../../../../../helpers/objectHelper';

class DigitalSigningWizardScreen1 extends React.PureComponent<Props> {
    errorsShown = (): boolean => Object.keys(this.props.errors).length > 0;
    isReadOnly = (): boolean => this.getFilteredTimesheetCodes().length === 1;

    onSelectAll = (): void => {
        if (this.isReadOnly()) return;

        const copy = DigitalSigningHelper.resetAllButFirstPage(this.props.signingHubObject);
        copy.tsCodes = !this.selectAllChecked() ? this.getFilteredTimesheetCodes().slice().map(ts => ts.id) : [];
        copy.assignments = !this.selectAllChecked() ? this.getFilteredTimesheetCodes().slice().map(ts => ts.assignmentIds).flat(1) : [];
        this.recalculateApprovalType(copy);
        this.props.updateSigningHubObject(copy, this.errorsShown());
    };

    getFilteredTimesheetCodes = (): StoreTimesheetCode[] => {
        return this.props.timesheetCodes.filter(t => t.entries.length > 0);
    };

    recalculateApprovalType = (shObject: SigningHubObject): void => {
        if(shObject.tsCodes.length > 1) {
            const selectedTimesheetCodes = this.props.timesheetCodes.filter(e => shObject.tsCodes.includes(e.id));

            shObject.internalApprovalType =
                selectedTimesheetCodes.some(ts => ts.internalApprovalType === ApprovalTypes.All) ? ApprovalTypes.All :
                selectedTimesheetCodes.some(ts => ts.internalApprovalType === ApprovalTypes.One) ? ApprovalTypes.One : ApprovalTypes.None;
            shObject.customerApprovalType =
                selectedTimesheetCodes.some(ts => ts.customerApprovalType === ApprovalTypes.All) ? ApprovalTypes.All :
                selectedTimesheetCodes.some(ts => ts.customerApprovalType === ApprovalTypes.One) ? ApprovalTypes.One : ApprovalTypes.None;
        }
    };

    onTimesheetCodeClick = (timesheetCode: StoreTimesheetCode): void => {
        if (this.isReadOnly()) {
            return;
        }

        const copy = DigitalSigningHelper.resetAllButFirstPage(this.props.signingHubObject);

        if (copy.tsCodes.find(tsCodeId => tsCodeId === timesheetCode.id)) {
            const tsCodeIndex = copy.tsCodes.indexOf(timesheetCode.id);
            copy.tsCodes.splice(tsCodeIndex, 1);

            timesheetCode.assignmentIds.forEach(assignmentId => {
                const assignmentIndex = copy.assignments.indexOf(assignmentId);
                if(assignmentIndex > -1) copy.assignments.splice(assignmentIndex, 1);
            });
        } else {
            copy.tsCodes.push(timesheetCode.id);
            timesheetCode.assignmentIds.forEach(assignmentId => {
                if(!copy.assignments.find(a => a === assignmentId)) copy.assignments.push(assignmentId);
            });
        }

        //calculate required signatures when more than 1 tscode
        this.recalculateApprovalType(copy);
        this.props.updateSigningHubObject(copy, this.errorsShown());
    };

    changeHideComments = (): void => {
        this.props.updateSigningHubObject(Object.assign({}, this.props.signingHubObject, {hideComments: !this.props.signingHubObject.hideComments}), false);
    };

    selectAllChecked = (): boolean => {
        const filteredTsCodes = this.getFilteredTimesheetCodes();
        return this.props.signingHubObject.tsCodes.length === filteredTsCodes.length && filteredTsCodes.length !== 0;
    };

    componentDidUpdate(prevProps: Props) {
        if(!ObjectHelper.deepEqual(this.props.timesheetCodes, prevProps.timesheetCodes)) {
            //When only one tscode present, select it.
            let tsCodes: number[] = [];
            let assignments: number[] = [];
            if(this.isReadOnly()) {
                tsCodes = [this.getFilteredTimesheetCodes()[0].id];
                assignments = this.getFilteredTimesheetCodes()[0].assignmentIds;
            }

            this.props.updateSigningHubObject(Object.assign({}, this.props.signingHubObject, {tsCodes, assignments}), this.errorsShown());
        }
    }

    //for the timesheetcodetotal
    componentDidMount() {
        if(this.isReadOnly()) {
            const signingHubObject = Object.assign({}, this.props.signingHubObject, {
                tsCodes: [this.getFilteredTimesheetCodes()[0].id],
                assignments: this.getFilteredTimesheetCodes()[0].assignmentIds
            });

            this.props.updateSigningHubObject(signingHubObject, this.errorsShown());
        }
    }

    render() {
        return (
            <div className="wizard-screen-1">
                <div className="form-group">
                    <div className="label-with-error-tooltip">
                        <label data-required={this.props.errors['selectedTimesheetCodes']} className="digital-signing-modal__required">Select timesheet codes to export:</label>
                        <div data-required={this.props.errors['selectedTimesheetCodes']}   className="error-tooltip"><i className="fas fa-info-circle" /></div>
                    </div>
                    {this.getFilteredTimesheetCodes().length === 0 ?
                    <label className="timesheetcodes-container">No selectable timesheetcodes present.</label> :
                    <div className={`timesheetcodes-container ${(this.isReadOnly() ? 'readonly' : '')}`}>
                        <div className="digital-signing-modal-selectAll" onClick={this.onSelectAll}>
                            <input type="checkbox" className="digital-signing-modal-selectAll-checkbox"
                                checked={this.selectAllChecked()} readOnly />
                            <div className="digital-signing-modal-selectAll-checkbox-text">SELECT ALL</div>
                        </div>
                        <div className="scrollBarContainer">
                            <ScrollBar>
                                {this.getFilteredTimesheetCodes().map(timesheetCode =>
                                    <div key={timesheetCode.id} className="tscode-select">
                                        <div className="tscode-select-checkbox">
                                            <input id={`tsc-${timesheetCode.id}`}
                                                type="checkbox"
                                                checked={this.props.signingHubObject.tsCodes.find(t => t === timesheetCode.id) !== undefined}
                                                onChange={() => this.onTimesheetCodeClick(timesheetCode)}/>
                                        </div>
                                        <div className="tscode-select-name"
                                            onClick={() => this.onTimesheetCodeClick(timesheetCode)}>
                                            {timesheetCode.name}
                                        </div>
                                    </div>)}
                            </ScrollBar>
                        </div>
                    </div>
                    }
                </div>
                <div className="form-group hours-per-week-toggle">
                    <label>Hours per week:</label>
                    <ContractHoursToggle />
                </div>
                <div className="form-group">
                    <div className="digital-signing-checkbox" onClick={this.changeHideComments}>
                        <input type="checkbox" checked={this.props.signingHubObject.hideComments} readOnly />
                        <label>Hide comments</label>
                    </div>
                </div>
                <div className="digital-signing-modal__required_message">Fields marked with an * are required</div>
            </div>
        );
    }
}

type Props = {
    signingHubObject: SigningHubObject,
    timesheetCodes: StoreTimesheetCode[],
    errors: PropertyNameIndexer,
    updateSigningHubObject: (signingHubObject: SigningHubObject, revalidate: boolean) => void
};

export default DigitalSigningWizardScreen1;
