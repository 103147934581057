import { PermissionTypes } from '../../../../types/permissionTypes';
import ApiHelper from '../../../../helpers/apiHelper';
import AutoSuggestList from './AutoSuggestList';
import Autosuggest, {GetSuggestionValue, InputProps} from 'react-autosuggest';
import ContractHoursToggle from '../../../../common/ContractHoursToggle';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import StringHelper from '../../../../helpers/stringHelper';

import {
    StoreSettings,
    StoreSupplier,
    StoreTsCodes,
    StoreUser
} from '../../../../typescript/storeTypes';

import {Action} from '../../../../typescript/actionTypes';
import {SuggestionInfo} from '../../../../typescript/infoTypes';
import {TimesheetsReportType} from '../../../../typescript/reportTypes';
import { onlyUnique } from '../../../../helpers/util';
import EmployeeSortHelper from '../../../../helpers/employeeSortHelper';
import MonthYearPicker from '../../common/MonthYearPicker';
import PermissionHelper from '../../../../helpers/permissionHelper';
import Preset from '../../../../common/Preset';
import WildCardHelper from '../../../../helpers/wildCardHelper';

class TimesheetsReport extends React.Component<Props, State> {
    date = new Date();

    state: State = {
        month: this.date.getMonth() + 1,
        year: this.date.getFullYear(),
        userSelection: [],
        tsCodeSelection: [],
        supplierSelection: [],
        tsCodeSuggestions: [],
        selectedTemplateSupplierValue: '',
        selectedTemplateSupplier: null,
        userFilter: '',
        supplierFilter: ''
    };

    selectionsEmpty = (): boolean => this.state.supplierSelection.length === 0 && this.state.tsCodeSelection.length === 0 && this.state.userSelection.length === 0;

    exportPdf = (): void => {
        if(!this.canExport()) return;

        const data = this.getExportData();
        this.props.exportTimesheetsPdf(data);
    };

    canExport = (): boolean => {
        if (this.props.reportRequested) return false;
        if (this.selectionsEmpty()) return false;

        return true;
    };

    getExportData = (): TimesheetsReportType => {
        let templateId = null;
        if (this.state.selectedTemplateSupplier !== null && this.state.selectedTemplateSupplier !== undefined) {
            templateId = this.state.selectedTemplateSupplier.id;
        }
        return {
            companyIds: this.state.supplierSelection.map(s => s.id),
            tsCodeIds: this.state.tsCodeSelection.map(t => t.id).filter(onlyUnique),
            userIds: this.state.userSelection.map(u => u.id),
            year: this.state.year,
            month: this.state.month,
            hoursOnContract: this.props.settings.defaultContractHours,
            templateId: templateId
        };
    };

    getTsCodeSuggestions = (): SuggestionInfo<string>[] => {
        return this.state.tsCodeSuggestions.slice();
    };

    getUserSuggestions = (): SuggestionInfo<string>[] => {
        let users = this.props.user.visibleUsers.slice();
        if (this.state.userFilter && this.state.userFilter !== '') {
            users = users.filter(vu => EmployeeSortHelper.isHit(this.state.userFilter, vu));
        }

        const list: SuggestionInfo<string>[] = [];
        for (const user of users) {
            if(list.length > 0 && list.some(e=>e.id === user.id)) continue;
            list.push({
                id: user.id,
                value: `${StringHelper.getEmployeeFullName(user, this.props.settings.firstNameLastName, false, true)}`,
                displayValue: `${StringHelper.getEmployeeFullName(user, this.props.settings.firstNameLastName, false, true)}`,
                currentCount: 0,
                totalCount:0
            });
        }

        return list;
    };

    getSupplierSuggestions = (): SuggestionInfo<string>[] => {
        let suppliers = this.props.user.visibleSuppliers.slice();

        if(this.state.supplierFilter && this.state.supplierFilter !== '') {
            suppliers = suppliers.filter(vs => WildCardHelper.containsString(this.state.supplierFilter, vs.name));
        }

        const list: SuggestionInfo<string>[] = [];
        for (const supplier of suppliers) {
            if(list.length > 0 && list.some(e=>e.id === supplier.id)) continue;
            list.push({
                id: supplier.id,
                value: supplier.name,
                displayValue: supplier.name,
                currentCount: 0,
                totalCount: 0
            });
        }

        return list;
    };

    clearTsCodePagingList = ():void => {
        this.props.clearTsCodes();
    };

    fetchUsers = (value: string) => this.setState({userFilter: value});
    fetchSuppliers = (value: string) => this.setState({supplierFilter: value});
    fetchTsCodes: (value: string, page?: number) => void = ApiHelper.debounce((value: string, page?: number) => this.props.getTsCodes(value, page as number, true, true, this.state.month, this.state.year), 500);

    addUser = (users: any[]): void => {
        const newList = this.state.userSelection.slice();

        users.forEach(u => {
            if(newList.some(user => user.id === u.id)) {
                return;
            }
            newList.push(u);
        });

        this.setState({userSelection: newList});
    };

    addTsCode = (tsCodes: any[]): void => {
        const newList = this.state.tsCodeSelection.slice();

        tsCodes.forEach(t => {
            if(newList.some(tscode => tscode.id === t.id)) {
                return;
            }
            newList.push(t);
        });

        this.setState({tsCodeSelection: newList});
    };

    addCompany = (suppliers: any[]): void => {
        const newList = this.state.supplierSelection.slice();

        suppliers.forEach(s => {
            if(newList.some(supplier => supplier.id === s.id)) {
                return;
            }
            newList.push(s);
        });

        this.setState({supplierSelection: newList});
    };

    removeUser = (ids: number[]): void => {
        const newSelection = this.state.userSelection.filter(id => ids.indexOf(id.id) === -1);
        this.setState({userSelection: newSelection});
    };

    removeTsCode = (ids: string[]): void => {
        const newSelection = this.state.tsCodeSelection.filter(id => ids.indexOf(id.id) === -1);
        this.setState({tsCodeSelection: newSelection});
    };

    removeCompany = (ids: number[]): void => {
        const newSelection = this.state.supplierSelection.filter(id => ids.indexOf(id.id) === -1);
        this.setState({supplierSelection: newSelection});
    };

    handleAMonthChange = (year: number, month: number) => {
        this.setState({year: year, month:month});
    };

    getSuppliers: (value: string) => void = ApiHelper.debounce((value: string) => this.props.getSuppliers(value), 500);

    onChange = (event: React.FormEvent<HTMLElement>, {newValue}: { newValue: StoreSupplier | string }) => {
        if (newValue instanceof Object) {
            this.setState({selectedTemplateSupplierValue: newValue.name, selectedTemplateSupplier: newValue});
        } else {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({
                selectedTemplateSupplierValue: target.value,
                selectedTemplateSupplier: null
            });
        }
    };

    getDataForPreset = (): any => {
        return {
            userSelection: this.state.userSelection,
            tsCodeSelection: this.state.tsCodeSelection,
            supplierSelection: this.state.supplierSelection
        };
    };

    onPresetChange = (data: any) => {
        this.setState(data);
    };

    onSuggestionsFetchRequested = ({value}: { value: string }) => this.getSuppliers(value);
    onSuggestionsClearRequested = this.props.clearSuppliers;
    getSuggestionValue: GetSuggestionValue<StoreSupplier> = (supplier: StoreSupplier): any => supplier;
    renderSuggestion = (supplier: StoreSupplier) => <div>{supplier.name}</div>;
    onBlur = (templateSupplier: StoreSupplier) => {
        if (this.state.selectedTemplateSupplierValue !== '' && this.state.selectedTemplateSupplier === null && templateSupplier === null) {
            this.setState({selectedTemplateSupplierValue: ''});
            return;
        }

        if (templateSupplier !== null) {
            this.setState({
                selectedTemplateSupplier: templateSupplier,
                selectedTemplateSupplierValue: templateSupplier.name
            });
        }
    };

    componentDidUpdate(prevProps: Props) {
        if(this.props.user.permissions !== prevProps.user.permissions){
            if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_VISIBLE_SUPPLIERS) && this.props.user.visibleSuppliers.length < 1) {
                this.props.getVisibleSuppliers();
            }
        }
    }

    componentDidMount() {
        if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_VISIBLE_SUPPLIERS) && this.props.user.visibleSuppliers.length < 1) {
            this.props.getVisibleSuppliers();
        }
    }

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        if(nextProps.tsCodes.length === 0) return {tsCodeSuggestions: []};

        const list = state.tsCodeSuggestions.slice();
        for (const tsCode of nextProps.tsCodes) {
            if(list.length > 0 && list.some(e => e.id === tsCode.id && e.value === tsCode.name)) continue;
            list.push({
                id: tsCode.id,
                value: tsCode.name,
                displayValue: tsCode.name,
                currentCount: tsCode.currentCount,
                totalCount: tsCode.totalCount
            });
        }

        return {tsCodeSuggestions: list};
    }

    render() {
        const inputProps: InputProps<StoreSupplier> = {
            placeholder: '',
            value: this.state.selectedTemplateSupplierValue,
            onChange: (event: React.FormEvent<HTMLElement> & { currentTarget: HTMLElement }, newValue: { newValue: any }) => this.onChange(event, newValue),
            onBlur: (_: React.FocusEvent<HTMLElement>, suggestion : Autosuggest.BlurEvent<StoreSupplier>|undefined) => this.onBlur(suggestion?.highlightedSuggestion as StoreSupplier)
        };

        return (
            <div className="timesheets-report">
                <div className="timesheets-information">
                    <div className="month-picker-box-container info-container">
                        <label>Period</label>
                        <MonthYearPicker
                            minDate={new Date(2000, 0, 1)}
                            maxDate={DateHelper.addMonths(new Date(), 12)}
                            onChange={(month: number, year: number) => this.handleAMonthChange(year, month)}
                        />
                    </div>
                    <div className="info-container">
                        <label>Company template</label>
                        <Autosuggest suggestions={this.props.suppliers}
                                     onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                     onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                     getSuggestionValue={this.getSuggestionValue}
                                     renderSuggestion={this.renderSuggestion}
                                     inputProps={inputProps}
                                     highlightFirstSuggestion />
                    </div>
                    <div className="info-container">
                        <label>Hours per week</label>
                        <ContractHoursToggle />
                    </div>
                    <div className="timesheets-buttons">
                        <input type="submit" className="export-button" disabled={!this.canExport()} onClick={this.exportPdf} value="Export as PDF"/>
                    </div>
                    {this.props.reportRequested ?
                    <div className="export-spinner">
                        <i className="fas fa-spinner fa-spin" />
                    </div> : undefined}
                </div>
                <div className="timesheets-selection-container">
                    <Preset location="timesheetsreport"
                            presetData={this.getDataForPreset()}
                            onPresetChange={this.onPresetChange}
                            displayProperties={[
                                { property:'userSelection', displayName:'Users'},
                                { property:'tsCodeSelection', displayName:'Timesheet Codes'},
                                { property:'supplierSelection', displayName:'Suppliers'}
                            ]}
                            defaultEmptyPreset={{location: 'actualsreport', name: '(empty)', data: {userSelection: [], tsCodeSelection: [], supplierSelection: []}}}
                            />
                    <div className="timesheets-selection">
                        <div className="timesheets-selection-box">
                            <AutoSuggestList<string> listName="Users"
                                            selected={this.state.userSelection}
                                            suggestions={this.getUserSuggestions()}
                                            fetch={this.fetchUsers}
                                            selectSuggestion={this.addUser}
                                            unSelectSuggestion={this.removeUser}/>
                        </div>
                        <div className="timesheets-selection-box">
                            <AutoSuggestList<string> listName="Timesheet Codes"
                                            selected={this.state.tsCodeSelection}
                                            suggestions={this.getTsCodeSuggestions()}
                                            fetch={this.fetchTsCodes}
                                            selectSuggestion={this.addTsCode}
                                            unSelectSuggestion={this.removeTsCode}
                                            clearPagingList={this.clearTsCodePagingList}
                                            pagingAllowedOnFetch/>
                        </div>
                        <div className="timesheets-selection-box">
                            <AutoSuggestList<string> listName="Companies"
                                            selected={this.state.supplierSelection}
                                            suggestions={this.getSupplierSuggestions()}
                                            fetch={this.fetchSuppliers}
                                            selectSuggestion={this.addCompany}
                                            unSelectSuggestion={this.removeCompany}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    suppliers: StoreSupplier[],
    tsCodes: StoreTsCodes,
    reportRequested: boolean,
    settings: StoreSettings,

    getSuppliers: (search: string) => Action,
    clearSuppliers: () => Action,
    clearTsCodes: () => Action,
    getTsCodes: (search: string, page: number, showOldAndReadOnly: boolean, showAllAliases: boolean, month: number, year: number) => Action,
    exportTimesheetsPdf: (timesheetsReport: TimesheetsReportType) => Action,
    getVisibleSuppliers: () => Action
};

type State = {
    userFilter: string,
    supplierFilter: string,
    year: number,
    month: number,
    userSelection: SuggestionInfo<string>[],
    supplierSelection: SuggestionInfo<string>[],
    tsCodeSelection: SuggestionInfo<string>[],
    tsCodeSuggestions: SuggestionInfo<string>[],
    selectedTemplateSupplierValue: string,
    selectedTemplateSupplier: StoreSupplier | null | undefined
};

export default TimesheetsReport;
