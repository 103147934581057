import React from 'react';

import { AnyAction, Dispatch, bindActionCreators} from 'redux';
import { ConnectedProps, connect} from 'react-redux';
import { clearTimeLeftOnOrder, getTimeLeftOnOrder } from '../../../../actions/reportActions';
import { clearTsCodes, getTsCodes, getTsCodesIcPartners} from '../../../../actions/tsCodeActions';

import ApiHelper from '../../../../helpers/apiHelper';
import AutoSuggestList from './AutoSuggestList';

import Preset from '../../../../common/Preset';
import TimeLeftOnOrderDetails from './TimeLeftOnOrderDetails';

import { StoreIcPartnerOnTsCode, StoreState } from '../../../../typescript/storeTypes';
import { SuggestionInfo } from '../../../../typescript/infoTypes';

export class TimeLeftOnOrderReport extends React.Component<Props, State> {
    state: State = {
        tsCodeSelection: [],
        tsCodeSuggestions: [],
        selectedIcPartnerId: null
    };

    fetchTsCodes: (value: string, page?: number) => void = ApiHelper.debounce((value: string, page?: number) => this.props.getTsCodes(value, page as number, true, true, new Date().getMonth() + 1, new Date().getFullYear()), 500);

    clearTsCodePagingList = ():void => {
        this.props.clearTsCodes();
    };

    addTsCode = (tsCodes: SuggestionInfo<string>[]): void => {
        if(tsCodes.length > 1) {
            this.setState({tsCodeSelection: [tsCodes[0]], selectedIcPartnerId: null});
        } else {
            this.setState({tsCodeSelection: tsCodes, selectedIcPartnerId: null});
        }

        this.props.getTsCodesIcPartners(tsCodes[0].id);
    };

    removeTsCode = (): void => {
        this.setState({tsCodeSelection: [], selectedIcPartnerId: null});
        this.props.getTsCodesIcPartners('0');
    };

    getTsCodeSuggestions = (): SuggestionInfo<string>[] => {
        return this.state.tsCodeSuggestions.slice();
    };

    getTimeLeftOnOrder = (icPartnerId: number): void => {
        this.props.getTimeLeftOnOrder({icPartnerId: icPartnerId, tsCodeId: this.state.tsCodeSelection[0].id});
    };

    selectIcPartner = (icPartner: StoreIcPartnerOnTsCode): void => {
        this.setState({selectedIcPartnerId: icPartner.id});
        this.getTimeLeftOnOrder(icPartner.id);
    };

    getDataForPreset = (): any => {
        return {
            tsCodeSelection: this.state.tsCodeSelection
        };
    };

    onPresetChange = (data: any): void => {
        if(data) {
            this.setState(data);
            if(data.tsCodeSelection.length > 0) {
                this.props.getTsCodesIcPartners(data.tsCodeSelection[0].id);
                this.setState({selectedIcPartnerId: null});
            } else {
                this.props.clearTimeLeftOnOrder();
            }
        }
    };

    componentWillUnmount() {
        this.props.clearTimeLeftOnOrder();
    }

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        if(nextProps.tsCodes.length === 0) return {tsCodeSuggestions: []};

        const list = state.tsCodeSuggestions.slice();
        for (const tsCode of nextProps.tsCodes) {
            if(list.length > 0 && list.some(e => e.id === tsCode.id && e.value === tsCode.name)) continue;
            list.push({
                id: tsCode.id,
                value: tsCode.name,
                displayValue: tsCode.name,
                currentCount: tsCode.currentCount,
                totalCount: tsCode.totalCount
            });
        }

        return {tsCodeSuggestions: list};
    }

    render() {
        return (
            <div className="time-left-on-order-report">
                <div className="time-left-on-order-report-preset">
                    <Preset location="timeleftonorderreport"
                            presetData={this.getDataForPreset()}
                            onPresetChange={this.onPresetChange}
                            displayProperties={[
                                { property:'tsCodeSelection', displayName:'Timesheet Codes'}
                            ]}
                            defaultEmptyPreset={{location: 'timeleftonorderreport', name: '(empty)', data: {tsCodeSelection: []}}}
                    />
                </div>
                <div className="time-left-on-order-report-tscode">
                    <AutoSuggestList<string>
                        listName="Timesheet Code"
                        selected={this.state.tsCodeSelection}
                        suggestions={this.getTsCodeSuggestions()}
                        fetch={this.fetchTsCodes}
                        selectSuggestion={this.addTsCode}
                        unSelectSuggestion={this.removeTsCode}
                        clearPagingList={this.clearTsCodePagingList}
                        singleItemSelection
                        pagingAllowedOnFetch/>
                </div>
                <div className="time-left-on-order-report-company">
                    <div className="time-left-on-order-report-company-title">Company</div>
                    <div className="time-left-on-order-report-company-options">{
                        this.props.icPartnersOnTsCode.map((icpartner, index) => {
                        return (<div className={'time-left-on-order-report-company-option' + (this.state.selectedIcPartnerId === icpartner.id ? ' selected' : '')} key={index} onClick={() => this.selectIcPartner(icpartner)}>{icpartner.name}</div>);
                        })}
                    </div>
                </div>

                {(this.props.timeLeftOnOrder.tsCodeName === ''  && this.props.timeLeftOnOrder.icPartner === '') ? undefined : <TimeLeftOnOrderDetails timeLeftOnOrder={this.props.timeLeftOnOrder}/>}
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        tsCodes: state.tsCodes,
        icPartnersOnTsCode: state.reporting.icPartnersOnTsCode,
        timeLeftOnOrder: state.reporting.timeLeftOnOrder
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({clearTimeLeftOnOrder, clearTsCodes, getTsCodes, getTsCodesIcPartners, getTimeLeftOnOrder}, dispatch);
}

type State = {
    tsCodeSelection: SuggestionInfo<string>[],
    tsCodeSuggestions: SuggestionInfo<string>[],
    selectedIcPartnerId?: number | null
};

export default connector(TimeLeftOnOrderReport);
