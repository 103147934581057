import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';

import {Day} from '../../../../typescript/dateTypes';

class TimesheetNavigationDay extends React.PureComponent<Props> {
    render() {
        return (
            <div className={'day' + (DateHelper.isToday(this.props.day) ? ' day-highlight-border' : '')}>
                <div className={'day-week' + (DateHelper.isToday(this.props.day) ? ' day-highlight' : '')}>
                    {DateHelper.formatDayOfWeek(this.props.day)}
                </div>
                <div className={'day-month' + (DateHelper.isToday(this.props.day) ? ' day-highlight' : '')}>
                    {DateHelper.formatDayOfMonth(this.props.day)}
                </div>
            </div>
        );
    }
}

type Props = {
    day: Day
};

export default TimesheetNavigationDay;
