import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import { ProjectBasedTimesheetReportType } from '../typescript/reportTypes';

export function getAllProjects(): Action {
    return {type: ActionTypes.GET_ALL_PROJECTS_REQUESTED, payload: null};
}

export function generateProjectReport(project: ProjectBasedTimesheetReportType): Action {
    return {type: ActionTypes.GENERATE_PROJECT_REPORT_REQUESTED, payload: project};
}

export function clearProjects(): Action {
    return {type: ActionTypes.CLEAR_PROJECTS_REQUESTED, payload: null};
}
