import { ProgressTrackerStep } from '../../../../typescript/importTypes';
import React from 'react';

class ProgressTracker extends React.Component<Props> {
    isFirstStep = (step: ProgressTrackerStep): boolean => {
        return this.props.steps.indexOf(step) === 0;
    };

    allowHover = (step: ProgressTrackerStep): boolean => {
        if(step.action) {
            return true;
        }

        return false;
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    executeStepAction = (func: Function | undefined | null): void => {
        if(func === undefined || func === null) return;
        func();
    };

    render() {
        return (
            <ul className="progress-tracker progress-tracker--text progress-tracker--center">
                {this.props.steps.map(s =>
                    <li key={s.text} className={`progress-step ${s.isComplete ? 'is-complete' : ''} ${s.isActive ? 'is-active' : ''} ${this.allowHover(s) ? 'allow-hover' : 'no-hover'}`} onClick={() => this.executeStepAction(s.action)}>
                        <div className="progress-marker"/>
                        <div className="progress-text">
                            <div className={`progress-title ${this.props.showStartOver && this.isFirstStep(s) ? 'startover': ''}`}>{this.props.showStartOver && this.isFirstStep(s) ? <i className="fas fa-redo" aria-hidden="true"/> : null}{s.text}</div>
                        </div>
                    </li>
                )}
            </ul>
        );
    }
}

type Props = {
    steps: ProgressTrackerStep[],
    showStartOver: boolean
};

export default ProgressTracker;
