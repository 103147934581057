import { PermissionTypes } from '../../../../types/permissionTypes';
import ApiHelper from '../../../../helpers/apiHelper';
import AutoSuggestList from './AutoSuggestList';
import ContractHoursToggle from '../../../../common/ContractHoursToggle';
import DatePicker from '../../common/DatePicker';
import OrderByToggle from '../../../../common/OrderByToggle';
import React from 'react';
import StringHelper from '../../../../helpers/stringHelper';
import moment from 'moment';

import {
    StoreSettings,
    StoreTsCodes,
    StoreUser
} from '../../../../typescript/storeTypes';

import {Action} from '../../../../typescript/actionTypes';
import {ActualsReportType} from '../../../../typescript/reportTypes';
import { FormatTypes } from '../../../../types/formatTypes';
import {SuggestionInfo} from '../../../../typescript/infoTypes';
import { onlyUnique } from '../../../../helpers/util';
import DateHelper from '../../../../helpers/dateHelper';
import EmployeeSortHelper from '../../../../helpers/employeeSortHelper';
import PermissionHelper from '../../../../helpers/permissionHelper';
import Preset from '../../../../common/Preset';
import WildCardHelper from '../../../../helpers/wildCardHelper';


class ActualsReport extends React.Component<Props, State> {
    state: State = {
        selectedTo: new Date(),
        selectedFrom: new Date(),
        selectedFromValid: false,
        selectedToValid: false,
        initialTo: true,
        initialFrom: true,
        userSelection: [],
        tsCodeSelection: [],
        supplierSelection: [],
        tsCodeSuggestions: [],
        userFilter: '',
        supplierFilter: ''
    };

    datesAreInitial = (): boolean => this.state.initialFrom || this.state.initialTo;

    dateFromIsSmallerThanDateTo = (): boolean => {
        if(this.datesAreInitial()) return true; //no error when initial.
        if(!this.datesAreValid()) return true; // don't show this error when the dates are invalid.

        return moment(this.state.selectedFrom).isBefore(moment(this.state.selectedTo));
    };

    datesAreValid = (): boolean => this.state.selectedFromValid && this.state.selectedToValid;

    handleFromChange = (selectedFrom: Date|undefined): void => {
        if(selectedFrom === undefined) {
            this.setState({selectedFrom: DateHelper.resetTime(new Date()), initialFrom: true});
        } else {
            this.setState({selectedFrom: new Date(selectedFrom), initialFrom: false, selectedFromValid: true});
        }
    };

    handleToChange = (selectedTo: Date|undefined): void => {
        if(selectedTo === undefined) {
            this.setState({selectedTo: DateHelper.resetTime(new Date()), initialTo: true});
        } else {
            this.setState({selectedTo: new Date(selectedTo), initialTo: false, selectedToValid: true});
        }
    };

    exportPdf = (): void => {
        if(!this.canExport()) return;

        const data = this.getExportData();
        this.props.exportActualsPdf(data);
    };

    exportExcel = (): void => {
        if(!this.canExport()) return;

        const data = this.getExportData();
        this.props.exportActualsExcel(data);
    };

    exportCsv = (): void => {
        if(!this.canExport()) return;

        const data = this.getExportData();
        this.props.exportActualsCsv(data);
    };

    canExport = (): boolean => {
        if (this.props.reportRequested) return false;
        if (this.datesAreInitial()) return false;
        if (!this.datesAreValid()) return false;
        if (!this.dateFromIsSmallerThanDateTo()) return false;
        if (this.selectionsEmpty()) return false;

        return true;
    };

    selectionsEmpty = (): boolean => this.state.supplierSelection.length === 0 && this.state.tsCodeSelection.length === 0 && this.state.userSelection.length === 0;

    getExportData = (): ActualsReportType => {
        const start = this.state.selectedFrom;
        const end = this.state.selectedTo;

        return {
            companyIds: this.state.supplierSelection.map(s => s.id),
            tsCodeIds: this.state.tsCodeSelection.map(t => t.id).filter(onlyUnique),
            userIds: this.state.userSelection.map(u => u.id),
            endDate: end,
            startDate: start,
            hoursOnContract: this.props.settings.defaultContractHours,
            orderBy: this.props.orderBy
        };
    };

    fetchUsers = (value: string) => this.setState({userFilter: value});
    fetchSuppliers = (value: string) => this.setState({supplierFilter: value});
    fetchTsCodes: (value: string, page?: number) => void = ApiHelper.debounce((value: string, page?: number) => this.props.getTsCodes(value, page as number, true, true, this.state.selectedFrom.getMonth() + 1, this.state.selectedFrom.getFullYear()), 750);

    clearTsCodePagingList = ():void => {
        this.props.clearTsCodes();
    };

    getTsCodeSuggestions = (): SuggestionInfo<string>[] => {
        return this.state.tsCodeSuggestions.slice();
    };

    getUserSuggestions = (): SuggestionInfo<string>[] => {
        let users = this.props.user.visibleUsers.slice();

        if (this.state.userFilter && this.state.userFilter !== '') {
            users = users.filter(vu => EmployeeSortHelper.isHit(this.state.userFilter, vu));
        }

        const list: SuggestionInfo<string>[] = [];
        for (const user of users) {
            if(list.length > 0 && list.some(e=>e.id === user.id)) continue;
            list.push({
                id: user.id,
                value: `${StringHelper.getEmployeeFullName(user, this.props.settings.firstNameLastName, false, true)}`,
                displayValue: `${StringHelper.getEmployeeFullName(user, this.props.settings.firstNameLastName, false, true)}`,
                currentCount: 0,
                totalCount:0
            });
        }

        return list;
    };

    getSupplierSuggestions = (): SuggestionInfo<string>[] => {
        let suppliers = this.props.user.visibleSuppliers.slice();

        if(this.state.supplierFilter && this.state.supplierFilter !== '') {
            suppliers = suppliers.filter(vs => WildCardHelper.containsString(this.state.supplierFilter, vs.name));
        }

        const list: SuggestionInfo<string>[] = [];
        for (const supplier of suppliers) {
            if(list.length > 0 && list.some(e=>e.id === supplier.id)) continue;
            list.push({
                id: supplier.id,
                value: supplier.name,
                displayValue: supplier.name,
                currentCount: 0,
                totalCount: 0
            });
        }

        return list;
    };

    addUser = (users: any[]): void => {
        const newList = this.state.userSelection.slice();

        users.forEach(u => {
            if(newList.some(user => user.id === u.id)) {
                return;
            }
            newList.push(u);
        });

        this.setState({userSelection: newList});
    };

    addTsCode = (tsCodes: any[]): void => {
        const newList = this.state.tsCodeSelection.slice();

        tsCodes.forEach(t => {
            if(newList.some(tscode => tscode.id === t.id)) {
                return;
            }
            newList.push(t);
        });

        this.setState({tsCodeSelection: newList});
    };

    addCompany = (suppliers: any[]): void => {
        const newList = this.state.supplierSelection.slice();

        suppliers.forEach(s => {
            if(newList.some(supplier => supplier.id === s.id)) {
                return;
            }
            newList.push(s);
        });

        this.setState({supplierSelection: newList});
    };

    removeUser = (ids: number[]): void => {
        const newSelection = this.state.userSelection.filter(id => ids.indexOf(id.id) === -1);
        this.setState({userSelection: newSelection});
    };

    removeTsCode = (ids: string[]): void => {
        const newSelection = this.state.tsCodeSelection.filter(id => ids.indexOf(id.id) === -1);
        this.setState({tsCodeSelection: newSelection});
    };

    removeCompany = (ids: number[]): void => {
        const newSelection = this.state.supplierSelection.filter(id => ids.indexOf(id.id) === -1);
        this.setState({supplierSelection: newSelection});
    };

    validateFrom = (e: any): void => {
        if (!this.isValidDate(e.target.value)) {
            this.setState({selectedFromValid: false});
        }
    };

    validateTo = (e: any): void => {
        if (!this.isValidDate(e.target.value)) {
            this.setState({selectedToValid: false});
        }
    };

    isValidDate = (value: string): boolean => {
        const momentDate = moment(value, 'DD/MM/YYYY', true);
        if (momentDate.isValid()) {
            // validate the years.
            const date = momentDate.toDate();
            if (date.getFullYear() > 1990 && date.getFullYear() <= new Date().getFullYear()) {
                return true;
            } else {
                return false;
            }
        } else { // Not a valid date anyway
            return false;
        }
    };

    getDataForPreset = (): any => {
        return {
            userSelection: this.state.userSelection,
            tsCodeSelection: this.state.tsCodeSelection,
            supplierSelection: this.state.supplierSelection
        };
    };

    onPresetChange = (data: any): void => {
        this.setState(data);
    };

    componentDidMount() {
        if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_VISIBLE_SUPPLIERS) && this.props.user.visibleSuppliers.length < 1) {
            this.props.getVisibleSuppliers();
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if(this.props.user.permissions !== prevProps.user.permissions){
            if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_VISIBLE_SUPPLIERS) && this.props.user.visibleSuppliers.length < 1) {
                this.props.getVisibleSuppliers();
            }
        }
    }

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        if(nextProps.tsCodes.length === 0) return {tsCodeSuggestions: []};

        const list = state.tsCodeSuggestions.slice();
        for (const tsCode of nextProps.tsCodes) {
            if(list.length > 0 && list.some(e => e.id === tsCode.id && e.value === tsCode.name)) continue;
            list.push({
                id: tsCode.id,
                value: tsCode.name,
                displayValue: tsCode.name,
                currentCount: tsCode.currentCount,
                totalCount: tsCode.totalCount
            });
        }

        return {tsCodeSuggestions: list};
    }

    dayPickerFrom: Date | undefined;
    dayPickerTo: Date | undefined;

    render() {
        return (
            <div className="actuals-report">
                <div className="actuals-information">
                    <div className={'dates-error' + (this.dateFromIsSmallerThanDateTo() ? ' hidden' : '')}>From date needs to be smaller than to date</div>
                    <div className="info-container">
                        <label>From</label>
                        <DatePicker
                            onHandleChange={this.handleFromChange}
                            placeholder={`Eg. ${moment().locale('en').format(FormatTypes.DATE_FORMAT.toUpperCase())}`}
                        />
                    </div>
                    <div className="info-container">
                        <label>To</label>
                        <DatePicker
                            onHandleChange={this.handleToChange}
                            placeholder={`Eg. ${moment().locale('en').format(FormatTypes.DATE_FORMAT.toUpperCase())}`}
                        />
                    </div>
                    <div className="info-container">
                        <label>Hours per week</label>
                        <ContractHoursToggle />
                    </div>
                    <div className="info-container">
                        <label>Order by</label>
                        <OrderByToggle />
                    </div>
                    <div className="actuals-buttons">
                        <input type="submit" className="export-button" disabled={!this.canExport()} onClick={this.exportPdf} value="Export as PDF"/>
                        <input type="submit" className="export-button" disabled={!this.canExport()} onClick={this.exportExcel} value="Export as Excel"/>
                        <input type="submit" className="export-button" disabled={!this.canExport()} onClick={this.exportCsv} value="Export as raw data"/>
                    </div>
                    {this.props.reportRequested ?
                    <div className="export-spinner">
                        <i className="fas fa-spinner fa-spin" />
                    </div> : undefined}
                </div>
                <div className="actuals-selection-container">
                    <Preset location="actualsreport"
                            presetData={this.getDataForPreset()}
                            onPresetChange={this.onPresetChange}
                            displayProperties={[
                                { property:'userSelection', displayName:'Users'},
                                { property:'tsCodeSelection', displayName:'Timesheet Codes'},
                                { property:'supplierSelection', displayName:'Suppliers'}
                            ]}
                            defaultEmptyPreset={{location: 'actualsreport', name: '(empty)', data: {userSelection: [], tsCodeSelection: [], supplierSelection: []}}}
                            />
                    <div className="actuals-selection">
                        <div className="actuals-selection-box">
                            <AutoSuggestList<string> listName="Users"
                                            selected={this.state.userSelection}
                                            suggestions={this.getUserSuggestions()}
                                            fetch={this.fetchUsers}
                                            selectSuggestion={this.addUser}
                                            unSelectSuggestion={this.removeUser}/>
                        </div>
                        <div className="actuals-selection-box">
                            <AutoSuggestList<string> listName="Timesheet Codes"
                                            selected={this.state.tsCodeSelection}
                                            suggestions={this.getTsCodeSuggestions()}
                                            fetch={this.fetchTsCodes}
                                            selectSuggestion={this.addTsCode}
                                            unSelectSuggestion={this.removeTsCode}
                                            clearPagingList={this.clearTsCodePagingList}
                                            pagingAllowedOnFetch/>
                        </div>
                        <div className="actuals-selection-box">
                            <AutoSuggestList<string> listName="Companies"
                                            selected={this.state.supplierSelection}
                                            suggestions={this.getSupplierSuggestions()}
                                            fetch={this.fetchSuppliers}
                                            selectSuggestion={this.addCompany}
                                            unSelectSuggestion={this.removeCompany}/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    tsCodes: StoreTsCodes,
    reportRequested: boolean,
    settings: StoreSettings,
    orderBy: string,

    clearTsCodes: () => Action,
    getTsCodes: (search: string, page: number, showOldAndReadOnly: boolean, showAllAliases: boolean, month: number, year: number) => Action,
    exportActualsPdf: (actualsReport: ActualsReportType) => Action,
    exportActualsExcel: (actualsReport: ActualsReportType) => Action,
    exportActualsCsv: (actualsReport: ActualsReportType) => Action,
    getVisibleSuppliers: () => Action
};

type State = {
    selectedFrom: Date,
    selectedTo: Date,
    selectedFromValid: boolean,
    selectedToValid: boolean,
    initialFrom: boolean,
    initialTo: boolean,
    userSelection: SuggestionInfo<string>[],
    supplierSelection: SuggestionInfo<string>[],
    tsCodeSelection: SuggestionInfo<string>[],
    tsCodeSuggestions: SuggestionInfo<string>[],
    userFilter: string,
    supplierFilter: string
};

export default ActualsReport;
