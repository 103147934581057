import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import { Period } from '../typescript/dateTypes';
import {addAssignment} from '../../src/actions/assignmentActions';
import {getMostRecentTsCodes} from '../../src/actions/tsCodeActions';
import DateHelper from '../../src/helpers/dateHelper';
import DeserifyHelper from '../helpers/DeserifyHelper';
import React from 'react';

import {StoreState} from '../typescript/storeTypes';

export class EmptyTimesheetToast extends React.PureComponent<Props> {
    addAssignments = () => {
        this.props.mostRecentTsCodes.forEach(tsCode => {
            const assignment = {
                username: this.props.user.impersonatedUser.username,
                tsCodeId: tsCode.id,
                name: tsCode.name,
                description: tsCode.description,
                month: this.props.period.month,
                year: this.props.period.year,
                internalApprovers: tsCode.internalApprovers,
                customerApprovers: tsCode.customerApprovers,
                internalApprovalType: tsCode.internalApprovalType,
                customerApprovalType: tsCode.customerApprovalType,
                isBillable: tsCode.isBillable,
                comments: ''
            };
            this.props.addAssignment(assignment, this.props.user);
        });
    };

    componentDidMount() {
        const month = DateHelper.addMonths(this.props.period.startDate, -1);
        this.props.getMostRecentTsCodes(this.props.user.impersonatedUser.username,
            month.getMonth() + 1,
            month.getFullYear());
    }

    render() {
        return (
            <div>
                <div className="copy-timesheetcode-message">Copy timesheet codes of previous month?</div>
                <input type="submit" value="Ok" onClick={this.addAssignments}/>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        period: DeserifyHelper.deserify<Period>(state.timesheet.period),
        user: state.user,
        mostRecentTsCodes: state.mostRecentTsCodes
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({getMostRecentTsCodes, addAssignment}, dispatch);
}

export default connector(EmptyTimesheetToast);
