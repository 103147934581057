import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import { base64ToBlob } from 'file64';
import {saveAs} from 'file-saver';

import {StoreInvoiceDetails, StoreSentInvoice} from '../typescript/storeTypes';

export default function (state: StoreInvoiceDetails = InitialState.invoiceDetails, action: Action): StoreInvoiceDetails {

    switch (action.type) {
        case ActionTypes.SEARCH_INVOICE_SUCCEEDED: {
            if(action.payload.download) {
                base64ToBlob(action.payload.pdf.base64).then(r => saveAs(r, action.payload.pdf.fileName));
                return state;
            }
            return Object.assign({}, state, {invoice: action.payload.pdf});
        }
        case ActionTypes.CLEAR_INVOICE_REQUESTED: {
            return Object.assign({}, state, {invoice: InitialState.invoiceDetails.invoice});
        }
        case ActionTypes.CLEAR_SENT_INVOICES_REQUESTED: {
            return Object.assign({}, state, {sentInvoices: []});
        }
        case ActionTypes.GET_SENT_INVOICES_SUCCEEDED: {
            const newSentInvoices = state.sentInvoices.slice() as StoreSentInvoice[];

            (action.payload as StoreSentInvoice[]).filter(i => !newSentInvoices.some(inv => inv.invoiceId === i.invoiceId)).map(i => newSentInvoices.push(i));

            return Object.assign({}, state, {sentInvoices: newSentInvoices});
        }
        case ActionTypes.SEARCH_INVOICENUMBERS_SUCCEEDED: {
            return Object.assign({}, state, {invoiceNumbers: action.payload});
        }
        case ActionTypes.SEARCH_INVOICENUMBERS_FAILED: {
            return Object.assign({}, state, {invoiceNumbers: action.payload});
        }
        case ActionTypes.CLEAR_INVOICE_NUMBERS_SUCCEEDED: {
            return Object.assign({}, state, {invoiceNumbers: []});
        }
        case ActionTypes.GET_INVOICE_SUCCEEDED: {
            base64ToBlob(action.payload.pdf.base64).then(r => saveAs(r, action.payload.pdf.fileName));

            return state;
        }
        default:
            return state;
    }
}
