class NumberHelper {
    static getAmountOfDecimals = (value: number|undefined): number => {
        if(value === undefined || value === null || isNaN(value)) return 0;
        
        if (Math.floor(value) === value) return 0;
    
        const str = value.toString();
        
        if(str.includes('.')) {
            return str.split('.')[1].length;
        } else {
            return 0;
        }
    };
    
    static getAmountOfIntegers = (value: number|undefined): number => {
        if(value === undefined || value === null || isNaN(value)) return 0;

        return Math.floor(value).toString().length;
    };
}

export default NumberHelper;
