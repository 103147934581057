import {call, put, takeEvery} from 'redux-saga/effects';

import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import { IsDuplicateSigningHubQueue } from '../typescript/signingHubTypes';
import { MessageTypes } from '../types/messageTypes';
import DeserifyHelper from '../helpers/DeserifyHelper';
import MessageHelper from '../helpers/messageHelper';
import signingHubApi from '../api/signingHubApi';
import timesheetApi from '../api/timesheetApi';

function* sendMonthlyToSigningHub(action: Action): Generator<any, any, any> {
    try {
        const payload = DeserifyHelper.deserify(action.payload);
        yield isDuplicateSigningHubQueue({
            assignmentIds: payload.monthlyTimesheetToSigningHub.assignments,
            signingHubQueueModel: JSON.stringify(payload.monthlyTimesheetToSigningHub)
        });

        yield call(signingHubApi.sendMonthlyToSigningHub, payload.monthlyTimesheetToSigningHub);

        if(payload.monthlyTimesheetToSigningHub.saveSettings === true) {
            yield put({
                type: ActionTypes.TIMESHEET_REQUEST_SUCCEEDED,
                payload: {
                    period: payload.period,
                    timesheetCodes: yield call(timesheetApi.getTimesheet, payload.period, payload.employee)
                }
            });
        }

        showSubmitMessage();

        yield put({
            type: ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_SUCCEEDED,
            payload: {}
        });
    } catch (e: any) {
        yield put({type: ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_FAILED, payload: e.message});
    }
}

function* sendMonthlyToSigningHubImpersonated(action: Action): Generator<any, any, any> {
    try {
        const payload = DeserifyHelper.deserify(action.payload);
        yield isDuplicateSigningHubQueue({
            assignmentIds: payload.monthlyTimesheetToSigningHub.assignments,
            signingHubQueueModel: JSON.stringify(payload.monthlyTimesheetToSigningHub)
        });

        yield call(signingHubApi.sendMonthlyToSigningHubImpersonated, {monthlyTimesheetToSigningHub: payload.monthlyTimesheetToSigningHub, username: payload.employee.username});

        if(payload.monthlyTimesheetToSigningHub.saveSettings === true) {
            yield put({
                type: ActionTypes.TIMESHEET_REQUEST_SUCCEEDED,
                payload: {
                    period: payload.period,
                    timesheetCodes: yield call(timesheetApi.getTimesheet, payload.period, payload.employee)
                }
            });
        }

        showSubmitMessage();

        yield put({
            type: ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_SUCCEEDED,
            payload: {}
        });
    } catch (e: any) {
        yield put({type: ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_FAILED, payload: e.message});
    }
}

function* sendWeeklyToSigningHub(action: Action): Generator<any, any, any> {
    try {
        const payload = DeserifyHelper.deserify(action.payload);
        yield isDuplicateSigningHubQueue({
            assignmentIds: payload.weeklyTimesheetToSigningHub.assignments,
            signingHubQueueModel: JSON.stringify(payload.weeklyTimesheetToSigningHub)
        });

        yield call(signingHubApi.sendWeeklyToSigningHub, payload.weeklyTimesheetToSigningHub);

        if(payload.weeklyTimesheetToSigningHub.saveSettings === true) {
            yield put({
                type: ActionTypes.TIMESHEET_REQUEST_SUCCEEDED,
                payload: {
                    period: payload.period,
                    timesheetCodes: yield call(timesheetApi.getTimesheet, payload.period, payload.employee)
                }
            });
        }

        showSubmitMessage();

        yield put({
            type: ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_SUCCEEDED,
            payload: {}
        });
    } catch (e: any) {
        yield put({type: ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_FAILED, payload: e.message});
    }
}

function* sendWeeklyToSigningHubImpersonated(action: Action): Generator<any, any, any> {
    try {
        const payload = DeserifyHelper.deserify(action.payload);
        yield isDuplicateSigningHubQueue({
            assignmentIds: payload.weeklyTimesheetToSigningHub.assignments,
            signingHubQueueModel: JSON.stringify(payload.weeklyTimesheetToSigningHub)
        });

        yield call(signingHubApi.sendWeeklyToSigningHubImpersonated, {weeklyTimesheetToSigningHub: payload.weeklyTimesheetToSigningHub, username: payload.employee.username});

        if(payload.weeklyTimesheetToSigningHub.saveSettings === true) {
            yield put({
                type: ActionTypes.TIMESHEET_REQUEST_SUCCEEDED,
                payload: {
                    period: payload.period,
                    timesheetCodes: yield call(timesheetApi.getTimesheet, payload.period, payload.employee)
                }
            });
        }

        showSubmitMessage();

        yield put({
            type: ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_SUCCEEDED,
            payload: {}
        });
    } catch (e: any) {
        yield put({type: ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_FAILED, payload: e.message});
    }
}

function* isDuplicateSigningHubQueue(isDuplicateSigningHubQueue: IsDuplicateSigningHubQueue): Generator<any, any, any> {
    const response = yield call(signingHubApi.postIsDuplicateSigningHubQueue, isDuplicateSigningHubQueue);

    if(response && response.result === true) {
        const message = 'Digital signing failed. Request for approval with duplicate timesheet codes already exists. Retry is only allowed after 30 minutes.';
        MessageHelper.showMessage({type: MessageTypes.ERROR, message: message});
        throw new Error(message);
    }
}

function* cancelSigningHubQueue(action: Action): Generator<any, any, any> {
    try{
        const response = yield call(signingHubApi.postCancelSigningHubQueue, action.payload);

        if(response && response.result === true) {
            const message = 'The signing process of the document was successfully canceled.';
            MessageHelper.showMessage({type: MessageTypes.INFO, message: message});
        }
        yield put({type: ActionTypes.CANCEL_SIGNING_HUB_QUEUE_SUCCEEDED, payload: action.payload});
    } catch(e) {
        yield put({type: ActionTypes.CANCEL_SIGNING_HUB_QUEUE_FAILED, payload: e.message});
    }

}

function showSubmitMessage() {
    MessageHelper.showMessage({type: MessageTypes.INFO, message: 'Digital signing request was submitted.'});
}

function* signingHubSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_REQUESTED, sendMonthlyToSigningHub);
    yield takeEvery(ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED, sendMonthlyToSigningHubImpersonated);
    yield takeEvery(ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_REQUESTED, sendWeeklyToSigningHub);
    yield takeEvery(ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED, sendWeeklyToSigningHubImpersonated);
    yield takeEvery(ActionTypes.CANCEL_SIGNING_HUB_QUEUE_REQUESTED, cancelSigningHubQueue);
}

export default signingHubSaga;
