import {AnyAction, Dispatch, bindActionCreators} from 'redux';

import {ConnectedProps, connect} from 'react-redux';
import { PermissionTypes } from '../../../../types/permissionTypes';
import { Route, Routes } from 'react-router-dom';
import { WithRouterProps, withRouter } from '../../../../common/WithRouter';
import {clearSuppliers, clearSuppliersEmployees, getSuppliers, getSuppliersEmployees} from '../../../../actions/supplierActions';
import {clearTsCodes, getTsCodes} from '../../../../actions/tsCodeActions';
import {exportActualsCsv, exportActualsExcel, exportActualsPdf, exportTimesheetsLandscapeExcel, exportTimesheetsLandscapePdf, exportTimesheetsPdf, exportTimesheetsWeeklyExcel, exportTimesheetsWeeklyPdf } from '../../../../actions/reportActions';
import { getActiveUsersWithGroups, getVisibleSuppliers } from '../../../../actions/userActions';
import { getGroups } from '../../../../actions/groupActions';
import { updateSetting } from '../../../../actions/settingsActions';
import ActualsReport from './ActualsReport';
import ProjectTimesheetReport from './ProjectTimesheetReport';
import ProtectedRoute  from '../../common/ProtectedRoute';
import React from 'react';
import ReportingContainer from './ReportingContainer';
import TimeLeftOnOrderReport from './TimeLeftOnOrderReport';
import TimesheetsLandscapeReport from './TimesheetsLandscapeReport';
import TimesheetsReport from './TimesheetsReport';
import TimesheetsWeeklyReport from './TimesheetsWeeklyReport';

import { LocationTypes } from '../../../../types/locationTypes';
import { StoreState } from '../../../../typescript/storeTypes';
import {clearProjects, generateProjectReport, getAllProjects} from '../../../../actions/projectActions';


export class Reporting extends React.Component<Props, State> {
    state: State = {
        monthlyOpen: false
    };

    render() {
        return (
            <div className="reporting">
                <Routes>
                    <Route index element={
                        <ReportingContainer
                            user={this.props.user}
                            navigate={this.props.navigate} />
                    }/>
                    <Route path={LocationTypes.REPORTING_ACTUALS} element={
                        <ProtectedRoute {...this.props} permissionType={PermissionTypes.POST_ACTUALS_PDF}>
                            <ActualsReport
                                        user={this.props.user}
                                        tsCodes={this.props.tsCodes}
                                        reportRequested={this.props.reportRequested}
                                        settings={this.props.settings}
                                        orderBy={this.props.orderBy}

                                        getTsCodes={this.props.getTsCodes}
                                        clearTsCodes={this.props.clearTsCodes}
                                        exportActualsPdf={this.props.exportActualsPdf}
                                        exportActualsExcel={this.props.exportActualsExcel}
                                        exportActualsCsv={this.props.exportActualsCsv}
                                        getVisibleSuppliers={this.props.getVisibleSuppliers} />
                        </ProtectedRoute>}
                    />
                    <Route path={LocationTypes.REPORTING_TIMESHEETS} element={
                        <ProtectedRoute {...this.props} permissionType={PermissionTypes.POST_TIMESHEETS_PDF}>
                            <TimesheetsReport
                                        user={this.props.user}
                                        tsCodes={this.props.tsCodes}
                                        suppliers={this.props.suppliers}
                                        settings={this.props.settings}
                                        reportRequested={this.props.reportRequested}

                                        getSuppliers={this.props.getSuppliers}
                                        clearSuppliers={this.props.clearSuppliers}
                                        clearTsCodes={this.props.clearTsCodes}
                                        getTsCodes={this.props.getTsCodes}
                                        exportTimesheetsPdf={this.props.exportTimesheetsPdf}
                                        getVisibleSuppliers={this.props.getVisibleSuppliers} />
                        </ProtectedRoute>}
                    />
                    <Route path={LocationTypes.REPORTING_WEEKLY_TIMESHEETS} element={
                        <ProtectedRoute {...this.props} permissionType={PermissionTypes.POST_TIMESHEETS_WEEKLY_PDF}>
                            <TimesheetsWeeklyReport
                                        user={this.props.user}
                                        tsCodes={this.props.tsCodes}
                                        suppliers={this.props.suppliers}
                                        reportRequested={this.props.reportRequested}
                                        settings={this.props.settings}

                                        getSuppliers={this.props.getSuppliers}
                                        clearTsCodes={this.props.clearTsCodes}
                                        clearSuppliers={this.props.clearSuppliers}
                                        getTsCodes={this.props.getTsCodes}
                                        exportWeeklyTimesheetsPdf={this.props.exportTimesheetsWeeklyPdf}
                                        exportWeeklyTimesheetsExcel={this.props.exportTimesheetsWeeklyExcel}
                                        getVisibleSuppliers={this.props.getVisibleSuppliers} />
                        </ProtectedRoute>}
                    />
                    <Route path={LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS} element={
                        <ProtectedRoute {...this.props} permissionType={PermissionTypes.POST_TIMESHEETS_LANDSCAPE_PDF}>
                            <TimesheetsLandscapeReport
                                user={this.props.user}
                                settings={this.props.settings}
                                reportRequested={this.props.reportRequested}
                                suppliersEmployees={this.props.suppliersEmployees}

                                getVisibleSuppliers={this.props.getVisibleSuppliers}
                                getSuppliersEmployees={this.props.getSuppliersEmployees}
                                clearSuppliersEmployees={this.props.clearSuppliersEmployees}
                                exportTimesheetsLandscapePdf={this.props.exportTimesheetsLandscapePdf}
                                exportTimesheetsLandscapeExcel={this.props.exportTimesheetsLandscapeExcel} />
                        </ProtectedRoute>}
                    />
                    <Route path={LocationTypes.REPORTING_TIME_LEFT_ON_ORDER} element={
                        <ProtectedRoute {...this.props} permissionType={PermissionTypes.POST_TIME_LEFT_ON_ORDER}>
                            <TimeLeftOnOrderReport/>
                        </ProtectedRoute>}
                    />
                    <Route path={LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT} element={
                        <ProtectedRoute {...this.props} permissionType={PermissionTypes.POST_PROJECT_REPORT}>
                            <ProjectTimesheetReport
                                user={this.props.user}
                                projects={this.props.projects}
                                suppliers={this.props.suppliers}
                                settings={this.props.settings}
                                reportRequested={this.props.reportRequested}
                                groups={this.props.groups}

                                getProjects={this.props.getAllProjects}
                                getSuppliers={this.props.getSuppliers}
                                clearSuppliers={this.props.clearSuppliers}
                                generateProjectReport={this.props.generateProjectReport}
                                getActiveUsersWithGroups={this.props.getActiveUsersWithGroups}
                                getGroups={this.props.getGroups}
                                updateSetting={this.props.updateSetting} />
                        </ProtectedRoute>}
                    />
                </Routes>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    monthlyOpen: boolean
};

function mapStateToProps(state: StoreState, props: WithRouterProps) {
    return {
        user: state.user,
        tsCodes: state.tsCodes,
        suppliers: state.suppliers.suppliers,
        reportRequested: state.calls.reportRequested,
        suppliersEmployees: state.reporting.supplierEmployees,
        settings: state.settings,
        orderBy: state.settings.defaultOrderBy,
        projects: state.projects.projects,
        groups: state.group.groups,
        navigate: props.navigate, // routed
        location: props.location // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getTsCodes,
        getSuppliers,
        clearSuppliers,
        clearTsCodes,
        exportActualsCsv,
        exportActualsExcel,
        exportActualsPdf,
        exportTimesheetsPdf,
        exportTimesheetsLandscapePdf,
        exportTimesheetsLandscapeExcel,
        getVisibleSuppliers,
        getSuppliersEmployees,
        clearSuppliersEmployees,
        exportTimesheetsWeeklyPdf,
        exportTimesheetsWeeklyExcel,
        getAllProjects,
        clearProjects,
        getActiveUsersWithGroups,
        getGroups,
        generateProjectReport,
        updateSetting
    }, dispatch);
}

export default withRouter(connector(Reporting));
