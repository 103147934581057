import React from 'react';

class InfoContainer extends React.PureComponent<Props> {
    render() {
        return (
            <div className="infotext-container">
                <i className="fas fa-info-circle"/>
                <label>{this.props.message}</label>
            </div>
        );
    }
}

type Props = {
    message: string
}

export default InfoContainer;
