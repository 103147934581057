import { Day, Period } from '../../../../typescript/dateTypes';
import { EntryLocationInfo, MultiSelectInfo } from '../../../../typescript/infoTypes';
import { MultiEdit, NavigationEvent } from '../../../../typescript/timesheetTypes';
import { StoreMonthYear, StoreSettings, StoreTimesheetCode, StoreTimesheetEntry, StoreTimesheetFromClient, StoreUser } from '../../../../typescript/storeTypes';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import TimesheetCode from './TimesheetCode';
import TimesheetCodeDay from './TimesheetCodeDay';
import TimesheetCodeTotal from './TimesheetCodeTotal';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

class TimesheetRow extends React.PureComponent<Props> {
    onEntryChange = (entry: StoreTimesheetEntry, day: Day): void => {
        this.props.onEntryChange(entry, day, this.props.timesheetCode);
    };

    onTimesheetCodeExpand = (fromEntry?: boolean): void => {
        this.props.onTimesheetCodeExpand(this.props.timesheetCode, fromEntry);
    };

    onNavigateEntry = (event: NavigationEvent, dayIndex?: number, day?: Day, level?: number): void => {
        this.props.onNavigateEntry(event, {
            tsCodeId: this.props.timesheetCode.id,
            tsCodeIndex: this.props.tsCodeIndex,
            dayIndex: dayIndex as number,
            day: day,
            level: level as number
        });
    };

    completeTimesheetCode = (): void => {
        this.props.completeTimesheetCode(this.props.timesheetCode.id);
    };

    incompleteTimesheetCode = (): void => {
        this.props.incompleteTimesheetCode(this.props.timesheetCode.id);
    };

    deleteTimesheetCode = (): void => {
        this.props.deleteTimesheetCode(this.props.timesheetCode.id);
    };

    render() {
        let visibleIndex = -1;
        return (
            <div className={'timesheet-row' + (this.props.timesheetCode.expanded ? '-expanded' : '')}>
                <TimesheetCode name={this.props.timesheetCode.name}
                               onTimesheetCodeExpand={this.onTimesheetCodeExpand}
                               expanded={this.props.timesheetCode.expanded}
                               description={this.props.timesheetCode.description}
                               isReadOnly={this.props.timesheetCode.isReadOnly}
                               isTsCodeActive={this.props.timesheetCode.isTsCodeActive}
                               activeRowLevel={this.props.activeEntry.level}/>
                {this.props.settings.monthViewSelected ? '' : <div className="timesheet-row-filler-left"/>}
                <div
                    className={'timesheet-code-days' + (this.props.settings.monthViewSelected ? ' month-timesheet-code-days-width' : ' timesheet-days-weekly week-timesheet-code-days-width')}>
                    {this.props.days.map((day, index) => {
                        const isWeekend = DateHelper.isWeekend(day);
                        if (!(isWeekend && this.props.settings.hideWeekends)) {
                            visibleIndex += 1;

                            const entry = TimesheetHelper.getEntry(this.props.timesheetCode, day);
                            const entryDate = new Date(day.dateString);
                            const entryMonth = entryDate.getMonth() + 1;
                            const entryYear = entryDate.getFullYear();
                            const timesheetMonth = this.props.period.endDate.getMonth() + 1;
                            const isEntryForCompletedMonth = TimesheetHelper.isTimesheetEntryForCompletedMonth(entryMonth, entryYear, timesheetMonth, this.props.incompleteMonths)
                                && !TimesheetHelper.isCurrentMonth(entryMonth, entryYear);

                            return (
                                <TimesheetCodeDay key={`${day.dateString}|${index}|${this.props.timesheetCode.name}`}
                                                  entry={entry}
                                                  day={day} isWeekend={isWeekend}
                                                  isReadOnly={this.props.entriesReadOnly || !this.props.timesheetCode.isTsCodeActive || !this.props.isAccessibleUser || isEntryForCompletedMonth}
                                                  isVacationOrIllness={this.props.timesheetCode.isVacationOrIllness}
                                                  isCompleted={TimesheetHelper.isTimesheetCodeCompleted(this.props.timesheetCode, DateHelper.getNormalizedDate(new Date(day.dateString))) || isEntryForCompletedMonth}
                                                  expanded={this.props.timesheetCode.expanded}
                                                  settings={this.props.settings}
                                                  onEntryChange={this.onEntryChange}
                                                  onEntryExpand={this.onTimesheetCodeExpand}

                                                  visibleIndex={visibleIndex}
                                                  dayIndex={index}
                                                  onNavigateEntry={this.onNavigateEntry}

                                                  activeRowLevel={this.props.activeEntry.level}
                                                  isActive={this.props.activeEntry.dayIndex === index}
                                                  setActiveEntryRequested={this.props.setActiveEntryRequested}

                                                  multiSelected={this.props.multiSelect.beginDayIndex <= index && this.props.multiSelect.endDayIndex >= index}
                                                  multiSelectEntryPoint={this.props.multiSelect.startDayIndex === index}
                                                  multiSelectLeftBorder={this.props.multiSelect.beginDayIndex === index}
                                                  multiSelectRightBorder={this.props.multiSelect.endDayIndex === index}

                                                  onMultiEditSubmit={this.props.onMultiEditSubmit}
                                                  onMultiEditCancel={this.props.onMultiEditCancel}
                                                  commentMandatory={this.props.timesheetCode.commentMandatory}
                                                  resetToDefaultEntry={this.props.resetToDefaultEntry}

                                                  isTsCodeActive={this.props.timesheetCode.isTsCodeActive}
                                                  tsCodeId={this.props.timesheetCode.id} />
                            );
                        }
                        else {
                            return (this.props.settings.monthViewSelected ?
                                <div key={day.dateString} className="weekend-collapsed"/> : null);
                        }
                    })}
                </div>

                {this.props.settings.monthViewSelected ? '' : <div className="timesheet-row-filler-right"/>}
                <TimesheetCodeTotal
                    entries={this.props.days.map(day => TimesheetHelper.getEntry(this.props.timesheetCode, day))}
                    completeTimesheetCode={this.completeTimesheetCode}
                    monthViewSelected={this.props.settings.monthViewSelected}
                    completed={TimesheetHelper.isTimesheetCodeCompleted(this.props.timesheetCode, this.props.period.startDate)}
                    canIncomplete={this.props.canIncomplete && this.props.isAccessibleUser}
                    canComplete={this.props.canComplete && this.props.isAccessibleUser}
                    incompleteTimesheetCode={this.incompleteTimesheetCode}
                    deleteTimesheetCode={this.deleteTimesheetCode}
                    timesheetsFromClient={this.props.timesheetsFromClient}
                    getTimesheetsFromClientInfo={this.props.getTimesheetsFromClientInfo}
                    period={this.props.period}
                    timesheetCode={this.props.timesheetCode}
                    canDeleteTimesheetCode={this.props.canDeleteTimesheetCode && this.props.isAccessibleUser}
                    canUploadClientTimesheet={this.props.canUploadClientTimesheet && this.props.isAccessibleUser} />
            </div>
        );
    }
}

type Props = {
    timesheetCode: StoreTimesheetCode,
    user: StoreUser,
    period: Period,
    days: Day[],
    settings: StoreSettings,
    timesheetsFromClient: StoreTimesheetFromClient[],
    incompleteMonths: StoreMonthYear[],

    onEntryChange: (entry: StoreTimesheetEntry, day: Day, timesheetCode: StoreTimesheetCode) => void,
    onTimesheetCodeExpand: (timesheetCode: StoreTimesheetCode, fromEntry?: boolean) => void,
    canIncomplete: boolean,
    canComplete: boolean,
    incompleteTimesheetCode: (tsCodeId: number) => void,
    completeTimesheetCode: (tsCodeId: number) => void,
    deleteTimesheetCode: (tsCodeId: number) => void,
    getTimesheetsFromClientInfo: (assignmentId: number) => void,

    tsCodeIndex: number,
    onNavigateEntry: (event: NavigationEvent, sourceEntry: EntryLocationInfo) => void,

    activeEntry: EntryLocationInfo,
    setActiveEntryRequested: boolean,
    multiSelect: MultiSelectInfo,

    onMultiEditSubmit: (multiEdit: MultiEdit) => void,
    onMultiEditCancel: () => void,
    resetToDefaultEntry: () => void,

    entriesReadOnly: boolean,
    canDeleteTimesheetCode: boolean,

    isAccessibleUser: boolean,

    canUploadClientTimesheet: boolean
};

export default TimesheetRow;
