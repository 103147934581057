import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {ActualsReportType, TimeLeftOnOrderType, TimesheetsLandscapeReportType, TimesheetsReportType, TimesheetsWeeklyReportType} from '../typescript/reportTypes';


export function exportActualsPdf(actualsReportType: ActualsReportType): Action {
    return {type: ActionTypes.EXPORT_ACTUALS_PDF_REQUESTED, payload: actualsReportType};
}

export function exportActualsCsv(actualsReportType: ActualsReportType): Action {
    return {type: ActionTypes.EXPORT_ACTUALS_CSV_REQUESTED, payload: actualsReportType};
}

export function exportActualsExcel(actualsReportType: ActualsReportType): Action {
    return {type: ActionTypes.EXPORT_ACTUALS_EXCEL_REQUESTED, payload: actualsReportType};
}

export function exportTimesheetsPdf(timesheetsReportType: TimesheetsReportType): Action {
    return {type: ActionTypes.EXPORT_TIMESHEETS_PDF_REQUESTED, payload: timesheetsReportType};
}

export function exportTimesheetsLandscapePdf(timesheetsLandscapeReportType: TimesheetsLandscapeReportType): Action {
    return {type: ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_PDF_REQUESTED, payload: timesheetsLandscapeReportType};
}

export function exportTimesheetsLandscapeExcel(timesheetsLandscapeReportType: TimesheetsLandscapeReportType): Action {
    return {type: ActionTypes.EXPORT_TIMESHEETS_LANDSCAPE_EXCEL_REQUESTED, payload: timesheetsLandscapeReportType};
}

export function exportTimesheetsWeeklyPdf(timesheetsWeeklyReportType: TimesheetsWeeklyReportType): Action {
    return {type: ActionTypes.EXPORT_TIMESHEETS_WEEKLY_PDF_REQUESTED, payload: timesheetsWeeklyReportType};
}

export function exportTimesheetsWeeklyExcel(timesheetsWeeklyReportType: TimesheetsWeeklyReportType): Action {
    return {type: ActionTypes.EXPORT_TIMESHEETS_WEEKLY_EXCEL_REQUESTED, payload: timesheetsWeeklyReportType};
}

export function getTimeLeftOnOrder(timeLeftOnOrderType: TimeLeftOnOrderType): Action {
    return {type: ActionTypes.POST_GET_TIME_LEFT_ON_ORDER_REQUESTED, payload: timeLeftOnOrderType};
}

export function clearTimeLeftOnOrder(): Action {
    return {type: ActionTypes.CLEAR_TIME_LEFT_ON_ORDER_REQUESTED, payload: null};
}
