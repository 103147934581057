export const LocationTypes = {
    HOME: '/',
    REPORTING_WILDCARD: '/reporting/*',
    REPORTING: '/reporting',
    REPORTING_ACTUALS: 'actuals',
    REPORTING_ACTUALS_FULL: '/reporting/actuals',
    REPORTING_TIMESHEETS: 'timesheets',
    REPORTING_TIMESHEETS_FULL: '/reporting/timesheets',
    REPORTING_WEEKLY_TIMESHEETS: 'timesheetsweekly',
    REPORTING_WEEKLY_TIMESHEETS_FULL: '/reporting/timesheetsweekly',
    REPORTING_LANDSCAPE_TIMESHEETS: 'timesheetslandscape',
    REPORTING_LANDSCAPE_TIMESHEETS_FULL: '/reporting/timesheetslandscape',
    REPORTING_TIME_LEFT_ON_ORDER: 'timeleftonorder',
    REPORTING_TIME_LEFT_ON_ORDER_FULL: '/reporting/timeleftonorder',
    REPORTING_PROJECT_TIMESHEET_REPORT: 'projecttimesheet',
    REPORTING_PROJECT_TIMESHEET_REPORT_FULL: '/reporting/projecttimesheet',
    IMPORT: '/import',
    IMPORT_ACTUALS: '/import/actuals',
    RELEASE_NOTES: '/releasenotes',
    SETTINGS: '/settings',
    ADFS_LOGIN: '/adfs/login',
    STS: process.env.STS_WEBSITE_ENDPOINT,
    STS_MOBILE: process.env.STS_MOBILE_ENDPOINT,
    INVOICE_VIEWER: '/invoiceviewer',
    ACCESSRIGHTS: process.env.ACCESSRIGHTS_REDIRECT_URI
};

export const ValidRedirects = {
    ForSTS: [
        LocationTypes.HOME,
        LocationTypes.REPORTING,
        LocationTypes.REPORTING_ACTUALS_FULL,
        LocationTypes.REPORTING_TIMESHEETS_FULL,
        LocationTypes.REPORTING_WEEKLY_TIMESHEETS_FULL,
        LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS_FULL,
        LocationTypes.REPORTING_TIME_LEFT_ON_ORDER_FULL,
        LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT_FULL,
        LocationTypes.IMPORT_ACTUALS,
        LocationTypes.RELEASE_NOTES,
        LocationTypes.INVOICE_VIEWER],
    ForWebsite: [
        LocationTypes.HOME,
        LocationTypes.REPORTING,
        LocationTypes.REPORTING_ACTUALS_FULL,
        LocationTypes.REPORTING_TIMESHEETS_FULL,
        LocationTypes.REPORTING_WEEKLY_TIMESHEETS_FULL,
        LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS_FULL,
        LocationTypes.REPORTING_TIME_LEFT_ON_ORDER_FULL,
        LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT_FULL,
        LocationTypes.IMPORT,
        LocationTypes.IMPORT_ACTUALS,
        LocationTypes.SETTINGS,
        LocationTypes.ADFS_LOGIN,
        LocationTypes.RELEASE_NOTES,
        LocationTypes.INVOICE_VIEWER
    ]
};
