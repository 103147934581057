import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import { Filter } from '../typescript/sentInvoiceTypes';

export function searchInvoice(invoiceNumber: string, download: boolean): Action {
    return {type: ActionTypes.SEARCH_INVOICE_REQUESTED, payload: { invoiceNumber: invoiceNumber, download: download}};
}

export function clearInvoice(): Action {
    return {type: ActionTypes.CLEAR_INVOICE_REQUESTED, payload: null};
}


export function clearSentInvoices(): Action {
    return {type: ActionTypes.CLEAR_SENT_INVOICES_REQUESTED, payload: null};
}

export function getSentInvoices(icPartnerId: number, page = 1, invoiceDateRangeStart: string, invoiceDateRangeEnd: string, filter: Filter, sortBy: string, sortOrder: string): Action {
    return {type: ActionTypes.GET_SENT_INVOICES_REQUESTED, payload: {
                                icPartnerId: icPartnerId,
                                page: page,
                                invoiceDateRangeStart: invoiceDateRangeStart,
                                invoiceDateRangeEnd: invoiceDateRangeEnd,
                                supplier: filter.supplier,
                                invoicenumber: filter.invoicenumber,
                                customer: filter.customer,
                                amount: filter.amount,
                                paid: filter.paid,
                                sortBy: sortBy,
                                sortOrder: sortOrder
                            }
            };
}

export function searchInvoiceNumbers(invoiceNumber: string, page = 1): Action {
    return {type: ActionTypes.SEARCH_INVOICENUMBERS_REQUESTED, payload: {invoiceNumber: invoiceNumber, page: page}};
}

export function clearInvoiceNumbers(): Action {
    return {type: ActionTypes.CLEAR_INVOICE_NUMBERS_REQUESTED, payload: undefined};
}

export function getInvoice(invoiceId: number, invoiceNumber: string): Action {
    return {type: ActionTypes.GET_INVOICE_REQUESTED, payload: { invoiceId: invoiceId, invoiceNumber: invoiceNumber}};
}
