import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import SearchTimesheetCode from './SearchTimesheetCode';

import {StoreSettings, StoreTimesheet} from '../../../../typescript/storeTypes';

class AddTimesheetCodeRow extends React.PureComponent<Props> {
    render() {
        return (
            <div className="timesheet-row">
                <SearchTimesheetCode
                    period={this.props.timesheet.period}
                    currentTimesheetCodes={this.props.timesheet.timesheetCodes}/>
                <div className={'timesheet-code-days' + (this.props.settings.monthViewSelected ? ' month-timesheet-code-days-width' : ' timesheet-days-weekly week-timesheet-code-days-width')}>
                    {this.props.timesheet.period.days.map(day => {
                        const isWeekend = DateHelper.isWeekend(day);
                        if (!(isWeekend && this.props.settings.hideWeekends))
                            return (<div key={day.dateString} className="day-hours-container">
                                <div className={'day-hours' + (isWeekend ? ' weekend' : '')}/>
                            </div>);
                        else
                            return (<div key={day.dateString} className="weekend-collapsed"/>);
                    })}
                </div>
                <div className="timesheet-code-total-container timesheet-code-total-container--width">
                    <div className="timesheet-code-total"/>
                </div>
            </div>
        );
    }
}

type Props = {
    timesheet: StoreTimesheet,
    settings: StoreSettings
};

export default AddTimesheetCodeRow;
