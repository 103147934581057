import DigitalSigning from './DigitalSigning/DigitalSigning';
import Disclaimer from './Disclaimer';
import ExportMenu from './ExportMenu';
import Legend from './Legend';
import MaintenanceModeDisclaimer from './MaintenanceModeDisclaimer';
import ManageClientTimesheet from './ManageClientTimesheet';
import React from 'react';
import TimesheetCodeColumnPlaceHolder from './TimesheetCodeColumnPlaceHolder';
import TimesheetComplete from './TimesheetComplete';

import Version from './Version';


import {StoreMaintenanceDetails, StoreSettings, StoreTimesheet, StoreUser} from '../../../../typescript/storeTypes';
import AccessibleUserHelper from '../../../../helpers/accessibleUserHelper';


class TimesheetFooter extends React.PureComponent<Props> {
    hasBillableTimesheetCodes = (): boolean => {
        return this.props.timesheet.timesheetCodes.filter(t => t.isBillable).length > 0;
    };

    render() {
        return (
            <div className="timesheet-footer">
                <TimesheetCodeColumnPlaceHolder/>
                <div className="timesheet-footer-container">
                    {this.props.settings.monthViewSelected ?
                        <React.Fragment>
                            <div className="timesheet-footer-container--separator"/>
                            <TimesheetComplete timesheet={this.props.timesheet}
                                completeTimesheet={this.props.completeTimesheet}
                                incompleteTimesheet={this.props.incompleteTimesheet}
                                isComplete={this.props.isComplete}
                                canUncomplete={this.props.canUncomplete}
                                canComplete={this.props.canComplete}
                                completionCalled={this.props.completionCalled}/>
                            <div className="timesheet-footer-container--separator"/>
                            <ExportMenu
                                timesheet={this.props.timesheet}
                                settings={this.props.settings} />
                            <div className="timesheet-footer-container--separator"/>
                            <ManageClientTimesheet canUpload={AccessibleUserHelper.isAccessibleUser(this.props.user) && this.hasBillableTimesheetCodes()} />
                            <div className="timesheet-footer-container--separator"/>
                            <DigitalSigning timesheet={this.props.timesheet} isMonthView={this.props.settings.monthViewSelected} />
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <div className="timesheet-footer-container--separator"/>
                            <ExportMenu
                                timesheet={this.props.timesheet}
                                settings={this.props.settings} />
                            <div className="timesheet-footer-container--separator"/>
                            <DigitalSigning timesheet={this.props.timesheet} isMonthView={this.props.settings.monthViewSelected} />
                        </React.Fragment>
                    }
                    <Legend />
                </div>
                <Disclaimer/>
                {!this.props.maintenanceDetails.isInMaintenanceMode ? null :
                    <MaintenanceModeDisclaimer />
                }
                <Version/>
            </div>
        );
    }
}

type Props = {
    completeTimesheet: () => void,
    incompleteTimesheet: () => void,
    timesheet: StoreTimesheet,
    settings: StoreSettings,
    isComplete: boolean,
    canUncomplete: boolean,
    canComplete: boolean,
    completionCalled: boolean,
    user: StoreUser,
    maintenanceDetails: StoreMaintenanceDetails
};

export default TimesheetFooter;
