import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import { WithRouterProps, withRouter } from '../../../../common/WithRouter';
import { getIncompleteTimesheets, getTimesheet } from '../../../../actions/timesheetActions';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';

import { LocationTypes } from '../../../../types/locationTypes';
import {StoreState} from '../../../../typescript/storeTypes';
import SiteLocation from '../../content/location/SiteLocation';

export class Identity extends React.Component<Props> {
    onClick = (): void => {
        const today = new Date();
        const startDate = this.props.settings.monthViewSelected ? DateHelper.firstDateOfMonth(today) : DateHelper.getMonday(today);
        const lastDate = this.props.settings.monthViewSelected ? DateHelper.lastDateOfMonth(today) : DateHelper.addDays(DateHelper.getMonday(today), 6);

        this.props.getTimesheet(DateHelper.getPeriod(startDate, lastDate), this.props.impersonatedUser);
        this.props.getIncompleteTimesheets();

        this.props.navigate(LocationTypes.HOME);
    };

    render() {
        return (
            <div className="identity">
                <div className="home" onClick={this.onClick}>
                    <img className="logo" src="/images/cronos-logo.png"/>
                    <span className="title">Timesheet App</span>
                </div>
                <SiteLocation/>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState, props: WithRouterProps) {
    return {
        settings: state.settings,
        impersonatedUser: state.user.impersonatedUser,
        navigate: props.navigate // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getTimesheet,
        getIncompleteTimesheets
    }, dispatch);
}

export default withRouter(connector(Identity));
