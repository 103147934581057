import { StoreSettings, StoreTimesheet } from '../typescript/storeTypes';
import { Week } from '../typescript/dateTypes';

import DateHelper from './dateHelper';

class WeekHelper { 
    static getWeekNumber = (timesheet: StoreTimesheet, settings: StoreSettings): number|undefined => !settings.monthViewSelected 
        ? DateHelper.getWeekNumber(DateHelper.getNormalizedDate(new Date(timesheet.period.days[0].dateString))) 
        : undefined;   
        
    static hasWeekNumber = (timesheet: StoreTimesheet, settings: StoreSettings): boolean => !!WeekHelper.getWeekNumber(timesheet, settings); 

    static getSelectedWeek = (timesheet: StoreTimesheet, settings: StoreSettings): Week|undefined => WeekHelper.hasWeekNumber(timesheet, settings) 
        ? timesheet.period.weeks.filter(week => week.weekNumber === WeekHelper.getWeekNumber(timesheet, settings))[0] 
        : undefined;
}

export default WeekHelper;
