import Autosuggest, * as AutoSuggest from 'react-autosuggest';

import { Action } from '../../../../../typescript/actionTypes';
import { ApprovalTypes } from '../../../../../types/enumTypes';
import { PropertyNameIndexer } from '../../../../../typescript/customTypes';
import { SigningHubObject } from '../../../../../typescript/signingHubTypes';
import { StoreSettings, StoreSupplier, StoreTimesheetCode, StoreUser, StoreVisibleSupplier } from '../../../../../typescript/storeTypes';

import DigitalSigningHelper from '../../../../../helpers/digitalSigningHelper';
import React from 'react';
import UserHelper from '../../../../../helpers/userHelper';

class DigitalSigningWizardScreen2 extends React.PureComponent<Props, State> {
    errorsShown = (): boolean => Object.keys(this.props.errors).length > 0;

    state: State = {
        selectedSupplierValue: UserHelper.getDefaultCompanyNameForTemplate(this.props.user, this.props.settings),
        selectedSupplier: UserHelper.getDefaultCompanyForTemplate(this.props.user, this.props.settings),
        supplierFilterValue: ''
    };

    onChange = (event: React.FormEvent<HTMLElement>, {newValue}: { newValue: StoreVisibleSupplier | string }) => {
        if (!(newValue instanceof Object)) {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({
                selectedSupplierValue: target.value,
                selectedSupplier: null
            });
        }
        else {
            if(!UserHelper.isImpersonating(this.props.user)) {
                this.props.updateSetting('defaultCompanyForTemplate', {id: newValue.id, name: newValue.name});
            }
            this.setState({selectedSupplierValue: newValue.name, selectedSupplier: newValue});

            this.props.updateSigningHubObject(Object.assign({}, this.props.signingHubObject, {companyId: newValue.id}), this.errorsShown());
        }
    };

    onBlur = (supplier: StoreSupplier) => {
        if(this.state.selectedSupplierValue === '') {
            this.setState({selectedSupplier: null});
            return;
        }

        if(this.state.selectedSupplier === null) {
            if (supplier === null) {
                this.setState({selectedSupplierValue: ''});
                return;
            }

            if (!UserHelper.isImpersonating(this.props.user)) {
                this.props.updateSetting('defaultCompanyForTemplate', {id: supplier.id, name: supplier.name});
            }

            const newSupplier = {
                id: supplier.id,
                name: supplier.name,
                isWritable: false
            };
            this.setState({
                selectedSupplier: newSupplier,
                selectedSupplierValue: supplier.name
            });

            this.props.updateSigningHubObject(Object.assign({}, this.props.signingHubObject, {companyId: supplier.id}), false);
        }
    };

    onSuggestionsFetchRequested = ({value}: { value: string }) => {
        this.setState({supplierFilterValue: value});
    };

    getFilteredSuggestions = (): StoreSupplier[] => {
        return this.props.suppliers.slice().filter(s => s.name.toLocaleLowerCase().includes(this.state.supplierFilterValue.toLocaleLowerCase()));
    };

    getSuggestionValue = (supplier: StoreSupplier): any => supplier;
    renderSuggestion = (supplier: StoreSupplier) => <div>{supplier.name}</div>;

    customerApprovalClicked = (): void => {
        const customerApprovalType = this.props.signingHubObject.customerApprovalType === ApprovalTypes.None ? ApprovalTypes.One : ApprovalTypes.None;

        const updatedSigningHubObject = {...this.props.signingHubObject, customerApprovalType};
        this.props.updateSigningHubObject(updatedSigningHubObject, this.errorsShown());
    };

    internalApprovalClicked = (): void => {
        const internalApprovalType = this.props.signingHubObject.internalApprovalType === ApprovalTypes.None ? ApprovalTypes.One : ApprovalTypes.None;

        const updatedSigningHubObject = {...this.props.signingHubObject, internalApprovalType};
        this.props.updateSigningHubObject(updatedSigningHubObject, this.errorsShown());
    };

    consultantApprovalClicked = (): void => this.props.updateSigningHubObject({
        ...this.props.signingHubObject,
        consultantApprovalNeeded: !this.props.signingHubObject.consultantApprovalNeeded
    }, this.errorsShown());

    //calculate initial approvaltype
    componentDidMount() {
        if(!this.props.signingHubObject.internalApprovalType || !this.props.signingHubObject.customerApprovalType) {
            const copy = Object.assign({}, this.props.signingHubObject);

            if(!this.props.signingHubObject.customerApprovalType) {
                const approvalTypes = [...new Set(this.props.timesheetCodes.map(e => e.customerApprovalType))]; //Set removes doubles.
                copy.customerApprovalType = approvalTypes.length === 1 ? approvalTypes[0] : ApprovalTypes.One;
            }

            if(!this.props.signingHubObject.internalApprovalType) {
                const approvalTypes = [...new Set(this.props.timesheetCodes.map(e => e.internalApprovalType))]; //Set removes doubles.
                copy.internalApprovalType = approvalTypes.length === 1 ? approvalTypes[0] : ApprovalTypes.One;
            }

            this.props.updateSigningHubObject(copy, false);
        }
    }

    render() {
        const inputProps: AutoSuggest.InputProps<StoreSupplier> = {
            placeholder: 'required',
            value: this.state.selectedSupplierValue,
            onChange: (event: React.FormEvent<HTMLElement>, newValue: { newValue: any }) => this.onChange(event, newValue),
            onBlur: (_: React.FocusEvent<HTMLElement>, suggestion : AutoSuggest.BlurEvent<StoreSupplier>|undefined) => this.onBlur(suggestion?.highlightedSuggestion as StoreSupplier)
        };

        return (
            <div className="wizard-screen-2">
                <div className="form-group">
                    <div className="label-with-error-tooltip">
                        <label data-required={this.props.errors['selectedSupplier']} className="digital-signing-modal__required">Company background image:</label>
                        <div data-required={this.props.errors['selectedSupplier']} className="error-tooltip"><i className="fas fa-info-circle" /></div>
                    </div>
                    <Autosuggest suggestions={this.getFilteredSuggestions()}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={() => undefined}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                        highlightFirstSuggestion />
                </div>
                <div className="form-group">
                    <div className="digital-signing-checkbox extra-top-margin" onClick={this.consultantApprovalClicked}>
                        <input type="checkbox" checked={this.props.signingHubObject.consultantApprovalNeeded} readOnly/>
                        <label>Consultant signature required</label>
                    </div>
                    <div className="digital-signing-checkbox" onClick={this.internalApprovalClicked}>
                        <input type="checkbox" checked={DigitalSigningHelper.approvalNeeded(this.props.signingHubObject.internalApprovalType)} readOnly/>
                        <label>Internal signature required</label>
                    </div>
                    <div className="digital-signing-checkbox" onClick={this.customerApprovalClicked}>
                        <input type="checkbox" checked={DigitalSigningHelper.approvalNeeded(this.props.signingHubObject.customerApprovalType)}  readOnly/>
                        <div className="label-with-error-tooltip">
                            <label data-required={this.props.errors['signatureCheckboxes']}>Customer signature required</label>
                            <div data-required={this.props.errors['signatureCheckboxes']} className="error-tooltip"><i className="fas fa-info-circle" /></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    settings: StoreSettings,
    errors: PropertyNameIndexer,
    suppliers: StoreSupplier[],
    timesheetCodes: StoreTimesheetCode[],
    signingHubObject: SigningHubObject,
    updateSigningHubObject: (signingHubObject: SigningHubObject, revalidate: boolean) => void,
    updateSetting: (name: string, value: any) => Action
};

type State = {
    selectedSupplierValue: string,
    selectedSupplier: StoreVisibleSupplier | null,
    supplierFilterValue: string
};

export default DigitalSigningWizardScreen2;
