import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import { Period } from '../typescript/dateTypes';
import { SigningHubObject } from '../typescript/signingHubTypes';
import { StoreEmployee } from '../typescript/storeTypes';

export function sendMonthlyToSigningHub(monthlyTimesheetToSigningHub: SigningHubObject, period: Period, employee: StoreEmployee): Action {
    return {type: ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_REQUESTED, payload: {
        monthlyTimesheetToSigningHub,
        employee,
        period
    }};
}

export function sendMonthlyToSigningHubImpersonated(monthlyTimesheetToSigningHub: SigningHubObject, period: Period, employee: StoreEmployee): Action {
    const payload = Object.assign({}, {
        monthlyTimesheetToSigningHub,
        employee,
        period
    });

    return {type: ActionTypes.SEND_MONTHLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED, payload: payload};
}

export function sendWeeklyToSigningHub(weeklyTimesheetToSigningHub: SigningHubObject, period: Period, employee: StoreEmployee): Action {
    return {type: ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_REQUESTED, payload: {
        weeklyTimesheetToSigningHub,
        employee,
        period
    }};
}

export function sendWeeklyToSigningHubImpersonated(weeklyTimesheetToSigningHub: SigningHubObject, period: Period, employee: StoreEmployee): Action {
    const payload = Object.assign({}, {
        weeklyTimesheetToSigningHub,
        employee,
        period
    });

    return {type: ActionTypes.SEND_WEEKLY_TO_SIGNING_HUB_IMPERSONATED_REQUESTED, payload: payload};
}

export function cancelSigningHubQueue(signingHubQueueId: number): Action {
    return {type: ActionTypes.CANCEL_SIGNING_HUB_QUEUE_REQUESTED, payload: signingHubQueueId};
}
