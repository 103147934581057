import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import { deleteAssignmentDocument, getAssignmentDocument } from '../../../../actions/documentActions';
import { getAssignedTimesheetCodes, getTimesheetsFromClientInfo } from '../../../../actions/assignmentActions';
import { toast } from 'react-toastify';
import {uploadClientTimesheet} from '../../../../actions/timesheetActions';
import ClosableModal from '../../../../common/ClosableModal';
import DateHelper from '../../../../helpers/dateHelper';
import DefaultSubmit from '../../../../common/DefaultSubmit';
import MagicDropzone from 'react-magic-dropzone';
import MonthYearPicker from '../../common/MonthYearPicker';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';

import {StoreAssignmentModel, StoreState} from '../../../../typescript/storeTypes';
import {UploadClientTimesheet} from '../../../../typescript/uploadTypes';

export class ManageClientTimesheetModal extends React.Component<Props & MappedProps, State> {
    state: State =  {
        isPeriodReadOnly: false,
        month: this.props.period.month,
        year: this.props.period.year,
        selectedAssignedTimesheetCode: null,
        fileBase64: '',
        documentName: ''
    };

    handleAMonthChange = (year: number, month: number) => {
        this.setState({year: year, month: month, selectedAssignedTimesheetCode: null});
    };

    isTsCodeChecked = (assignedTimesheetCode: StoreAssignmentModel): boolean => {
        if(this.state.selectedAssignedTimesheetCode === null) {
            return false;
        }

        return !!this.state.selectedAssignedTimesheetCode && this.state.selectedAssignedTimesheetCode.id === assignedTimesheetCode.id;
    };

    onTimesheetCodeClick = (assignedTsCode: StoreAssignmentModel): void => {
        this.setState({selectedAssignedTimesheetCode: assignedTsCode});
    };

    getFilteredAssignedTimesheetCodes = (): StoreAssignmentModel[] => {
        const list: StoreAssignmentModel[] = [];
        for (const assignedTimesheetCode of this.props.assignedTimesheetCodes) {
            if(list.some(at => at.id === assignedTimesheetCode.id)) continue;
            list.push(assignedTimesheetCode);
        }

        return list
            .filter(at => at.tsCode.isBillable && (at.hasEntries || (this.props.timesheetsFromClient[at.id] !== undefined ? this.props.timesheetsFromClient[at.id] : []).length > 0))
            .sort((at1, at2) => ((at1.tsCode.name < at2.tsCode.name) ? -1 : ((at1.tsCode.name > at2.tsCode.name) ? 1 : 0)));
    };

    onDrop = (accepted: any) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if(e.target === null || e.target.result === null || (e.target.result as string).length === 0) {
                this.setState({fileBase64: '', documentName: ''});
                return;
            }
            if(accepted[0].size > 5000000) {
                this.setState({fileBase64:'', documentName: ''});
                toast.info('The selected file should not be greater than 5MB');
                return;
            }
            const base64 = (e.target.result as string).substr((e.target.result as string).indexOf(',') + 1);
            this.setState({fileBase64: base64, documentName: accepted[0].name});
        };
        reader.readAsDataURL(accepted[0].slice());
    };

    isValid = (): boolean => {
        return this.state.selectedAssignedTimesheetCode !== null
            && !!this.state.fileBase64 && this.state.fileBase64.length > 0;
    };

    onUploadClientTimesheet = (): void => {
        if (!this.state.selectedAssignedTimesheetCode) return;

        const clientTimesheet: UploadClientTimesheet = {
            username: this.props.user.impersonatedUser.username,
            assignmentId: this.state.selectedAssignedTimesheetCode.id,
            fileBase64: this.state.fileBase64,
            documentName: this.state.documentName
        };

        this.props.uploadClientTimesheet(clientTimesheet, this.props.user);
        if(this.state.selectedAssignedTimesheetCode && this.state.selectedAssignedTimesheetCode !== null) {
            this.props.getTimesheetsFromClientInfo(this.state.selectedAssignedTimesheetCode.id);
        }

        this.props.closeModal();
    };

    downloadAssignmentDocument = (assignmentId: number, filename: string): void => {
        this.props.getAssignmentDocument(assignmentId, filename);
    };

    deleteAssignmentDocument = (assignmentId: number, assignmentDocumentId: number): void => {
        this.props.deleteAssignmentDocument(assignmentId, assignmentDocumentId);
    };

    getTimesheetsFromClient = (): React.ReactElement[] | React.ReactElement => {
        if(this.state.selectedAssignedTimesheetCode) {
            const documents = this.props.timesheetsFromClient[this.state.selectedAssignedTimesheetCode.id];
            if(documents) {
                return documents.map(document =>
                    (<div key={document.assignmentDocumentId} className="manage-client-timesheet-modal__documentlist__item">
                        <div onClick={() => this.downloadAssignmentDocument(document.assignmentDocumentId, document.documentName)} key={document.assignmentDocumentId + '_item'} className="manage-client-timesheet-modal__documentlist__item__name">{document.documentName}</div>
                        <div key={document.assignmentDocumentId+'_date'} className="manage-client-timesheet-modal__documentlist__item__date">{DateHelper.getFormattedDateWithTime(document.uploadedOn, '-')}</div>
                        <div key={document.assignmentDocumentId+'_delete'} className="trashbin" onClick={() => this.deleteAssignmentDocument(document.assignmentId, document.assignmentDocumentId)}/>
                    </div>));
            }

        }
        return (<div className="manage-client-timesheet-modal__documentlist__nocontent">No timesheets from client found.</div>);
    };


    componentDidMount() {
        this.props.getAssignedTimesheetCodes(this.props.user.impersonatedUser.username, this.state.month, this.state.year);
    }

    static getDerivedStateFromProps(nextProps: Props & MappedProps) {
        let newState = {isPeriodReadOnly: nextProps.timesheetCodeId !== undefined};

        if(nextProps.timesheetCodeId !== undefined && nextProps.assignedTimesheetCodes.length > 0) {
            newState = Object.assign(newState, {selectedAssignedTimesheetCode: nextProps.assignedTimesheetCodes.filter(at => at.tsCode.id === nextProps.timesheetCodeId)[0]});
        }

        return newState;
    }

    componentDidUpdate(prevProps: Props & MappedProps, prevState: State): void {
        if(this.state.month === prevState.month && this.state.year === prevState.year) return;

        this.props.getAssignedTimesheetCodes(this.props.user.impersonatedUser.username, this.state.month, this.state.year);
    }

    render() {
        const filtered = this.getFilteredAssignedTimesheetCodes();

        return (
            <ClosableModal
                title={'MANAGE TIMESHEETS FROM CLIENT'}
                closeModal={this.props.closeModal}
                marginBottom={19}>
                <div className="manage-client-timesheet-modal">
                    <div className="manage-client-timesheet-modal__picker  month-picker-box-container">
                        <label className="manage-client-timesheet-modal__picker__label">Period:</label>
                        <div className="manage-client-timesheet-modal__picker__monthpicker">
                            <MonthYearPicker
                                minDate={DateHelper.addMonths(new Date(), -12)}
                                maxDate={new Date()}
                                isReadOnly={this.state.isPeriodReadOnly}
                                onChange={(month: number, year: number) => this.handleAMonthChange(year, month)}
                            />
                        </div>
                    </div>
                    <div className="manage-client-timesheet-modal__tscodelist">
                        <div className="manage-client-timesheet-modal__tscodelist__header">
                            Select timesheet code:
                        </div>
                        <ScrollBar>
                            {filtered.length > 0 ?
                                filtered.map(assignedTimesheetCode =>
                                    <div key={`assignedtscode_${assignedTimesheetCode.id}`} className="manage-client-timesheet-modal__tscodelist__item">
                                        <div className="manage-client-timesheet-modal__tscodelist__item__checkbox">
                                            <input id={`assignedtscode-chk-${assignedTimesheetCode.id}`}
                                                type="checkbox"
                                                checked={this.isTsCodeChecked(assignedTimesheetCode)}
                                                onChange={() => this.onTimesheetCodeClick(assignedTimesheetCode)}/>
                                        </div>
                                        <div className="manage-client-timesheet-modal__tscodelist__item__name"
                                            onClick={() => this.onTimesheetCodeClick(assignedTimesheetCode)}>
                                            {assignedTimesheetCode.tsCode.name}
                                        </div>
                                </div>) :
                                <div className="manage-client-timesheet-modal__tscodelist__nocontent">
                                    No billable assignments with booked hours found.
                                </div>
                            }
                        </ScrollBar>
                    </div>
                    {this.state.selectedAssignedTimesheetCode !== null ?
                     <React.Fragment>
                        <div className="manage-client-timesheet-modal__documentlist">
                            <div className="manage-client-timesheet-modal__documentlist__header">
                                Currently uploaded timesheets from client:
                            </div>
                            <ScrollBar>
                                {this.getTimesheetsFromClient()}
                            </ScrollBar>
                        </div>
                        <div className="manage-client-timesheet-modal__file-upload">
                        {this.state.documentName === '' ?
                            <MagicDropzone accept=".pdf" onDrop={this.onDrop}>
                                <div className="manage-client-timesheet-modal__file-upload__dropzone-content">
                                    <div className="manage-client-timesheet-modal__file-upload__dropzone-content__container">
                                        <div className="manage-client-timesheet-modal__file-upload__dropzone-content__container__upload-logo">
                                            <i className="fas fa-file-contract" aria-hidden="true"/>
                                        </div>
                                        <div className="manage-client-timesheet-modal__file-upload__dropzone-content__container__text">
                                            Browse for the document (.pdf) or drag it here.
                                        </div>
                                    </div>
                                </div>
                            </MagicDropzone> :
                            <div className="manage-client-timesheet-modal__file-upload__complete">
                                <i className="fas fa-check"/>
                                <div>{this.state.documentName}</div>
                            </div>
                        }
                        </div>
                    </React.Fragment> : null}
                    <div className="manage-client-timesheet-modal__submit">
                        <DefaultSubmit className="manage-client-timesheet-modal__submit__button"
                            onSubmit={this.onUploadClientTimesheet}
                            value="Upload"
                            disabled={!this.isValid()} />
                    </div>
                </div>
            </ClosableModal>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    timesheetCodeId?: number,

    closeModal: () => void
};

type State = {
    isPeriodReadOnly: boolean,
    year: number,
    month: number,
    selectedAssignedTimesheetCode: StoreAssignmentModel | null,
    fileBase64: string,
    documentName: string
};

function mapStateToProps(state: StoreState, props: Props) {
    return {
        assignedTimesheetCodes: state.assignedTimesheetCodes,
        period: state.timesheet.period,
        user: state.user,
        timesheetsFromClient: state.timesheetsFromClient,

        closeModal: props.closeModal
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({getAssignedTimesheetCodes, getTimesheetsFromClientInfo, uploadClientTimesheet, getAssignmentDocument, deleteAssignmentDocument}, dispatch);
}

export default connector(ManageClientTimesheetModal);
