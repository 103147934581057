import {AnyAction, Dispatch, bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {clearSuppliers, getSuppliers} from '../../../../actions/supplierActions';
import {exportMonthlyTimesheet, exportMonthlyTimesheetExcel} from '../../../../actions/timesheetActions';
import {updateSetting} from '../../../../actions/settingsActions';

import ApiHelper from '../../../../helpers/apiHelper';
import ClosableModal from '../../../../common/ClosableModal';
import ContractHoursToggle from '../../../../common/ContractHoursToggle';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import TextInput from '../../../../common/TextInput';
import UserHelper from '../../../../helpers/userHelper';

import {MonthlyTimesheetToExport} from '../../../../typescript/timesheetTypes';
import {Period} from '../../../../typescript/dateTypes';
import { PropertyNameIndexer } from '../../../../typescript/customTypes';
import {StoreState, StoreSupplier, StoreTimesheetCode, StoreVisibleSupplier } from '../../../../typescript/storeTypes';

import Autosuggest, * as AutoSuggest from 'react-autosuggest';

export class ExportMonthlyTimesheetModal extends React.Component<Props & MappedProps, State> {
    isReadOnly = (): boolean => this.props.timesheetCodes.length === 1;

    state: State = {
        ownProjectLeader: '',
        clientProjectLeader: '',
        selectedSupplierValue: UserHelper.getDefaultCompanyNameForTemplate(this.props.user, this.props.settings),
        selectedSupplier: UserHelper.getDefaultCompanyForTemplate(this.props.user, this.props.settings),
        selectedTimesheetCodes: this.isReadOnly() ? [this.props.timesheetCodes[0]] : [],
        selectAllChecked: this.isReadOnly() ? true : false,
        downloadOpen: false,
        hideComments: false,
        errors: {},
        supplierFilterValue: ''
    };

    pdfButton: HTMLElement | undefined;
    excelButton: HTMLElement | undefined;
    downloadButton: HTMLElement | undefined;
    downloadDiv: HTMLElement | undefined;
    downloadCaret: HTMLElement | undefined;

    getSuppliers: (value: string) => void = ApiHelper.debounce(this.props.getSuppliers, 500);
    onChange = (event: React.FormEvent<HTMLElement>, {newValue}: { newValue: StoreVisibleSupplier | string }) => {
        if (!(newValue instanceof Object)) {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({
                selectedSupplierValue: target.value,
                selectedSupplier: null
            });
        }
        else {
            if(!UserHelper.isImpersonating(this.props.user)) {
                this.props.updateSetting('defaultCompanyForTemplate', {id: newValue.id, name: newValue.name});
            }
            this.setState({selectedSupplierValue: newValue.name, selectedSupplier: newValue});
        }
    };

    onTimesheetCodeClick = (timesheetCode: StoreTimesheetCode): void => {
        if (this.isReadOnly()) {
            return;
        }

        const selectedTimesheetCodes = this.state.selectedTimesheetCodes.slice();

        if (selectedTimesheetCodes.find(tsCode => tsCode.id === timesheetCode.id)) {
            const index = selectedTimesheetCodes.indexOf(timesheetCode);
            selectedTimesheetCodes.splice(index, 1);
        } else {
            selectedTimesheetCodes.push(timesheetCode);
        }

        this.setState({
            selectedTimesheetCodes: selectedTimesheetCodes,
            selectAllChecked: selectedTimesheetCodes.length === this.getFilteredTimesheetCodes().length
        });
    };

    onSelectAll = (): void => {
        if (this.isReadOnly()) {
            return;
        }

        this.setState({selectAllChecked: !this.state.selectAllChecked, selectedTimesheetCodes: !this.state.selectAllChecked ? this.getFilteredTimesheetCodes() : []});
    };

    validate = (): boolean => {
        const errors: PropertyNameIndexer = {};
        if (!this.state.selectedSupplier) {
            errors['selectedSupplier'] = 'No supplier selected';
        }

        if(this.state.selectedTimesheetCodes.length === 0) {
            errors['selectedTimesheetCodes'] = 'No timesheet code(s) selected';
        }

        this.setState({downloadOpen: false, errors: errors});

        if(Object.keys(errors).length > 0) {
            return false;
        }

        return true;
    };

    exportSelected = (): void => {
        if(!this.validate() || !this.state.selectedSupplier) {
            return;
        }

        const monthlyTimesheetToExport: MonthlyTimesheetToExport = {
            month: this.props.period.month,
            year: this.props.period.year,
            username: this.props.user.impersonatedUser.username,
            hoursPerWeek: this.props.hoursPerWeek,
            ownProjectLeader: this.state.ownProjectLeader,
            clientProjectLeader: this.state.clientProjectLeader,
            companyId: this.state.selectedSupplier.id,
            hideComments: this.state.hideComments,
            tsCodes: this.state.selectedTimesheetCodes.map(timesheetCode => timesheetCode.id),
            emailAddress: ''
        };

        this.props.exportMonthlyTimesheet(monthlyTimesheetToExport);
        this.props.closeModal();
    };

    exportSelectedExcel = (): void => {
        if(!this.validate() || !this.state.selectedSupplier) {
            return;
        }

        const monthlyTimesheetToExport: MonthlyTimesheetToExport = {
            month: this.props.period.month,
            year: this.props.period.year,
            username: this.props.user.impersonatedUser.username,
            hoursPerWeek: this.props.hoursPerWeek,
            ownProjectLeader: this.state.ownProjectLeader,
            clientProjectLeader: this.state.clientProjectLeader,
            hideComments: this.state.hideComments,
            companyId: this.state.selectedSupplier.id,
            tsCodes: this.state.selectedTimesheetCodes.map(timesheetCode => timesheetCode.id),
            emailAddress: ''
        };

        this.props.exportMonthlyTimesheetExcel(monthlyTimesheetToExport);
        this.props.closeModal();
    };

    getFilteredSuggestions = (): StoreSupplier[] => {
        return this.props.suppliers.slice().filter(s => s.name.toLocaleLowerCase().includes(this.state.supplierFilterValue.toLocaleLowerCase()));
    };

    onSuggestionsFetchRequested = ({value}: { value: string }) => {
        this.setState({supplierFilterValue: value});
    };

    getSuggestionValue = (supplier: StoreSupplier): any => supplier;
    renderSuggestion = (supplier: StoreSupplier) => <div>{supplier.name}</div>;

    onBlur = (supplier: StoreSupplier) => {
        if(this.state.selectedSupplierValue === '') {
            this.setState({selectedSupplier: null});
            return;
        }

        if(this.state.selectedSupplier === null) {
            if (supplier === null) {
                this.setState({selectedSupplierValue: ''});
                return;
            }

            if (supplier !== null && !UserHelper.isImpersonating(this.props.user)) {
                this.props.updateSetting('defaultCompanyForTemplate', {id: supplier.id, name: supplier.name});
                const newSupplier = {
                    id: supplier.id,
                    name: supplier.name,
                    isWritable: false
                };
                this.setState({
                    selectedSupplier: newSupplier,
                    selectedSupplierValue: supplier.name
                });
            }
        }
    };

    clickEvent = (event: Event) => {
        if (this.state.downloadOpen &&
            event.target !== this.pdfButton &&
            event.target !== this.excelButton &&
            event.target !== this.downloadDiv &&
            event.target !== this.downloadCaret &&
            event.target !== this.downloadButton) {
            this.setState({downloadOpen: false});
        }
    };

    onTextEditChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.setState({ [event.currentTarget.name]: event.currentTarget.value } as unknown as Pick<State, keyof State>);

    getFilteredTimesheetCodes = (): StoreTimesheetCode[] => {
        return this.props.timesheetCodes.slice().filter(t => t.entries.length > 0);
    };

    componentDidMount() {
        if (document.body) {
            document.body.addEventListener('click', (event: Event) => this.clickEvent(event));
        }
    }

    componentWillUnmount() {
        if (document.body) {
            document.body.removeEventListener('click', (event: Event) => this.clickEvent(event));
        }
    }

    render() {
        const inputProps: AutoSuggest.InputProps<StoreSupplier> = {
            placeholder: 'required',
            value: this.state.selectedSupplierValue,
            onChange: (event: React.FormEvent<HTMLElement>, newValue: { newValue: any }) => this.onChange(event, newValue),
            onBlur: (_: React.FocusEvent<HTMLElement>, suggestion : AutoSuggest.BlurEvent<StoreSupplier>|undefined) => this.onBlur(suggestion?.highlightedSuggestion as StoreSupplier)
        };
        return (
            <ClosableModal
                title={'EXPORT MONTHLY TIMESHEET - ' + DateHelper.monthName(this.props.period.month).toUpperCase() + ' ' + this.props.period.year}
                closeModal={this.props.closeModal}
                onClick={() => this.setState({downloadOpen: false})}
                marginBottom={19}>
                <div className="form-group">
                    <label>Hours per week:</label>
                    <ContractHoursToggle />
                </div>
                <div className="export-monthly-container">
                    <div className="form-group">
                        <label data-required={this.state.errors['selectedTimesheetCodes']} className="export-monthly-timesheet-modal__required">Select timesheet codes to export:</label>
                        <div className={`timesheetcodes-container ${(this.isReadOnly() ? 'readonly' : '')}`}>
                            <div className="exportMonthly-selectAll" onClick={this.onSelectAll}>
                                <input type="checkbox" className="exportMonthly-selectAll-checkbox"
                                    checked={this.state.selectAllChecked} readOnly />
                                <div className="exportMonthly-selectAll-checkbox-text">SELECT ALL</div>
                            </div>
                            <div className="scrollBarContainer">
                                <ScrollBar>
                                    {this.getFilteredTimesheetCodes().map(timesheetCode =>
                                        <div key={timesheetCode.id} className="tscode-select">
                                            <div className="tscode-select-checkbox">
                                                <input id={`tsc-${timesheetCode.id}`}
                                                    type="checkbox"
                                                    checked={this.state.selectedTimesheetCodes.find(t => t.id === timesheetCode.id) !== undefined}
                                                    onChange={() => this.onTimesheetCodeClick(timesheetCode)}/>
                                            </div>
                                            <div className="tscode-select-name"
                                                onClick={() => this.onTimesheetCodeClick(timesheetCode)}>
                                                {timesheetCode.name}
                                            </div>
                                        </div>)}
                                </ScrollBar>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label data-required={this.state.errors['selectedSupplier']} className="export-monthly-timesheet-modal__required">Company background image:</label>
                        <Autosuggest suggestions={this.getFilteredSuggestions()}
                                    onSuggestionsClearRequested={() => {return;}}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={inputProps}
                                    highlightFirstSuggestion />
                    </div>
                    <TextInput name="ownProjectLeader"
                        label="Project leader"
                        onChange={this.onTextEditChange}/>
                    <TextInput name="clientProjectLeader"
                        label="Client project leader"
                        onChange={this.onTextEditChange}/>
                    <div className="form-group">
                        <label>Hide comments</label>
                        <input className="hide-comments-checkbox" type="checkbox" checked={this.state.hideComments} onChange={() => this.setState({hideComments: !this.state.hideComments})} />
                    </div>
                    <div className="export-monthly-timesheet-modal__required_message">Fields marked with an * are required</div>
                </div>
                <div className="closable-modal-line">
                    <div className="line"/>
                </div>
                <div className="export-selected-button-container">
                    <div ref={(el: HTMLDivElement) => this.downloadButton = el} className={'export-selected-download' + (this.state.downloadOpen ? ' export-selected-download-open' : '')} onClick={() => this.setState({downloadOpen: !this.state.downloadOpen})}>
                        <div ref={(el: HTMLDivElement) => this.downloadDiv = el}>DOWNLOAD</div><i ref={(el: HTMLDivElement) => this.downloadCaret = el} className="fas fa-sort-up"/>
                        {
                            this.state.downloadOpen ? <div className="export-selected-buttons"><input
                            ref={(el: HTMLInputElement) => this.excelButton = el}
                            className="export-selected-button"
                            type="submit" value="EXCEL"
                            onClick={this.exportSelectedExcel}
                            />
                        <input
                            ref={(el: HTMLInputElement) => this.pdfButton = el}
                            className="export-selected-button"
                            type="submit" value="PDF"
                            onClick={this.exportSelected}
                        /></div> : null
                        }
                    </div>
                    <div className="closable-modal-cancel" onClick={this.props.closeModal}>CANCEL</div>
                </div>
            </ClosableModal>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    period: Period,
    timesheetCodes: StoreTimesheetCode[],

    closeModal: () => void
};

type State = {
    ownProjectLeader: string,
    clientProjectLeader: string,
    selectedSupplierValue: string,
    selectedSupplier: StoreVisibleSupplier | null,
    selectedTimesheetCodes: StoreTimesheetCode[],
    selectAllChecked: boolean,
    downloadOpen: boolean,
    hideComments: boolean,
    errors: PropertyNameIndexer,
    supplierFilterValue: string
};

function mapStateToProps(state: StoreState, prevProps: Props) {
    return {
        period: prevProps.period,
        user: state.user,
        hoursPerWeek: state.settings.defaultContractHours,
        timesheetCodes: prevProps.timesheetCodes,
        suppliers: state.suppliers.allSuppliers,
        settings: state.settings,
        closeModal: prevProps.closeModal
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({clearSuppliers, getSuppliers, exportMonthlyTimesheet, exportMonthlyTimesheetExcel, updateSetting }, dispatch);
}

export default connector(ExportMonthlyTimesheetModal);
