import { LocationTypes } from '../../../../types/locationTypes';
import { NavigateFunction } from 'react-router-dom';
import { PermissionTypes } from '../../../../types/permissionTypes';
import { StoreUser } from '../../../../typescript/storeTypes';
import PermissionHelper from '../../../../helpers/permissionHelper';
import React from 'react';

class ReportingContainer extends React.Component<Props>{
    openTimesheets = (): void => this.props.navigate(LocationTypes.REPORTING_TIMESHEETS_FULL);
    openWeeklyTimesheets = (): void => this.props.navigate(LocationTypes.REPORTING_WEEKLY_TIMESHEETS_FULL);
    openTimesheetsLandscape = (): void => this.props.navigate(LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS_FULL);
    openActuals = (): void => this.props.navigate(LocationTypes.REPORTING_ACTUALS_FULL);
    openTimeLeftOnOrder = (): void => this.props.navigate(LocationTypes.REPORTING_TIME_LEFT_ON_ORDER_FULL);
    openProjectTimesheet = (): void => this.props.navigate(LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT_FULL);

    render() {
        return (
            <div className="title-screen">
                <div className="title">
                    Reporting
                </div>
                <div className="reports-container">
                    {PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEETS_LANDSCAPE_PDF, PermissionTypes.POST_TIMESHEETS_LANDSCAPE_EXCEL) ?
                        <div className="report-box" onClick={this.openTimesheetsLandscape}>
                            <div className="far fa-file-alt" />
                            <div className="report-box-title">Timesheets report (Landscape)</div>
                        </div> : null}
                    {PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEETS_PDF) ?
                        <div className="report-box" onClick={this.openTimesheets}>
                            <div className="far fa-file-alt" />
                            <div className="report-box-title">Timesheets report</div>
                        </div> : null}
                    {PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEETS_WEEKLY_PDF, PermissionTypes.POST_TIMESHEETS_WEEKLY_EXCEL) ?
                        <div className="report-box" onClick={this.openWeeklyTimesheets}>
                            <div className="far fa-file-alt" />
                            <div className="report-box-title">Timesheets weekly report</div>
                        </div> : null}
                    {PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_ACTUALS_PDF, PermissionTypes.POST_ACTUALS_EXCEL, PermissionTypes.POST_ACTUALS_CSV) ?
                        <div className="report-box" onClick={this.openActuals} >
                            <div className="far fa-file-excel" />
                            <div className="report-box-title">Actuals report</div>
                        </div> : null}
                    {PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIME_LEFT_ON_ORDER) ?
                        <div className="report-box" onClick={this.openTimeLeftOnOrder} >
                            <div className="far fa-file-excel" />
                            <div className="report-box-title">Time left on order</div>
                        </div> : null}
                    {PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_PROJECT_REPORT) ?
                        <div className="report-box" onClick={this.openProjectTimesheet}>
                            <div className="far fa-file-alt" />
                            <div className="report-box-title">Project based timesheet report</div>
                        </div> : null
                    }
                </div>
            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    navigate: NavigateFunction
};

export default ReportingContainer;
