import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import { WithRouterProps, withRouter } from '../../../../common/WithRouter';
import {changeUser, logoutUser} from '../../../../actions/userActions';
import {updateSetting} from '../../../../actions/settingsActions';
import ChangeUserModal from './ChangeUserModal';
import React from 'react';
import SettingsModal from './SettingsModal';
import StringHelper from '../../../../helpers/stringHelper';


import {InitialState} from '../../../../reducers/initialState';
import { LocationTypes } from '../../../../types/locationTypes';
import { PermissionTypes } from '../../../../types/permissionTypes';
import {StoreEmployee, StoreState} from '../../../../typescript/storeTypes';
import MessageHelper from '../../../../helpers/messageHelper';
import PermissionHelper from '../../../../helpers/permissionHelper';

export class Profile extends React.Component<Props, State> {
    state: State = {
        clicked: false,
        changeUserClicked: false,
        changeSettingsClicked: false
    };

    profile = React.createRef<HTMLDivElement>();

    profileDiv: HTMLDivElement | undefined;
    profileMenuItemDiv: HTMLDivElement | undefined;
    profileMenuReportingDiv: HTMLDivElement | undefined;
    profileMenuImportDiv: HTMLDivElement | undefined;
    profileMenuInvoiceViewerDiv: HTMLDivElement | undefined;
    profileMenuNameDiv: HTMLDivElement | undefined;
    profileMenuAvatarFillingDiv: HTMLDivElement | undefined;
    profileMenuCaretDiv: HTMLDivElement | undefined;
    profileChangeUserDiv: HTMLDivElement | undefined;
    profileCommentCheckboxDiv: HTMLDivElement | undefined;
    profileCommentCheckboxTextDiv: HTMLDivElement | undefined;
    profileSettings: HTMLDivElement | undefined;
    profileAccessRights: HTMLDivElement | undefined;
    profileHelp: HTMLDivElement | undefined;
    profileReleaseNotes: HTMLDivElement | undefined;
    profileGeneralInfo: HTMLDivElement | undefined;

    profileClicked = () => {
        this.setState({clicked: !this.state.clicked});
    };

    changeUserClicked = (value: boolean) => {
        this.setState({changeUserClicked: value, clicked: false, changeSettingsClicked: false});
    };

    changeSettingsClicked = (value: boolean): void => {
        this.setState({changeUserClicked: false, clicked: false, changeSettingsClicked: value});
    };

    profileOut = (event: Event) => {
        if(event.target === this.profileDiv ||
           event.target === this.profileMenuItemDiv ||
           event.target === this.profileMenuNameDiv ||
           event.target === this.profileMenuReportingDiv ||
           event.target === this.profileMenuImportDiv ||
           event.target === this.profileMenuInvoiceViewerDiv ||
           event.target === this.profileMenuAvatarFillingDiv ||
           event.target === this.profileChangeUserDiv ||
           event.target === this.profileMenuCaretDiv ||
           event.target === this.profileCommentCheckboxDiv ||
           event.target === this.profileCommentCheckboxTextDiv ||
           event.target === this.profileSettings ||
           event.target === this.profileAccessRights ||
           event.target === this.profileReleaseNotes ||
           event.target === this.profileGeneralInfo ||
           event.target === this.profileHelp) return;

        if(this.state.clicked && this.profile.current) {
            this.setState({clicked: false});
        }
    };

    logOut = (): void => {
        this.checkClickedState();
        this.closeToasts();
        this.props.logoutUser();
    };

    checkClickedState = (): void => {
        if(this.state.clicked) {
            this.setState({clicked: false});
        }
    };

    openReporting = (): void => {
        this.props.changeUser(this.props.user.employee);
        this.closeToasts();
        this.props.navigate(LocationTypes.REPORTING);
        this.checkClickedState();
    };

    openImportActuals = (): void => {
        this.props.changeUser(this.props.user.employee);
        this.closeToasts();
        this.props.navigate(LocationTypes.IMPORT_ACTUALS);
        this.checkClickedState();
    };

    openReleaseNotes = (): void => {
        this.props.changeUser(this.props.user.employee);
        this.closeToasts();
        this.props.navigate(LocationTypes.RELEASE_NOTES);
        this.checkClickedState();
    };

    openInvoiceViewer = (): void => {
        this.props.changeUser(this.props.user.employee);
        this.closeToasts();
        this.props.navigate(LocationTypes.INVOICE_VIEWER);
        this.checkClickedState();
    };

    openSettings = (): void => {
        this.changeSettingsClicked(true);
    };

    canUserChangeUser = (): boolean => {
        return this.props.user.visibleUsers.length > 1;
    };

    changeUser = (user: StoreEmployee): void => {
        this.changeUserClicked(false);
        if(this.props.user.impersonatedUser.username === user.username) return;
        this.props.changeUser(user);

        this.props.navigate(LocationTypes.HOME);
    };

    componentDidMount() {
        if (document.body) {
            document.body.addEventListener('click', (event: Event) => this.profileOut(event));
        }
    }

    canOpenReporting = () => {
        return PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_ACTUALS_PDF) ||
            PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_ACTUALS_EXCEL) ||
            PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_ACTUALS_CSV);
    };

    canOpenImport = () => {
        return PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_IMPORT_ACTUALS);
    };

    canOpenInvoiceViewer = () => {
        return PermissionHelper.hasPermission(this.props.user, PermissionTypes.SEARCH_INVOICE);
    };

    updateSetting = (name: string, value: any): void => {
        this.props.updateSetting(name, value);
    };

    checkHeaderIsSmall = (): boolean => {
        const element = document.getElementById('header');
        if(!element) return false;
        return element.className.indexOf('small') !== -1;
    };

    closeToasts = (): void => {
        MessageHelper.dismiss();
    };

    openAccessRights = (): void => {
        window.open(LocationTypes.ACCESSRIGHTS, '_blank');
        this.profileClicked();
    };

    render() {
        return (this.props.user.impersonatedUser !== undefined && this.props.user.impersonatedUser.id !== InitialState.user.impersonatedUser.id ?
            <div className="profileDropDownFlexer" ref={this.profile}>
                <div ref={(profileDiv: HTMLDivElement) => this.profileDiv = profileDiv} className={'profile ' + (this.state.clicked ? 'clicked' : '')}
                onClick={this.profileClicked}>
                    <div className="avatar">
                        <div ref={(profileMenuAvatarFillingDiv: HTMLDivElement) => this.profileMenuAvatarFillingDiv = profileMenuAvatarFillingDiv} className="avatarFilling">
                            {StringHelper.getAvatarText(this.props.user, this.props.settings.firstNameLastName)}
                        </div>
                    </div>
                    <div ref={(profileMenuNameDiv: HTMLDivElement) => this.profileMenuNameDiv = profileMenuNameDiv}  className="profileName">{StringHelper.getUserFullName(this.props.user, this.props.settings.firstNameLastName)}</div>
                    <div><i ref={(profileMenuCaretDiv: HTMLDivElement) => this.profileMenuCaretDiv = profileMenuCaretDiv} className="fas fa-caret-down" aria-hidden="true"/></div>
                </div>
                {this.state.clicked ?
                    <div className="profileMenu">
                        {this.canUserChangeUser() ? <div id="changeUserItem"
                                                       className="profileMenuItem"
                                                       onClick={() => this.changeUserClicked(true)}
                                                       ref={(changeUserDiv: HTMLDivElement) => this.profileChangeUserDiv = changeUserDiv}>CHANGE USER</div> : null}

                        {this.canOpenReporting() ? <div id="openReporting" ref={(profileMenuReportingDiv: HTMLDivElement) => this.profileMenuReportingDiv = profileMenuReportingDiv} className="profileMenuItem" onClick={this.openReporting}>OPEN REPORTING</div> : null}
                        {this.canOpenImport() ? <div id="openImportActuals" ref={(profileMenuImportDiv: HTMLDivElement) => this.profileMenuImportDiv = profileMenuImportDiv} className="profileMenuItem" onClick={this.openImportActuals}>OPEN IMPORT ACTUALS</div> : null}
                        {this.canOpenInvoiceViewer() ? <div id="openInvoiceViewer" ref={(profileMenuInvoiceViewerDiv: HTMLDivElement) => this.profileMenuInvoiceViewerDiv = profileMenuInvoiceViewerDiv} className="profileMenuItem" onClick={this.openInvoiceViewer}>OPEN INVOICE VIEWER</div> : null}
                        <div id="accessRightsMenuItem" ref={(accessRightsMenuItem: HTMLDivElement) => this.profileAccessRights = accessRightsMenuItem} className="profileMenuItem" onClick={this.openAccessRights}>OPEN ACCESS RIGHTS</div>
                        <div id="settingsMenuItem" ref={(settingsMenuItem: HTMLDivElement) => this.profileSettings = settingsMenuItem} className="profileMenuItem" onClick={this.openSettings}>SETTINGS</div>
                        <div className="profileMenuItem cleanLink profileMenuItem_help">
                            <div>HELP</div>
                            <i className="fas fa-caret-right"/>
                            <div className="helpmenu">
                                <div className="helpmenu_helpmenuitem"><a href="/resources/Manual_Timesheet_Website_EN.pdf" target="_blank">EN</a></div>
                                <div className="helpmenu_helpmenuitem"><a href="/resources/Manual_Timesheet_Website_NL.pdf" target="_blank">NL</a></div>
                            </div>
                        </div>
                        <div id="releaseNotesMenuItem" ref={(el: HTMLDivElement) => this.profileReleaseNotes = el} className="profileMenuItem" onClick={this.openReleaseNotes}>RELEASE NOTES</div>
                        <div id="logoutUser" ref={(profileMenuItemDiv: HTMLDivElement) => this.profileMenuItemDiv = profileMenuItemDiv} className="profileMenuItem" onClick={this.logOut}>LOG OFF</div>
                    </div> : null}
                {this.state.changeUserClicked ?
                    <ChangeUserModal settings={this.props.settings} me={this.props.user.employee} changeUser={this.changeUser} closeModal={() => this.changeUserClicked(false)} visibleUsers={this.props.user.visibleUsers} />
                     : null}
                {this.state.changeSettingsClicked ?
                    <SettingsModal closeModal={() => this.changeSettingsClicked(false)}
                                   updateSetting={this.updateSetting}
                                   settings={this.props.settings}
                                   user={this.props.user}/> : null}
                {this.state.clicked ? <div id="js-profile-backdrop" className={`profile-backdrop ${this.checkHeaderIsSmall() ? 'small' : ''}`}/> : null}
            </div>
             : null);
    }
}

type State = {
    clicked: boolean,
    changeUserClicked: boolean,
    changeSettingsClicked: boolean
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState, props: WithRouterProps) {
    return {
        user: state.user,
        settings: state.settings,
        period: state.timesheet.period,
        navigate: props.navigate // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        logoutUser,
        changeUser,
        updateSetting,
    }, dispatch);
}

export default withRouter(connector(Profile));
