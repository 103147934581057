import React from 'react';

import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { ApprovalTypes } from '../../../../../types/enumTypes';
import { ConnectedProps, connect } from 'react-redux';
import { PermissionTypes } from '../../../../../types/permissionTypes';
import { PropertyNameIndexer } from '../../../../../typescript/customTypes';
import { SigningHubObject } from '../../../../../typescript/signingHubTypes';
import { StoreApprover, StoreState, StoreTimesheetCode } from '../../../../../typescript/storeTypes';
import { sendMonthlyToSigningHub, sendMonthlyToSigningHubImpersonated, sendWeeklyToSigningHub, sendWeeklyToSigningHubImpersonated } from '../../../../../actions/signingHubActions';
import { updateSetting } from '../../../../../actions/settingsActions';

import ClosableModal from '../../../../../common/ClosableModal';
import DateHelper from '../../../../../helpers/dateHelper';
import DeserifyHelper from '../../../../../helpers/DeserifyHelper';
import DigitalSigningHelper from '../../../../../helpers/digitalSigningHelper';
import DigitalSigningWizardManageApprovers from './DigitalSigningWizardManageApprovers';
import DigitalSigningWizardMonthly from './DigitalSigningWizardMonthly';
import DigitalSigningWizardOverview from './DigitalSigningWizardOverview';
import DigitalSigningWizardScreen2 from './DigitalSigningWizardScreen2';
import DigitalSigningWizardWeekly from './DigitalSigningWizardWeekly';
import EmailHelper from '../../../../../helpers/emailHelper';
import ObjectHelper from '../../../../../helpers/objectHelper';
import PermissionHelper from '../../../../../helpers/permissionHelper';
import UserHelper from '../../../../../helpers/userHelper';
import WeekHelper from '../../../../../helpers/weekHelper';


export class DigitalSigningModal extends  React.Component<Props & MappedProps, State> {
    state: State = {
        currentPage: 1,
        errors: {},
        signingHubObject: DigitalSigningHelper.getEmptySigningHubObject()
    };


    getApproverErrors = (approvers: StoreApprover[], secondaryApprovers: StoreApprover[], secondaryApproversApprovalType: ApprovalTypes | undefined, errors: PropertyNameIndexer, keyForError: string): PropertyNameIndexer => {
        const copy = Object.assign({}, errors);
        if(this.filterOutEmpties(approvers).length === 0) {
            copy[`${keyForError}Approvers-NeedRecords`] = 'At least one approver is required.';
            return copy;
        }

        for (let idx = 0; idx < approvers.length; idx++) {
            const approver = approvers[idx];
            if(approver.email === '' && approver.name === '') continue;

            if(approver.email === '') {
                copy[`${keyForError}Approvers-Email-${idx}`] = 'Empty email address is not allowed.';
                continue;
            }

            if(this.state.signingHubObject.consultantApprovalNeeded && approver.email.toLowerCase() === EmailHelper.addCronosEmailIfNeeded(this.props.user.impersonatedUser.username).toLowerCase()){
                copy[`${keyForError}Approvers-Email-${idx}`] = 'Duplicate email address - This is the same email address as the consultant email address.';
            }

            if(approvers.filter(x => x.email.toLowerCase() === approver.email.toLowerCase()).length !== 1) {
                copy[`${keyForError}Approvers-Email-${idx}`] = 'Duplicate email addresses are not allowed.';
            }

            if(!!secondaryApproversApprovalType && secondaryApproversApprovalType !== ApprovalTypes.None && !secondaryApprovers.every(sa => sa.email.toLowerCase() !== approver.email.toLowerCase())) {
                copy[`${keyForError}Approvers-Email-${idx}`] = 'Duplicate email address - internal and customer.';
            }

            if(!EmailHelper.isValidEmailAddress(approver.email)) {
                copy[`${keyForError}Approvers-Email-${idx}`] = 'Invalid email address.';
            }

            if(approver.name === '') {
                copy[`${keyForError}Approvers-Name-${idx}`] = 'Empty name is not allowed.';
            }
        }

        return copy;
    };

    isValid = (): boolean => {
        let errors: PropertyNameIndexer = {};

        switch (this.state.currentPage) {
            case 1:
                if(this.props.isWeekView && this.state.signingHubObject.startDate === undefined) {
                    errors['selectedWeek'] = 'A week should be selected';
                    break;
                }
                if(this.state.signingHubObject.tsCodes.length === 0) {
                    errors['selectedTimesheetCodes'] = 'No timesheet code(s) selected';
                }
                break;
            case 2:
                if (this.state.signingHubObject.companyId === 0) {
                    errors['selectedSupplier'] = 'No supplier selected';
                }
                if(!DigitalSigningHelper.approvalNeeded(this.state.signingHubObject.customerApprovalType) && !DigitalSigningHelper.approvalNeeded(this.state.signingHubObject.internalApprovalType)) {
                    errors['signatureCheckboxes'] = 'At least one signature is needed';
                }
                break;
            case 3:
                errors = this.getApproverErrors(this.state.signingHubObject.internalApprovers, [], this.state.signingHubObject.customerApprovalType, errors, 'Internal');
                break;
            case 4:
                errors = this.getApproverErrors(this.state.signingHubObject.customerApprovers, this.state.signingHubObject.internalApprovers, this.state.signingHubObject.internalApprovalType, errors, 'Customer');
                break;
            default:
                break;
        }

        this.setState({errors: errors});
        if(Object.keys(errors).length > 0) {
            return false;
        }

        return true;
    };

    updateSigningHubObject = (signingHubObject: SigningHubObject, revalidate: boolean): void => {
        this.setState({signingHubObject}, revalidate ? this.isValid : undefined);
    };

    updateInternalApprovers = (internalApprovers: StoreApprover[], revalidate: boolean): void => {
        this.updateSigningHubObject(Object.assign({}, this.state.signingHubObject, {internalApprovers}), revalidate);
    };
    updateCustomerApprovers = (customerApprovers: StoreApprover[], revalidate: boolean): void => {
        this.updateSigningHubObject(Object.assign({}, this.state.signingHubObject, {customerApprovers}), revalidate);
    };

    static getDerivedStateFromProps(nextProps: Props & MappedProps, state: State) {
        const signingHubObject = Object.assign({}, state.signingHubObject);

        if(state.signingHubObject.hoursPerWeek !== nextProps.settings.defaultContractHours) {
            signingHubObject.hoursPerWeek = nextProps.settings.defaultContractHours;
        }

        // set defaults
        if(signingHubObject.companyId === 0) {
            const defaultCompany = UserHelper.getDefaultCompanyForTemplate(nextProps.user, nextProps.settings);
            if(defaultCompany !== null) {
                signingHubObject.companyId = defaultCompany.id;
            }
        }

        //Set month and year
        if(!nextProps.isWeekView) {
            if(state.signingHubObject.month !== nextProps.timesheet.period.month || state.signingHubObject.year !== nextProps.timesheet.period.year) {
                signingHubObject.month = nextProps.timesheet.period.month;
                signingHubObject.year = nextProps.timesheet.period.year;

                return {signingHubObject: signingHubObject};
            }
        } else {
            //if in week view
            const week = WeekHelper.getSelectedWeek(nextProps.timesheet, nextProps.settings);
            if(week) {
                signingHubObject.startDate = week.startDate;
            }
        }

        if(ObjectHelper.deepEqual(signingHubObject, state.signingHubObject)) {
            return null;
        }

        return {signingHubObject};
    }

    switchAllToOne = (approvalType: ApprovalTypes|undefined): ApprovalTypes => {
        if(!approvalType) throw new Error('approvaltype should not be undefined');

        return approvalType === ApprovalTypes.One ? ApprovalTypes.All : ApprovalTypes.One;
    };

    getScreen = () => {
        switch(this.state.currentPage) {
            case 1:
                return this.props.isWeekView ?
                <DigitalSigningWizardWeekly timesheetCodes={this.props.timesheetCodes}
                                                    timesheet={this.props.timesheet}
                                                    settings={this.props.settings}
                                                    errors={this.state.errors}
                                                    signingHubObject={this.state.signingHubObject}
                                                    updateSigningHubObject={this.updateSigningHubObject}/>  :
                <DigitalSigningWizardMonthly timesheetCodes={this.props.timesheetCodes}
                                                    errors={this.state.errors}
                                                    signingHubObject={this.state.signingHubObject}
                                                    updateSigningHubObject={this.updateSigningHubObject}/>;
            case 2:
                return <DigitalSigningWizardScreen2 suppliers={this.props.suppliers}
                                                    settings={this.props.settings}
                                                    user={this.props.user}
                                                    errors={this.state.errors}
                                                    timesheetCodes={this.getNonReadOnlySelectedTimesheetCodes()}
                                                    updateSetting={this.props.updateSetting}
                                                    signingHubObject={this.state.signingHubObject}
                                                    updateSigningHubObject={this.updateSigningHubObject} />;
            case 3:
                return <DigitalSigningWizardManageApprovers key="InternalApprovers" title="Internal"
                                                    approverList={this.state.signingHubObject.internalApprovers}
                                                    signedByAll={this.state.signingHubObject.internalApprovalType === ApprovalTypes.All}
                                                    errors={this.state.errors}
                                                    updateApproversList={this.updateInternalApprovers}
                                                    signedByAllClicked={() => this.updateSigningHubObject(Object.assign({}, this.state.signingHubObject, {internalApprovalType: this.switchAllToOne(this.state.signingHubObject.internalApprovalType)}), false)} />;
            case 4:
                return <DigitalSigningWizardManageApprovers key="CustomerApprovers" title="Customer"
                                                    approverList={this.state.signingHubObject.customerApprovers}
                                                    signedByAll={this.state.signingHubObject.customerApprovalType === ApprovalTypes.All}
                                                    errors={this.state.errors}
                                                    updateApproversList={this.updateCustomerApprovers}
                                                    signedByAllClicked={() => this.updateSigningHubObject(Object.assign({}, this.state.signingHubObject, {customerApprovalType: this.switchAllToOne(this.state.signingHubObject.customerApprovalType)}), false)} />;
            case 5:
                return <DigitalSigningWizardOverview signingHubObject={this.state.signingHubObject}
                                                    suppliers={this.props.suppliers}
                                                    timesheetCodes={this.getSelectedTimesheetCodes()}
                                                    updateSigningHubObject={this.updateSigningHubObject} />;
            default:
                return <div />;
          }
    };

    isEmptyApprover = (approver: StoreApprover): boolean => approver.email === '' && approver.name === '';
    filterOutEmpties = (approvers: StoreApprover[]): StoreApprover[] => approvers.filter(a => !this.isEmptyApprover(a));
    getSigningHubObjectWithValidApprovers = (): SigningHubObject => {
        const internalApprovers = this.filterOutEmpties(this.state.signingHubObject.internalApprovers);
        const customerApprovers = this.filterOutEmpties(this.state.signingHubObject.customerApprovers);

        return Object.assign({}, this.state.signingHubObject, {internalApprovers, customerApprovers});
    };

    getApprovers = (tsc: StoreTimesheetCode, approverType: string): StoreApprover[] => {
        return (tsc as any)[approverType];
    };

    compareApprovers = (timesheetCodes: StoreTimesheetCode[], approverType: string): boolean => {
        if(timesheetCodes.length === 0) return false;

        if(this.getApprovers(timesheetCodes[0], approverType).length === 0) {
            return false;
        }

        for(let i = 1; i < timesheetCodes.length; i++) {
            if(this.getApprovers(timesheetCodes[0], approverType).length !== this.getApprovers(timesheetCodes[i], approverType).length) {
                return false;
            }

            if(!(this.getApprovers(timesheetCodes[i], approverType).every(a => this.getApprovers(timesheetCodes[0], approverType).some(aa => aa.email.toLowerCase() === a.email.toLowerCase())))) {
                return false;
            }
        }

        return true;
    };

    navigationClicked = (increment: number): void => {
        const signingHubObject =  this.getSigningHubObjectWithValidApprovers();

        if(increment > 0) {
            if(!this.isValid()) return;

            const timesheetCodes = this.getNonReadOnlySelectedTimesheetCodes();

            //calculate internal approvers
            if(this.state.currentPage === 2) {
                if(!DigitalSigningHelper.approvalNeeded(this.state.signingHubObject.internalApprovalType)) {
                    increment++;
                } else if(this.filterOutEmpties(this.state.signingHubObject.internalApprovers).length === 0) {
                    signingHubObject.internalApprovers = timesheetCodes.flatMap(tc => tc.internalApprovers)
                                                                       .filter((v,i,a) => a.findIndex(t=>(t.name === v.name && t.email===v.email))===i);
                }
            }

            //calculate customer approvers
            if(this.state.currentPage === 3 || this.state.currentPage === 2 && increment === 2) { //Customer approver screen or skipped from internal approver screen
                if(!DigitalSigningHelper.approvalNeeded(this.state.signingHubObject.customerApprovalType)) {
                    increment++;
                } else if(this.filterOutEmpties(this.state.signingHubObject.customerApprovers).length === 0) {
                    signingHubObject.customerApprovers = timesheetCodes.flatMap(ts => ts.customerApprovers)
                                                                       .filter((v,i,a) => a.findIndex(t=>(t.name === v.name && t.email===v.email))===i);
                }
            }
        } else {
        if(this.state.currentPage === 4 && !DigitalSigningHelper.approvalNeeded(this.state.signingHubObject.internalApprovalType)) increment--;
            if(this.state.currentPage === 5 && !DigitalSigningHelper.approvalNeeded(this.state.signingHubObject.customerApprovalType)) increment--;
        }

        this.setState({currentPage: this.state.currentPage + increment, signingHubObject});
    };

    getSelectedTimesheetCodes = (): StoreTimesheetCode[] => {
        return this.props.timesheetCodes.filter(e => this.state.signingHubObject.tsCodes.find(tsId => tsId === e.id));
    };

    getNonReadOnlySelectedTimesheetCodes = (): StoreTimesheetCode[] => {
        return this.getSelectedTimesheetCodes().filter(e => !e.isReadOnly);
    };

    sendToSigningHub = (): void => {
        if(!this.isValid()) return;

        if(this.props.isWeekView) {
            UserHelper.isImpersonating(this.props.user) && PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_WEEKLY_TO_SIGNING_HUB)
                ? this.props.sendWeeklyToSigningHubImpersonated(this.filterApprovers(this.state.signingHubObject), this.props.timesheet.period, this.props.user.impersonatedUser)
                : this.props.sendWeeklyToSigningHub(this.filterApprovers(this.state.signingHubObject), this.props.timesheet.period, this.props.user.impersonatedUser);
        } else {
            UserHelper.isImpersonating(this.props.user) && PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEET_TO_SIGNING_HUB)
                ? this.props.sendMonthlyToSigningHubImpersonated(this.filterApprovers(this.state.signingHubObject), this.props.timesheet.period, this.props.user.impersonatedUser)
                : this.props.sendMonthlyToSigningHub(this.filterApprovers(this.state.signingHubObject), this.props.timesheet.period, this.props.user.impersonatedUser);
        }

        this.props.closeModal();
    };

    filterApprovers = (signinghubObject: SigningHubObject): SigningHubObject => ({
        ...signinghubObject,
        internalApprovers: signinghubObject.internalApprovalType === ApprovalTypes.None ? [] : signinghubObject.internalApprovers,
        customerApprovers: signinghubObject.customerApprovalType === ApprovalTypes.None ? [] : signinghubObject.customerApprovers,
    });

    getTitle = (): string => {
        if(this.props.isWeekView) {
            if(this.state.signingHubObject.startDate) {
                return `WEEK ${DateHelper.getWeekNumber(this.state.signingHubObject.startDate)}`;
            } else {
                return 'WEEK NOT SELECTED';
            }
        }

        return `${DateHelper.monthName(this.props.timesheet.period.month).toUpperCase()} ${this.props.timesheet.period.year}`;
    };

    render() {
        return (
            <ClosableModal className="digital-signing-modal"
                title={`DIGITAL SIGNING - ${this.getTitle()}`}
                closeModal={this.props.closeModal}
                onClick={() => undefined}
                marginBottom={19}>
                {this.props.isWeekView ?
                    <div className="digital-signing-modal__weekly">{this.getScreen()}</div>
                    :
                    <div className="digital-signing-modal__monthly">{this.getScreen()}</div>
                }
                <div className="closable-modal-line">
                    <div className="line"/>
                </div>
                <div className="digital-signing-modal-button-container">
                    {this.state.currentPage !== 5 ?
                    <div className="digital-signing-modal-navigation" onClick={() => this.navigationClicked(1)}>
                        <div>NEXT</div><i className="fas fa-arrow-right"/>
                    </div> :
                    <div className="digital-signing-modal-navigation" onClick={() => this.sendToSigningHub()}>
                        <div>SEND</div><i className="fas fa-paper-plane"/>
                    </div>
                    }
                    {this.state.currentPage !== 1 ?
                    <div className="digital-signing-modal-navigation" onClick={() => this.navigationClicked(-1)}>
                        <i className="fas fa-arrow-left"/><div>PREVIOUS</div>
                    </div> : undefined}
                    <div className="closable-modal-cancel" onClick={this.props.closeModal}>CANCEL</div>
                    <div className="digital-signing-modal-pages">{this.state.currentPage}/5</div>
                </div>
            </ClosableModal>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    isWeekView: boolean,
    timesheetCodes: StoreTimesheetCode[],

    closeModal: () => void
};

type State = {
    currentPage: number,
    errors: PropertyNameIndexer,
    signingHubObject: SigningHubObject
};

function mapStateToProps(state: StoreState, prevProps: Props) {
    return {
        timesheet: DeserifyHelper.deserify(state.timesheet),
        suppliers: state.suppliers.allSuppliers,
        settings: state.settings,
        user: state.user,
        timesheetCodes: prevProps.timesheetCodes,
        closeModal: prevProps.closeModal
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({sendMonthlyToSigningHub, sendMonthlyToSigningHubImpersonated, sendWeeklyToSigningHub, sendWeeklyToSigningHubImpersonated, updateSetting}, dispatch);
}

export default connector(DigitalSigningModal);
