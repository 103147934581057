import ApiHelper from '../../../../helpers/apiHelper';
import CopyToClipboard from 'react-copy-to-clipboard';
import React from 'react';

class TimesheetCode extends React.PureComponent<Props, State> {
    static defaultProps = {
        description: '',
        expanded: false,
        isReadOnly: true,
        isTsCodeActive: true,
        activeRowLevel: 0,
        showClipboard: true
    };

    state: State = {
        copied: false
    };

    onTimesheetCodeExpand = () => {
        if (this.props.onTimesheetCodeExpand) {
            this.props.onTimesheetCodeExpand();
        }
    };

    setCopied = () => {
        this.setState({copied: true}, ApiHelper.debounce(() => this.setState({copied: false}),2000));
    };

    render() {
        return (
            <div className={`timesheetcode-container ${this.props.isReadOnly ? 'readonly' : ''} ${this.props.activeRowLevel !== 0 ? 'highlighted' : ''} ${this.props.activeRowLevel === 1 ? 'active-row-level' : ''} ${this.props.expanded ? 'timesheetcode-container-expanded' : ''}`}
                    onMouseDown={this.onTimesheetCodeExpand}>
                <div className={`timesheetcode ${this.props.activeRowLevel === 1 ? 'active-row-level' : ''}`}>
                    <div className={`timesheet-code-name-container ${!this.props.isTsCodeActive ? 'timesheetcode-inactive' : ''}`}>
                        <div className={`timesheet-code-name  ${this.props.activeRowLevel !== 0 ? 'active-row-level' : ''} ${this.props.expanded ? 'highlighted' : ''} ${this.props.name && this.props.name.length > 26 ? 'timesheet-code-name-small' : ''} ${this.props.description && this.props.description.length > 34 ? 'large-description' : ''}`}>{this.props.name}</div>
                        <div className={`timesheet-code-description ${this.props.description && this.props.description.length > 34 ? 'timesheet-code-description-small' : ''}`}>{this.props.description}</div>
                    </div>
                    {!this.props.isReadOnly ?
                    <div className="timesheetcode-collapse">
                        <i className={'fas fa-' + (this.props.expanded ? 'angle-up' : 'angle-down')}
                            aria-hidden="true"/>
                    </div> : null}
                </div>
                <div className={`${!this.props.expanded ? 'timesheet-code-hours-container-collapsed' : ''}`}>
                    <div className={'timesheet-code-hours' + (this.props.activeRowLevel === 2 ? ' active-row-level' : '')}>NORMAL HOURS</div>
                    <div className={'timesheet-code-hours' + (this.props.activeRowLevel === 3 ? ' active-row-level' : '')}>EXTRA HOURS</div>
                    <div className={'timesheet-code-hours' + (this.props.activeRowLevel === 4 ? ' active-row-level' : '')}>STANDBY HOURS</div>
                    <div className={'timesheet-code-hours' + (this.props.activeRowLevel === 5 ? ' active-row-level' : '')}>INTERVENTION HOURS</div>
                </div>
                {this.props.showClipboard ?
                    <div className={`copy-timesheetcode-to-clipboard ${this.props.expanded ? 'expanded' : ''}`}>
                        <CopyToClipboard text={this.props.name} onCopy={this.setCopied}
                                        onMouseDown={(e: any) => e.stopPropagation()}>
                            <i className="fas fa-clipboard"/>
                        </CopyToClipboard>
                    </div> : null}
                {this.state.copied ? <div className="copied">Copied to clipboard</div> : undefined}
            </div>);
    }
}

type Props = {
    name: string,
    description: string,
    onTimesheetCodeExpand?: (fromEntry?: boolean) => void,
    expanded: boolean,
    isReadOnly: boolean,
    isTsCodeActive: boolean,
    activeRowLevel: number,
    showClipboard: boolean
};

type State = {
    copied: boolean
};

export default TimesheetCode;
