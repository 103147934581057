import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';

import { SuggestionInfo } from '../../../../typescript/infoTypes';
import InfiniteScroll from 'react-infinite-scroller';
import TextInput from './../../../../common/TextInput';

class AutoSuggestList<T> extends React.Component<Props, State> {
    static defaultProps = {
        pagingAllowedOnFetch: false,
        singleItemSelection: false,
        placeholder: 'search',
        clearPagingList: undefined
    };

    state: State = {
        value: '',
        sortAscending: true,
        totalCount: 0,
        firstSuggestionId: null,
        suggestionLength: 0,
        isLoading: false,
        pageLoaded: 0
    };

    static getDerivedStateFromProps(props: Props, state: State): State {

        const newSuggestions = props.suggestions;
        const hasNewSuggestions = newSuggestions && !!newSuggestions.length;
        const newFirstSuggestionId = hasNewSuggestions ? newSuggestions[0].id : null;
        const newTotalCount = hasNewSuggestions ? newSuggestions[0].totalCount : 0;
        const newSuggestionLength = hasNewSuggestions ? newSuggestions.length : 0;

        const hasDifferentTotalCount = newTotalCount !== state.totalCount;
        const hasDifferentFirstSuggestion = newFirstSuggestionId !== state.firstSuggestionId;
        const hasDifferentSuggestionLength = newSuggestionLength !== state.suggestionLength;
        const hasDifferentSuggestions = hasDifferentTotalCount || hasDifferentFirstSuggestion || hasDifferentSuggestionLength;

        return {
            ...state,
            totalCount: newTotalCount,
            firstSuggestionId: newFirstSuggestionId,
            suggestionLength: newSuggestionLength,
            isLoading: hasDifferentSuggestions || !state.value ? false : state.isLoading
        };
    }


    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        if(this.props.clearPagingList) this.props.clearPagingList();
        this.setState({value: event.currentTarget.value, pageLoaded: 0}, () => this.fetch());
    };

    getFilteredList = (props: Props): SuggestionInfo<T>[] => {
        if (this.state.value === '' && props.suggestions.length > 0 && !props.showAllSuggestionsWhenEmptyFilterValue) {
            return [];
        }

        return props.suggestions.filter(this.withoutSelectedItems);
    };

    selectItems = (items: SuggestionInfo<T>[]): void => {
        if(this.props.singleItemSelection) {
            this.props.selectSuggestion([items[0]]);
            return;
        }

        this.props.selectSuggestion(items);

        if(this.hasMoreItems())
        {
            this.fetch();
        }
    };

    deselectItems = (items: SuggestionInfo<T>[]): void => {
        this.props.unSelectSuggestion(items.map(e => e.id));
    };

    reverseSort = (): void => {
        this.setState({sortAscending: !this.state.sortAscending});
    };

    getSortedItems = (): SuggestionInfo<T>[] => {
        const sorted = this.getFilteredList(this.props).sort(function(item1, item2) {
            const value1 = item1.displayValue.toString().toLowerCase();
            const value2 = item2.displayValue.toString().toLowerCase();
            if(value1 < value2) return -1;
            if(value1 > value2) return 1;
            return 0;
        });

        if(!this.state.sortAscending) sorted.reverse();

        return sorted;
    };

    fetch = (): void => {
        this.setState(state => ({ isLoading: true, pageLoaded: state.pageLoaded + 1 }), () => {
            this.props.pagingAllowedOnFetch ?
                this.props.fetch(this.state.value, this.state.pageLoaded) :
                this.props.fetch(this.state.value);
        });
    };

    fetchWithRespectingLoading = () => {
        if(this.state.isLoading || !this.hasMoreItems())
            return;

        this.fetch();
    };

    selectAll = () => {
        this.selectItems(this.getSortedItems());
    };

    deselectAll = () => {
        this.deselectItems(this.props.selected);
    };

    withoutSelectedItems = (suggestion: SuggestionInfo<T>) => this.props.selected.findIndex(selected => suggestion.id === selected.id) === -1;

    hasMoreItems = (): boolean => {
        if(!this.state.value) return false;
        if(!this.props.pagingAllowedOnFetch) return false;
        if(!this.props.suggestions || !this.props.suggestions.length) return false;


        const hasMoreToLoad = this.state.suggestionLength < this.state.totalCount && !this.state.isLoading;
        return hasMoreToLoad;
    };

    render() {
        return (
            <div className="autosuggest-list">
                <label>
                    {this.props.listName}
                    <div className="sorting fa-stack" onClick={this.reverseSort}>
                        <div className={`fas fa-sort-up fa-stack-1x ${this.state.sortAscending ? 'active' : ''}`} />
                        <div className={`fas fa-sort-down fa-stack-1x ${!this.state.sortAscending ? 'active' : ''}`} />
                    </div>
                </label>
                <TextInput id="searchSuggestions"
                           name="searchSuggestions"
                           placeholder={this.props.placeholder}
                           onChange={(event: React.SyntheticEvent<HTMLInputElement> & {currentTarget: HTMLInputElement}) => this.onChange(event)}/>
                {this.props.selected.length > 9 ?
                <div className="selectedList scroll-height">
                    <div className="extra-options-box">
                        {!this.props.singleItemSelection && this.props.selected.length > 0 ?
                            <div className="button" onClick={this.deselectAll}>Deselect all</div> :
                        null}
                    </div>
                    <div className="scroll-height">
                        <ScrollBar>
                            {this.props.selected.map(item => {
                                return <div key={item.id + '(' + item.displayValue +')'} onClick={() => this.deselectItems([item])} className="selected"><div className="fas fa-times"/>{item.displayValue}</div>;
                            })}
                        </ScrollBar>
                    </div>
                </div>
                :
                <div className="selectedList">
                    <div className="extra-options-box">
                        {!this.props.singleItemSelection && this.props.selected.length > 0 ?
                            <div className="button" onClick={this.deselectAll}>Deselect all</div> :
                        null}
                    </div>
                    {this.props.selected.map(item => {
                        return <div key={item.id + '(' + item.displayValue +')'} onClick={() => this.deselectItems([item])} className="selected"><div className="fas fa-times"/>{item.displayValue}</div>;
                    })}
                </div>}
                <div className="extra-options-box">
                        {!this.props.singleItemSelection && this.getSortedItems().length > 0 ?
                            <div className="button" onClick={this.selectAll}>select shown</div>
                        : null}
                    </div>
                <div id={this.props.listName} className="suggestedList">
                    <InfiniteScroll
                        id={this.props.listName + 'infinite'}
                        pageStart={1}
                        loadMore={() => this.fetchWithRespectingLoading()}
                        loader={<div key="loading"><div className="fas fa-circle-notch fa-spin"/></div>}
                        hasMore={this.hasMoreItems()}
                        initialLoad={false}
                        useWindow={false}>

                        {this.getSortedItems().map(i =>
                            <div key={i.id + '(' + i.displayValue +')'} onClick={() => this.selectItems([i])} className="suggestion">{i.displayValue}</div>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

type State = {
    value: string,
    sortAscending: boolean,
    totalCount: number,
    suggestionLength: number,
    firstSuggestionId: null | number,
    isLoading: boolean,
    pageLoaded: number
};

type Props = {
    listName: string,
    suggestions: SuggestionInfo<any>[],
    pagingAllowedOnFetch: boolean,
    singleItemSelection: boolean,
    placeholder: string,
    selected: SuggestionInfo<any>[],
    showAllSuggestionsWhenEmptyFilterValue?: boolean,

    fetch: (value: string, page?: number) => any,
    selectSuggestion: (suggestion: any[]) => void,
    unSelectSuggestion: (id: any[]) => void,
    clearPagingList: (() => void)|undefined
};

export default AutoSuggestList;
