import React from 'react';

import { PropertyNameIndexer } from '../../../../../typescript/customTypes';
import { SigningHubObject } from '../../../../../typescript/signingHubTypes';
import { StoreTimesheetCode } from '../../../../../typescript/storeTypes';

import DigitalSigningWizardScreen1 from './DigitalSigningWizardScreen1';

class DigitalSigningWizardMonthly extends React.PureComponent<Props> {    
    render() {
        return (
            <div className="digital-signing-wizard-monthly">
                <DigitalSigningWizardScreen1 timesheetCodes={this.props.timesheetCodes}
                                             errors={this.props.errors}
                                             signingHubObject={this.props.signingHubObject}
                                             updateSigningHubObject={this.props.updateSigningHubObject}/>
            </div>
        );
    }
}

type Props = {
    signingHubObject: SigningHubObject,  
    timesheetCodes: StoreTimesheetCode[],
    errors: PropertyNameIndexer,
    updateSigningHubObject: (signingHubObject: SigningHubObject, revalidate: boolean) => void
};

export default DigitalSigningWizardMonthly;
