import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {StoreActuals} from '../typescript/storeTypes';


export function validateActuals(actuals: StoreActuals[]): Action {
    return {type: ActionTypes.VALIDATE_ACTUALS_REQUESTED, payload: actuals};
}

export function clearValidatedActuals(): Action {
    return {type: ActionTypes.CLEAR_VALIDATED_ACTUALS_REQUESTED, payload: null};
}

export function processImportActuals(importId: string): Action {
    return {type: ActionTypes.PROCESS_IMPORT_ACTUALS_REQUESTED, payload: {importId: importId}};
}
