import { ApprovalTypes } from '../types/enumTypes';
import { SigningHubObject } from '../typescript/signingHubTypes';

class DigitalSigningHelper {
    static getEmptySigningHubObject = (): SigningHubObject => {
        return {
            month: 0,
            year: 0,
            startDate: undefined,
            hoursPerWeek: 0,
            companyId: 0,
            hideComments: false,
            tsCodes: [],
            assignments: [],
            internalApprovers: [],
            customerApprovers: [],
            internalApprovalType: undefined,
            customerApprovalType: undefined,
            consultantApprovalNeeded: false,
            saveSettings: false
        };
    };

    static approvalNeeded = (approvalType: ApprovalTypes|undefined): boolean => {
        if(!approvalType) return false;

        return approvalType === ApprovalTypes.All || approvalType === ApprovalTypes.One;
    };

    static resetAllButFirstPage = (original: SigningHubObject): SigningHubObject => {
        const newObj = DigitalSigningHelper.getEmptySigningHubObject();
        return {
            ...newObj,
            month: original.month,
            year: original.year,
            startDate: original.startDate,
            hoursPerWeek: original.hoursPerWeek,
            hideComments: original.hideComments,
            assignments: original.assignments,
            tsCodes: original.tsCodes
        };
    };
}

export default DigitalSigningHelper;
