import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import TimesheetCompleteModal from './TimesheetCompleteModal';
import TimesheetHelper from '../../../../helpers/timesheetHelper';

import {StoreTimesheet} from '../../../../typescript/storeTypes';

class TimesheetComplete extends React.PureComponent<Props, State> {
    state: State = {
        days: 0
    };

    checkDays = () => {
        if (!this.props.canComplete || this.props.completionCalled || this.props.isComplete || !this.hasTimesheetCodes()) return;
        const days = TimesheetHelper.calculateDays(this.props.timesheet);
        if (days > 0) {
            this.setState({days: days});
            return;
        }

        this.completeTimesheet();
    };

    completeTimesheet = () => {
        if (!this.props.canComplete || this.props.completionCalled) return;
        this.props.completeTimesheet();
        this.closeModal();
    };

    incompleteTimesheet = () => {
        if (!this.props.canUncomplete || this.props.completionCalled) return;
        this.props.incompleteTimesheet();
    };

    closeModal = () => {
        this.setState({days: 0});
    };

    hasTimesheetCodes = () => {
        const timesheets = this.props.timesheet.timesheetCodes.filter(tsCode => !tsCode.isReadOnly);
        return !(timesheets.length === 0);
    };

    render() {
        return (
            <div className="timesheet-complete">
                {
                    this.props.isComplete && this.props.canUncomplete
                    ? <input className="timesheet-complete-submit"
                             type="submit" value="UNCOMPLETE TIMESHEET" disabled={this.props.completionCalled}
                             onClick={() => this.incompleteTimesheet()}/>
                    : <input
                        className="timesheet-complete-submit"
                        type="submit" value="COMPLETE TIMESHEET" disabled={!(this.hasTimesheetCodes() && !this.props.completionCalled && !this.props.isComplete && this.props.canComplete)}
                        onClick={() => this.checkDays()}/>
                }
                {this.state.days > 0 ? <TimesheetCompleteModal closeModal={this.closeModal}
                                                               days={this.state.days}
                                                               complete={this.completeTimesheet}
                                                               month={DateHelper.monthName(this.props.timesheet.period.month)}
                                                               year={this.props.timesheet.period.year}/> : null}
            </div>
        );
    }
}

type State = {
    days: number
};

type Props = {
    completeTimesheet: () => void,
    incompleteTimesheet: () => void,
    timesheet: StoreTimesheet,
    isComplete: boolean,
    canUncomplete: boolean,
    canComplete: boolean,
    completionCalled: boolean
};

export default TimesheetComplete;
