import React from 'react';

class Legend extends React.PureComponent<Props, State> {
    state: State = {
        isLegendTooltipOpen: false
    };

    render() {
        return (
            <div className="legend no-select">
                <div className="legend-button" onClick={() => this.setState({isLegendTooltipOpen: !this.state.isLegendTooltipOpen})} >
                    <div className="float">Legend</div>
                    <i className={'fas fa-angle-' + (this.state.isLegendTooltipOpen ? 'up' : 'down')} aria-hidden="true"/>
                </div>
                {this.state.isLegendTooltipOpen ?
                    <div className="legend-border">
                        <div className="legend-item">
                            <div className="legend-color holiday"/>
                            <div className="legend-description">Holiday</div>
                        </div>
                        <div className="legend-item">
                            <div className="legend-color vacation"/>
                            <div className="legend-description">Vacation</div>
                        </div>
                        <div className="legend-item">
                            <div className="legend-color weekend"/>
                            <div className="legend-description">Weekend</div>
                        </div>
                        <div className="legend-item">
                            <div className="legend-color fas fa-circle comment"/>
                            <div className="legend-description">Contains comments</div>
                        </div>
                    </div>
                : null}
            </div>
        );
    }
}

type Props = {

};

type State = {
    isLegendTooltipOpen: boolean
};

export default Legend;
