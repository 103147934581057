import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {addAssignment} from '../../../../actions/assignmentActions';
import {clearTsCodes, getMostRecentTsCodes, getTsCodes} from '../../../../actions/tsCodeActions';
import ApiHelper from '../../../../helpers/apiHelper';
import ClosableModal from './../../../../common/ClosableModal';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import TextInput from './../../../../common/TextInput';
import TimesheetHelper from '../../../../helpers/timesheetHelper';
import getAddableTimesheetCodes from '../../../selectors/timesheetSelectors';

import {Period} from '../../../../typescript/dateTypes';
import { StoreState, StoreTsCode, StoreTsCodes } from '../../../../typescript/storeTypes';
import DefaultSubmit from '../../../../common/DefaultSubmit';
import ObjectHelper from '../../../../helpers/objectHelper';

export class AddTimesheetCodeModal extends React.Component<Props & MappedProps, State> {
    static defaultState: State = {
        value: '',
        activeTab: 0,
        selectedTsCodes: [],
        tabChangeRequested: false,
        selectAllChecked: false
    };

    state = Object.assign({}, ObjectHelper.deepClone(AddTimesheetCodeModal.defaultState), {value: this.props.value});
    getTsCodes: (value: string) => void = ApiHelper.debounce(this.props.getTsCodes, 500);

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        this.setState({value: event.currentTarget.value});
        this.getTsCodes(event.currentTarget.value);
    };

    onTimesheetCodeClick = (tsCode: StoreTsCode): void => {
        const newSelectedTsCodes = this.state.selectedTsCodes.slice();
        if (this.state.selectedTsCodes.find((timesheetCode: StoreTsCode) => timesheetCode.id === tsCode.id)) {
            const index = newSelectedTsCodes.indexOf(tsCode);
            newSelectedTsCodes.splice(index, 1);
        } else {
            newSelectedTsCodes.push(tsCode);
        }
        this.setState({
            selectedTsCodes: newSelectedTsCodes,
            selectAllChecked: newSelectedTsCodes.length === this.getFilteredTimesheetCodes().length
        });
    };

    addSelected = (): void => {
        for (let i = 0, len = this.state.selectedTsCodes.length; i < len; i++) {
            const assignment = {
                username: this.props.user.impersonatedUser.username,
                tsCodeId: this.state.selectedTsCodes[i].id,
                name: this.state.selectedTsCodes[i].name,
                description: this.state.selectedTsCodes[i].description,
                month: this.props.period.month,
                year: this.props.period.year,
                comments: '',
                isBillable: this.state.selectedTsCodes[i].isBillable
            };
            this.props.addAssignment(assignment, this.props.user);
        }

        this.props.closeModal();
    };

    getFilteredTimesheetCodes = (): StoreTsCodes => {
        return TimesheetHelper.sortTsCodes(this.getTsCodesToUse()
            .filter(tsc => this.props.timesheetCodes.findIndex(tsc2 => tsc.id === tsc2.id) === -1));
    };

    isTabActive = (tab: number): boolean => {
        return this.state.activeTab === tab;
    };

    setTabActive = (tab: number): void => {
        if (this.state.tabChangeRequested) return;

        this.setState({tabChangeRequested: true, activeTab: tab, selectedTsCodes: [], selectAllChecked: false});
        switch (tab) {
            case 0: {
                if(!this.state.value) {
                    this.setState({tabChangeRequested: false});
                } else {
                    this.props.getTsCodes(this.state.value);
                }
                break;
            }
            case 1: {
                const month = DateHelper.addMonths(this.props.period.startDate, -1);
                this.props.getMostRecentTsCodes(this.props.user.impersonatedUser.username,
                    month.getMonth() + 1,
                    month.getFullYear());
                break;
            }
        }
    };

    onSelectAll = (): void => {
        const selectedTsCodes = [];
        if (!this.state.selectAllChecked) {
            const tsCodesToUse = this.getFilteredTimesheetCodes();
            for (let i = 0, len = tsCodesToUse.length; i < len; i++) {
                selectedTsCodes.push(tsCodesToUse[i]);
            }
            this.setState({selectAllChecked: true});
        }
        else {
            this.setState({selectAllChecked: false});
        }
        this.setState({selectedTsCodes: selectedTsCodes});
    };

    getTsCodesToUse = (): StoreTsCodes => {
        return this.state.activeTab === 0 ? this.props.tsCodes : this.props.mostRecentTsCodes;
    };

    static getDerivedStateFromProps(nextProps: Props, state: State)  {
        if(state.tabChangeRequested) {
            return {tabChangeRequested: false};
        }

        return null;
    }

    componentDidMount() {
        this.props.clearTsCodes();
    }

    componentWillUnmount() {
        this.props.clearTsCodes();
    }

    render() {
        return (
            <ClosableModal closeModal={this.props.closeModal}>
                <div className="timesheet-code-tab-container">
                    <div className={'timesheet-code-tab' + (this.isTabActive(0) ? ' active' : '')}
                         onClick={() => this.setTabActive(0)}>
                        <div>SEARCH TIMESHEET CODE</div>
                        <div className={'line' + (this.isTabActive(0) ? ' black' : '')}/>
                    </div>
                    <div className={'timesheet-code-tab' + (this.isTabActive(1) ? ' active' : '')}
                         onClick={() => this.setTabActive(1)}>
                        <div>MY RECENT TIMESHEET CODES</div>
                        <div className={'line' + (this.isTabActive(1) ? ' black' : '')}/>
                    </div>
                </div>
                <div className="timesheet-code-tab-content-timesheet-codes">
                    {this.isTabActive(0) ?
                        <TextInput
                                   id="searchTimesheetCode"
                                   name="searchTimesheetCode"
                                   value={this.state.value}
                                   onChange={(event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.onChange(event)}
                                   autoFocus/> : null}
                    {this.getTsCodesToUse().length === parseInt(process.env.API_PAGESIZE as unknown as string) && this.isTabActive(0) ?
                        <div className="searchResultMessage">Too many results. Please refine your search.</div>
                        : null
                    }
                    {this.isTabActive(1) ? (this.getFilteredTimesheetCodes().length > 0 ?
                                                (!this.isTabActive(0) ?
                                                    <div className="addTimesheetCode-selectAll"
                                                        onClick={this.onSelectAll}>
                                                        <input type="checkbox" className="addTimesheetCode-selectAll-checkbox" checked={this.state.selectAllChecked}/>
                                                        <div className="addTimesheetCode-selectAll-checkbox-text">SELECT ALL</div>
                                                    </div>
                                                : null)
                                            : <div className="no-most-recent-tscodes">No results found</div>)
                    : null}
                    <ScrollBar className="scrollBar">
                        {this.getFilteredTimesheetCodes().map(timesheetCode =>
                            <div key={timesheetCode.name} className="tscode-select">
                                <div className="tscode-select-checkbox">
                                    <input type="checkbox"
                                           checked={this.state.selectedTsCodes.find((t: StoreTsCode) => t.id === timesheetCode.id) !== undefined}
                                           onChange={() => this.onTimesheetCodeClick(timesheetCode)}/>
                                </div>
                                <div className="tscode-select-container"
                                     onClick={() => this.onTimesheetCodeClick(timesheetCode)}>
                                    <div className="tscode-select-name">{timesheetCode.name}</div>
                                    <div className="tscode-select-description">{timesheetCode.description}</div>
                                </div>
                            </div>)}
                    </ScrollBar>
                </div>
                <div className="closable-modal-line">
                    <div className="line"/>
                </div>
                <div className="add-selected-button-container">
                    <DefaultSubmit className="add-selected-button"
                        value="ADD SELECTED"
                        onSubmit={this.addSelected}
                        disabled={this.state.selectedTsCodes.length === 0}/>
                    <div className="closable-modal-cancel" onClick={this.props.closeModal}>CANCEL</div>
                </div>
            </ClosableModal>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    value: string,
    period: Period,

    closeModal: () => void
};

type State = {
    value: string,
    activeTab: number,
    selectedTsCodes: StoreTsCode[],
    tabChangeRequested: boolean,
    selectAllChecked: boolean
};

function mapStateToProps(state: StoreState, props: Props) {
    return {
        value: props.value,
        period: props.period,
        user: state.user,
        tsCodes: state.tsCodes,
        mostRecentTsCodes: state.mostRecentTsCodes,
        timesheetCodes: getAddableTimesheetCodes(state, props),

        closeModal: props.closeModal
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({getTsCodes, addAssignment, getMostRecentTsCodes, clearTsCodes}, dispatch);
}

export default connector(AddTimesheetCodeModal);
