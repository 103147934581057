import * as React from 'react';
import { LocationTypes } from '../../../types/locationTypes';
import {  Navigate } from 'react-router-dom';
import { StoreUser } from '../../../typescript/storeTypes';
import PermissionHelper from '../../../helpers/permissionHelper';

interface ProtectedRouteProps {
    user: StoreUser,
    permissionType: string,
    children: React.ReactNode
}

export default function ProtectedRoute({ user, permissionType, children }: ProtectedRouteProps)
{
    if (user.permissions.length > 0 && !PermissionHelper.hasPermission(user, permissionType)) {
        return (
            <Navigate replace to={LocationTypes.HOME} />
        );
    } else {
        return <>{children}</>;
    }
}
