import ClosableModal from './../../../../common/ClosableModal';
import EmployeeSortHelper from '../../../../helpers/employeeSortHelper';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import StringHelper from '../../../../helpers/stringHelper';

import {StoreEmployee, StoreSettings, StoreVisibleUser} from '../../../../typescript/storeTypes';

class ChangeUserModal extends React.Component<Props, State> {
    state: State = {
        searchString: ''
    };

    searchInput: HTMLInputElement | undefined;

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowDown') {
            this.nextSelected();
            return;
        }
        if (event.key === 'ArrowUp') {
            this.previousSelected();
            return;
        }
        if (event.key === 'Enter') {
            const selected = document.getElementsByClassName('changeUserSelected').item(0) as HTMLElement;
            if (selected) selected.click();
            return;
        }
    };

    nextSelected = () => {
        let newItem;
        const selected = document.getElementsByClassName('changeUserSelected').item(0);

        if (selected) {
            selected.classList.remove('changeUserSelected');
            newItem = selected.nextElementSibling;
        } else {
            const list = ((document.getElementsByClassName('change-user-scrollbar').item(0) as HTMLElement).children.item(0) as HTMLElement).children.item(0) as HTMLElement;
            newItem = list.children.item(0);
        }

        if (!newItem) return;
        newItem.classList.add('changeUserSelected');
        newItem.scrollIntoView();
        return newItem;
    };

    previousSelected = () => {
        const selected = document.getElementsByClassName('changeUserSelected').item(0);

        if (selected) {
            selected.classList.remove('changeUserSelected');
            const newItem = selected.previousElementSibling;
            if (newItem) {
                newItem.classList.add('changeUserSelected');
                newItem.scrollIntoView();
            }
        }
    };

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        this.setState({searchString: event.currentTarget.value});
    };

    getVisibleUsersWithoutDoubles = (): StoreVisibleUser[] => {
        const list: StoreVisibleUser[] = [];
        const sortedVisibleUsers = this.props.visibleUsers.slice().sort((vu1, vu2) => {
            if(vu1.isCurrentActivePayroll && vu2.isCurrentActivePayroll) return 0;
            if(vu1.isCurrentActivePayroll && !vu2.isCurrentActivePayroll) return -1;
            return 1;
        });

        for(const user of sortedVisibleUsers) {
            if(list.length > 0 && list.some(e=>e.id === user.id)) continue;

            list.push(user);
        }

        return list;
    };

    componentDidMount() {
        if(this.searchInput) {
            this.searchInput.focus();
        }
    }

    render() {
        return (
            <ClosableModal title="CHANGE USER" closeModal={this.props.closeModal}>
                <input ref={(searchInput: HTMLInputElement) => this.searchInput = searchInput} className="change-user-input" type="text"
                       onChange={this.onChange}
                       onKeyDown={this.handleKeyPress}/>
                <div className="change-user-list">
                    <div className="change-user-list-item myself"
                         onClick={() => this.props.changeUser(this.props.me)}>
                        <div className="change-user-avatar">
                            <div className="no-select change-user-avatarFilling">
                                {StringHelper.getEmployeeAvatarText(this.props.me, this.props.settings.firstNameLastName)}
                            </div>
                        </div>
                        <div className="no-select change-user-username">
                            {StringHelper.getEmployeeFullName(this.props.me, this.props.settings.firstNameLastName, false, false)}
                        </div>
                    </div>
                    <div className="change-user-scrollbar">
                        <ScrollBar>
                            {EmployeeSortHelper.findMatch(
                                    this.state.searchString,
                                    this.props.me.username,
                                    this.getVisibleUsersWithoutDoubles(),
                                    (this.props.settings.firstNameLastName ? 'firstname' : 'lastname')
                                ).map((emp: StoreEmployee) => {
                                return (
                                    <div key={`${emp.username}${emp.employer}`}
                                         className="change-user-list-item"
                                         onClick={() => this.props.changeUser(emp)}>
                                        <div className="change-user-avatar">
                                            <div className="no-select change-user-avatarFilling">
                                                {StringHelper.getEmployeeAvatarText(emp, this.props.settings.firstNameLastName)}
                                            </div>
                                        </div>
                                        <div className="no-select change-user-username">
                                            {StringHelper.getEmployeeFullName(emp, this.props.settings.firstNameLastName, false, true)}
                                        </div>
                                    </div>);
                            })}
                        </ScrollBar>
                    </div>
                </div>
            </ClosableModal>
        );
    }
}

type Props = {
    me: StoreEmployee,
    settings: StoreSettings,

    closeModal: () => void,
    visibleUsers: StoreVisibleUser[],
    changeUser: (user: StoreEmployee) => void
};

type State = {
    searchString: string
};

export default ChangeUserModal;
