import { Interval } from '../../../../types/enumTypes';
import {Job} from 'node-schedule';
import ApiHelper from '../../../../helpers/apiHelper';
import CronHelper from '../../../../helpers/cronHelper';
import React from 'react';
import TextAreaInput from '../../../../common/TextAreaInput';

import HourHelper from '../../../../helpers/hourHelper';
import ObjectHelper from '../../../../helpers/objectHelper';
import WindowHelper from '../../../../helpers/windowHelper';

let cronJob: Job | undefined;
class TimesheetCodeDayAddHoursComment extends React.PureComponent<Props, State> {
    cancelDiv: HTMLDivElement | undefined;

    state: State = {
        hours: this.props.getHours(),
        comments: this.props.comments,
        showAutoSaveMessage: false,
        isInViewPort: true
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> & { currentTarget: HTMLInputElement }): void => {
        if (event.currentTarget.name === 'comments') {
            this.setState({comments: event.currentTarget.value});
        } else {
            if (event.currentTarget.value.length === 1 && ObjectHelper.charAt(event.currentTarget.value,0) === ',' ||  ObjectHelper.charAt(event.currentTarget.value,0) === '.') {
                event.currentTarget.value = '0.';
            }

            if(HourHelper.isValidHour(event.currentTarget.value)) {
                event.currentTarget.value = HourHelper.getHoursInNumberFormat(event.currentTarget.value);
                this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);
            }
        }
    };

    onBlur = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        if (event.currentTarget.value.endsWith('.')) {
            event.currentTarget.value = event.currentTarget.value.slice(0, -1);
            this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);
        }
    };

    onSubmit = (event?: React.MouseEvent<HTMLInputElement, MouseEvent>, isAutoSave = false): void => {
        event && event.preventDefault();

        this.props.onSubmit(this.state.hours, this.state.comments);
        if(!isAutoSave)
        {
            this.props.resetToDefaultEntry();
        }
    };

    componentDidMount() {
        cronJob = CronHelper.createNewJob(Interval.EveryMinute, () => {
            if(this.state.hours === '0' && this.commentsEmpty()) return;
            if(!this.cantSend())
            {
                this.onSubmit(undefined, true);
                this.setState({showAutoSaveMessage: true});
            }
        });

        if(this.state.isInViewPort !== WindowHelper.isElementInViewport(this.cancelDiv)) {
            // because it needs to redraw if it notices that the button is not in the right place
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({isInViewPort: WindowHelper.isElementInViewport(this.cancelDiv)});
        }
    }

    componentWillUnmount() {
        CronHelper.stopCronJob(cronJob);
        cronJob = undefined;
    }

    componentDidUpdate(): void {
        if(this.state.showAutoSaveMessage) {
            ApiHelper.debounce(() => this.setState({showAutoSaveMessage: false}), 4000)();
        }
    }

    hoursEmpty = (): boolean => this.state.hours === undefined || this.state.hours === '';
    commentsEmpty = (): boolean => {
        if(this.state.comments === undefined || this.state.comments === null) return true;
        const com = this.state.comments.trim();
        return com === '';
    };

    cantSend = ():boolean => {
        if(this.hoursEmpty() && this.commentsEmpty()) return true;

        if(this.props.commentMandatory) {
            if(this.hoursEmpty()) return true;

            return this.commentsEmpty() && this.state.hours !== '0';
        }

        return !this.commentsEmpty() && this.hoursEmpty();
    };

    getErrorMessage = (): string => {
        if(this.props.commentMandatory) {
            if(!this.hoursEmpty() && this.commentsEmpty() && this.state.hours !== '0') return 'Comments are mandatory.';
        }

        if(this.hoursEmpty() && !this.commentsEmpty()) return 'A comment without hours can\'t be saved. Use 0 when saving comments only.';

        return '';
    };

    render() {
        return (
            <div className={'timesheet-code-day-comment-mandatory' + (this.state.isInViewPort === true ? '' : ' inverted') + (this.props.level === 1 ? '' : ` level${this.props.level}`)}>
                <form>
                    <i className="fas fa-times" aria-hidden="true" onClick={this.props.resetToDefaultEntry}/>
                    {this.state.hours === '0' ?
                        <div className="random-info">
                            Info: sending 0 hours without a comment will delete the existing entry.
                        </div> : <div className="comments-autosave-placeholder"/>
                    }
                    <div className="form-group">
                        <input name="hours" id="hours"
                               className="form-control"
                               value={this.state.hours}
                               onChange={this.onChange}
                               onBlur={this.onBlur}
                               autoFocus/>
                        <label htmlFor="hours">
                            hours/day
                        </label>
                    </div>
                    <TextAreaInput name="comments"
                                   height="100%"
                                   label="Add a comment"
                                   labelExtra="(the client can also see this)"
                                   value={this.state.comments}
                                   onChange={this.onChange}
                                   error={this.getErrorMessage()}/>
                    {this.state.showAutoSaveMessage ?
                        <div className="comments-autosave">
                            Hours and comments have been autosaved
                        </div> : <div className="comments-autosave-placeholder"/> }
                    <div className="clear-fix">
                        <input id="submit" name="submitHours" type="submit" value="Done" onClick={this.onSubmit} disabled={this.cantSend()} />
                        <div ref={(div: HTMLDivElement) => this.cancelDiv = div} id="cancel" role="submit" onClick={this.props.resetToDefaultEntry}>Cancel</div>
                    </div>
                </form>
            </div>
        );
    }
}

type Props = {
    comments?: string | undefined,
    level: number,
    commentMandatory: boolean,

    getHours: () => string,
    onSubmit: (hours: string, comments: string | undefined) => void,
    resetToDefaultEntry: () => void
};

type State = {
    hours: string,
    comments: string | undefined,
    showAutoSaveMessage: boolean,
    isInViewPort: boolean
};

export default TimesheetCodeDayAddHoursComment;
