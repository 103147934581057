import DateHelper from '../../../../helpers/dateHelper';
import DropDown from '../../common/DropDown';
import ExportMonthlyTimesheetModal from './ExportMonthlyTimesheetModal';
import ExportWeeklyTimesheetModal from './ExportWeeklyTimesheetModal';
import React from 'react';

import { DropDownAction } from '../../../../typescript/menuTypes';
import {StoreSettings, StoreTimesheet} from '../../../../typescript/storeTypes';

class ExportMenu extends React.PureComponent<Props, State> {
    state: State = {
        isExportMonthlyOpen: false,
        isExportWeeklyOpen: false
    };

    hasEntries = (): boolean => {
        return this.props.timesheet.timesheetCodes
                    .filter(tsCode => tsCode.entries.length > 0).length > 0;
    };

    openExportMonthly = () => {
        this.setState({
            isExportMonthlyOpen: true,
            isExportWeeklyOpen: false
        });
    };

    openExportWeekly = () => {
        this.setState({
            isExportMonthlyOpen: false,
            isExportWeeklyOpen: true
        });
    };

    getActions = (): DropDownAction[] => {
        const actions: DropDownAction[] = [];
        
        if(this.props.settings.monthViewSelected) {
            actions.push({title: 'MONTHLY', action: this.openExportMonthly});
        }

        actions.push({title: 'WEEKLY', action: this.openExportWeekly});

        return actions;
    };

    render() {
        return (
            <div className="export-menu">
                <DropDown mainButtonName="DOWNLOAD"
                        disabledCondition={() => !this.hasEntries()}
                        actions={this.getActions()}/>
                    {this.state.isExportMonthlyOpen ?
                        <ExportMonthlyTimesheetModal
                            period={this.props.timesheet.period}
                            timesheetCodes={this.props.timesheet.timesheetCodes}
                            closeModal={() => this.setState({isExportMonthlyOpen: false})} />
                            : null
                    }
                    {this.state.isExportWeeklyOpen ?
                        <ExportWeeklyTimesheetModal
                            period={this.props.timesheet.period}
                            timesheetCodes={this.props.timesheet.timesheetCodes}
                            weekNumber={!this.props.settings.monthViewSelected ?
                                DateHelper.getWeekNumber(DateHelper.getNormalizedDate(new Date(this.props.timesheet.period.days[0].dateString)))
                                : undefined}
                            closeModal={() => this.setState({isExportWeeklyOpen: false})} />
                            : null
                    }
            </div>
        );
    }
}

type State = {
    isExportMonthlyOpen: boolean,
    isExportWeeklyOpen: boolean
};

type Props = {
    timesheet: StoreTimesheet,
    settings: StoreSettings
};

export default ExportMenu;
