import { PermissionTypes } from '../../../../types/permissionTypes';
import ContractHoursToggle from '../../../../common/ContractHoursToggle';
import DateHelper from '../../../../helpers/dateHelper';
import MonthYearPicker from '../../common/MonthYearPicker';
import PermissionHelper from '../../../../helpers/permissionHelper';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import Select from 'react-select';
import StringHelper from '../../../../helpers/stringHelper';
import TextInput from '../../../../common/TextInput';

import {
    StoreSettings,
    StoreSupplierEmployee,
    StoreUser,
    StoreVisibleSupplier
} from '../../../../typescript/storeTypes';

import {Action} from '../../../../typescript/actionTypes';
import {SelectedOptionInfo} from '../../../../typescript/infoTypes';
import {SuppliersEmployeesType} from '../../../../typescript/supplierTypes';
import { TimesheetCompleteStatus } from '../../../../types/enumTypes';
import {TimesheetsLandscapeReportType} from '../../../../typescript/reportTypes';


class TimesheetsLandscapeReport extends React.Component<Props, State> {
    date = new Date();
    defaultOption: SelectedOptionInfo = {value: 'all', label: 'All'};

    state: State = {
        month: this.date.getMonth() + 1,
        year: this.date.getFullYear(),
        selectedSupplier: null,
        selectedSupplierEmployees: [],
        selectedOption: this.defaultOption,
        supplierFilter: ''
    };

    exportPdf = (): void => {
        if(!this.canExport()) return;

        const data = this.getExportData();
        this.props.exportTimesheetsLandscapePdf(data);
    };

    exportExcel = (): void => {
        if(!this.canExport()) return;

        const data = this.getExportData();
        this.props.exportTimesheetsLandscapeExcel(data);
    };

    getExportData = (): TimesheetsLandscapeReportType => {
        return {
            userIds: this.state.selectedSupplierEmployees,
            month: this.state.month,
            year: this.state.year,
            hoursPerWeek: this.props.settings.defaultContractHours
        };
    };

    canExport = (): boolean => {
        if (this.props.reportRequested || this.state.selectedSupplier === null || this.state.selectedSupplierEmployees.length === 0) return false;
        return true;
    };

    handleAMonthChange = (year: number, month: number) => {
        this.setState({year: year, month:month});

        if (this.props.suppliersEmployees.length > 0 && this.state.selectedSupplier) {
            this.props.getSuppliersEmployees({
                supplierId: this.state.selectedSupplier.id,
                month: month,
                year: year
            });
        }
    };

    selectSupplier = (visibleSupplier: StoreVisibleSupplier): void => {
        if (this.state.selectedSupplier && this.state.selectedSupplier.id === visibleSupplier.id) {
            this.setState({selectedSupplier: null, selectedSupplierEmployees:[]});
            this.props.clearSuppliersEmployees();
            return;
        }

        this.setState({selectedSupplier: visibleSupplier, selectedSupplierEmployees:[]});
        this.props.getSuppliersEmployees({
            supplierId: visibleSupplier.id,
            month: this.state.month,
            year: this.state.year
        });
    };

    selectSupplierEmployee = (suppliersEmployee: StoreSupplierEmployee): void => {
        const activeIndex = this.state.selectedSupplierEmployees.findIndex(sel => suppliersEmployee.id === sel);
        if(activeIndex !== -1) {
            this.state.selectedSupplierEmployees.splice(activeIndex, 1);
            this.setState({selectedSupplierEmployees: this.state.selectedSupplierEmployees});
        } else {
            const newList = this.state.selectedSupplierEmployees.slice();
            newList.push(suppliersEmployee.id);
            this.setState({selectedSupplierEmployees: newList});
        }
    };

    selectAll = (): void => {
        if(this.allSelected()) {
            this.setState({selectedSupplierEmployees: []});
            return;
        }

        const items = this.getFilteredByCompleted();

        const list = items.slice().map(se => se.id);
        this.setState({selectedSupplierEmployees: list});
    };

    getFilteredByCompleted = (): StoreSupplierEmployee[] => {
        if (this.state.selectedOption && this.state.selectedOption === this.defaultOption) return this.props.suppliersEmployees;
        if (this.state.selectedOption.value === 'completed') {
            return this.props.suppliersEmployees.filter(it => it.timesheetCompleted === TimesheetCompleteStatus.Complete || it.timesheetCompleted === TimesheetCompleteStatus.PartiallyComplete);
        } else {
            return this.props.suppliersEmployees.filter(it => it.timesheetCompleted === TimesheetCompleteStatus.Incomplete);
        }
    };

    allSelected  = (): boolean => {
        return this.state.selectedSupplierEmployees.length === this.getFilteredByCompleted().length;
    };

    addSuppliers = () => {
        return this.getSortedSuppliers().filter(v => !this.state.supplierFilter || v.name.toLowerCase().indexOf(this.state.supplierFilter.toLowerCase()) !== -1).map(vs =>
            <div onClick={() => this.selectSupplier(vs)}
                 className={'no-select suggestion' + (this.state.selectedSupplier && this.state.selectedSupplier.id === vs.id ? ' selected' : '')}
                 key={vs.id}>{vs.name}</div>);
    };

    sortSupplierEmployees = (employees: StoreSupplierEmployee[], sortOrder: string): StoreSupplierEmployee[] => {
        return employees.sort((a, b) => {
            let nameA;
            let nameB;
            if(sortOrder.toLowerCase() === 'lastname') {
                nameA = a.lastName.toLowerCase();
                nameB = b.lastName.toLowerCase();
            } else {
                nameA = a.firstName.toLowerCase();
                nameB = b.firstName.toLowerCase();
            }

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
        });
    };

    addSupplierEmployees = (): React.ReactElement[] => {
        const filteredByCompleted = this.getFilteredByCompleted();
        const items = filteredByCompleted.slice();
        const sortedItems = this.sortSupplierEmployees(items, this.props.settings.firstNameLastName ? 'firstname' : 'lastname');

        return sortedItems.map(se =>
            <div onClick={() => this.selectSupplierEmployee(se)}
                 className={'suggestion' + (this.state.selectedSupplierEmployees.findIndex(e => e === se.id) !== -1 ? ' selected' : '')}
                 key={se.id}>{StringHelper.getEmployeeFullName(se, this.props.settings.firstNameLastName, false, false)} ({se.timesheetCompleted})</div>);
    };

    onCompleteChange = (selectedOption: SelectedOptionInfo) => {
        if(this.state.selectedOption === selectedOption) return;
        this.setState({ selectedOption, selectedSupplierEmployees: [] });
    };

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        this.setState({supplierFilter: event.currentTarget.value});
    };

    getSortedSuppliers = (): StoreVisibleSupplier[] => {
        return this.props.user.visibleSuppliers.slice().sort((sup1: StoreVisibleSupplier, sup2: StoreVisibleSupplier) => {
            if(sup1.name.toLowerCase() > sup2.name.toLowerCase()) return 1;
            if(sup1.name.toLowerCase() < sup2.name.toLowerCase()) return -1;
            return 0;
        });
    };

    componentDidUpdate(prevProps: Props): void {
        if(this.props.user.permissions !== prevProps.user.permissions){
            if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_VISIBLE_SUPPLIERS) && this.props.user.visibleSuppliers.length < 1) {
                this.props.getVisibleSuppliers();
            }
        }
    }

    componentDidMount() {
        if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_VISIBLE_SUPPLIERS) && this.props.user.visibleSuppliers.length < 1){
            this.props.getVisibleSuppliers();
        }
    }

    componentWillUnmount() {
        this.setState({
            month: this.date.getMonth() + 1,
            year: this.date.getFullYear(),
            selectedSupplier: null,
            selectedSupplierEmployees: [],
            selectedOption: this.defaultOption,
        });
        this.props.clearSuppliersEmployees();
    }

    render() {
        const options = [
                            this.defaultOption,
                            {value:'completed', label:'Complete'},
                            {value:'incomplete', label:'Incomplete'},
                        ];

        return (
            <div className="timesheets-landscape-report">
                <div className="timesheets-information">
                    <div className="month-picker-box-container info-container">
                        <label>Period</label>
                        <MonthYearPicker
                            minDate={new Date(2000, 0, 1)}
                            maxDate={DateHelper.addMonths(new Date(), 12)}
                            onChange={(month: number, year: number) => this.handleAMonthChange(year, month)}
                        />
                    </div>
                    <div className="info-container">
                        <label>Hours per week</label>
                        <ContractHoursToggle />
                    </div>
                    <div className="info-container">
                        <label>Completed</label>
                        <Select name="complete-select" classNamePrefix="Select"
                                value={options.find(({label}) => label === this.state.selectedOption.label)}
                                isClearable={false}
                                isSearchable={false}
                                onChange={(opt) => this.onCompleteChange(opt as SelectedOptionInfo)}
                                options={options}/>
                    </div>
                    <div className="timesheets-buttons">
                        <input type="submit" className="export-button" disabled={!this.canExport()} onClick={this.exportPdf} value="Export as PDF"/>
                        <input type="submit" className="export-button" disabled={!this.canExport()} onClick={this.exportExcel} value="Export as Excel"/>
                    </div>
                    {this.props.reportRequested ?
                    <div className="export-spinner">
                        <i className="fas fa-spinner fa-spin" />
                    </div> : undefined}
                </div>
                <div className="landscape-selection">
                    <div className="landscape-selection-box items-box">
                        <div className="extra-options-box">
                            <label className="landscape-selection-box-title">Companies</label>
                            <TextInput id="searchSuggestions"
                                name="searchSuggestions"
                                placeholder=""
                                onChange={(event: React.SyntheticEvent<HTMLInputElement> & {currentTarget: HTMLInputElement}) => this.onChange(event)}/>
                        </div>
                        <div className="suggestedList">
                        {
                            <ScrollBar>
                                {this.addSuppliers()}
                            </ScrollBar>
                        }
                        </div>
                    </div>
                    <div className={'landscape-selection-box' + (this.props.suppliersEmployees.length > 0 ? ' items-box' : '')}>
                        <div className="extra-options-box">
                            <label className="landscape-selection-box-title">Users</label>
                            {this.props.suppliersEmployees.length > 0 ?
                            <div className="button" onClick={this.selectAll}>{this.allSelected() ? 'Deselect all' : 'select all'}</div> :
                            null}
                        </div>
                        <div className="suggestedList">
                        {
                            <ScrollBar>
                                { this.addSupplierEmployees() }
                            </ScrollBar>
                        }
                        </div>
                    </div>
                    <div className="landscape-selection-empty-box" />
                </div>
            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    suppliersEmployees: StoreSupplierEmployee[],
    reportRequested: boolean,
    settings: StoreSettings,

    getSuppliersEmployees: (data: SuppliersEmployeesType) => Action,
    getVisibleSuppliers: () => Action,
    clearSuppliersEmployees: () => Action,
    exportTimesheetsLandscapePdf: (data: TimesheetsLandscapeReportType) => Action,
    exportTimesheetsLandscapeExcel: (data: TimesheetsLandscapeReportType) => Action
};

type State = {
    year: number,
    month: number,
    selectedSupplier: StoreVisibleSupplier | null,
    selectedSupplierEmployees: number[],
    selectedOption: SelectedOptionInfo,
    supplierFilter: string
};

export default TimesheetsLandscapeReport;
