import React from 'react';

import { StoreApprover } from '../../../../../typescript/storeTypes';

class Approver extends React.PureComponent<Props> {
    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
        const approver = Object.assign({}, this.props.approver);
        (approver as {[key: string]: any})[event.currentTarget.name] = event.currentTarget.value;

        this.props.updateApprover(approver);
    };

    render() {
        return (
            <div className="form-group approver">
                <div className="input-emailaddress" data-required={this.props.emailError}>
                    <input data-required={this.props.emailError} name="email" type="text" placeholder="Eg. someone@example.com" value={this.props.approver.email} onChange={this.onChange} />
                </div>
                <div className="input-name" data-required={this.props.nameError}>
                    <input data-required={this.props.nameError} name="name" type="text" placeholder="Eg. Someone" value={this.props.approver.name} onChange={this.onChange} />
                </div>
                <i className="fas fa-times" aria-hidden="true" onClick={this.props.deleteApprover}/>
            </div>
        );
    }
}

type Props = {
    approver: StoreApprover,
    updateApprover: (approver: StoreApprover) => void,
    deleteApprover: () => void,
    emailError: string,
    nameError: string
};

export default Approver;
