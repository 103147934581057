import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';

import {Period} from '../../../../typescript/dateTypes';
import {StoreSettings} from '../../../../typescript/storeTypes';

class MonthWeekNavigation extends React.PureComponent<Props> {
    switchMonth = () => {
        if (!this.props.settings.monthViewSelected) {
            if(DateHelper.periodContainsFirstOfMonth(this.props.period)) {
                const day = this.props.period.days.find(e => e.dayOfMonth === 1);
                if(day) {
                    const firstOfMonth = new Date(day.dateString);
                    this.props.getTimesheet(firstOfMonth, true);
                } else {
                    this.props.getTimesheet(this.props.period.startDate, true);
                }
            } else {
                this.props.getTimesheet(this.props.period.startDate, true);
            }
        }
    };

    switchWeek = () => {
        if (this.props.settings.monthViewSelected) {
            if (this.props.settings.hideWeekends) {
                const firstWeekday = DateHelper.getFirstWeekday(this.props.period.days);
                this.props.getTimesheet(new Date(firstWeekday.dateString), false);
            } else {
                this.props.getTimesheet(this.props.period.startDate, false);
            }
        }
    };

    render() {
        return (
            <div className="month-week-container">
                <div className="month-week-month-block">
                    <div
                        className={'month-week-month-selector no-select' + (this.props.settings.monthViewSelected ? ' selected' : ' not-selected')}
                        onClick={this.switchMonth}>
                        MONTH
                    </div>
                </div>
                <div className="month-week-week-block">
                    <div
                        className={'month-week-week-selector no-select' + (this.props.settings.monthViewSelected ? ' not-selected' : ' selected')}
                        onClick={this.switchWeek}>
                        WEEK
                    </div>
                </div>
            </div>
        );
    }
}

type Props = {
    settings: StoreSettings,
    period: Period,

    getTimesheet: (startDate: Date, monthViewSelected?: boolean) => void
};

export default MonthWeekNavigation;
