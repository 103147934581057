import {ActionTypes} from '../types/actionTypes';
import { base64ToBlob } from 'file64';
import {saveAs} from 'file-saver';

import {Action} from '../typescript/actionTypes';
import {StoreEmptyObject} from '../typescript/storeTypes';

export default function (state: StoreEmptyObject = {}, action: Action): StoreEmptyObject {
    switch (action.type) {
        case ActionTypes.GET_ASSIGNMENTDOCUMENT_SUCCEEDED: {
            base64ToBlob(action.payload.base64).then(r => saveAs(r, action.payload.fileName));
            return state;
        }
        default:
            return state;
    }
}
