import './App.styling';
import { BrowserRouter } from 'react-router-dom';
import Content from './content/Content';
import CookieConsent from '../../common/CookieConsent';
import ErrorBoundary from '../../common/ErrorBoundary';
import Header from './header/Header';
import MobileRedirect from '../../common/MobileRedirect';
import React from 'react';

class App extends React.Component {
    render() {
        return (
            <ErrorBoundary>
                <BrowserRouter>
                    <div>
                        {process.env.BUILD_CONFIG !== 'LOCAL' ?
                            <MobileRedirect/> : null}
                        <Header/>
                        <Content/>
                        {process.env.BUILD_CONFIG !== 'LOCAL' ?
                            <CookieConsent/> : null}
                    </div>
                </BrowserRouter>
            </ErrorBoundary>
        );
    }
}

export default App;
