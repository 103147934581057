import React from 'react';

class Version extends React.PureComponent {
    render() {
        return (
            <div className="build-info">
                {process.env.BUILD_CONFIG} {process.env.BUILD_VERSION}
            </div>
        );
    }
}

export default Version;
