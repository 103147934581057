import { Period } from '../../typescript/dateTypes';
import { StoreTimesheet } from '../../typescript/storeTypes';
import {createSelector} from 'reselect';
import TimesheetHelper from '../../helpers/timesheetHelper';

const getTimesheet = (state: State) => state.timesheet;
const getPeriod = (state: State, props: Props) => props.period;

interface State {
    timesheet: StoreTimesheet
}

interface Props {
    period: Period
}

const getAddableTimesheetCodes = createSelector(
    [getTimesheet, getPeriod],
    (timesheet, period) => {
        const addableTimesheetCodes = [];

        for (const timesheetCode of timesheet.timesheetCodes) {
            if (!timesheetCode.isReadOnly &&
                !TimesheetHelper.isTimesheetCodeCompletedEntries(timesheetCode, period)) {
                addableTimesheetCodes.push(timesheetCode);
            }
        }
        return addableTimesheetCodes;
    }
);

export default getAddableTimesheetCodes;
