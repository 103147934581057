import {StoreSettings, StoreUser} from '../../../../typescript/storeTypes';
import ClosableModal from '../../../../common/ClosableModal';
import React from 'react';
import Toggle from '../../../../common/Toggle';

class SettingsModal extends React.Component<Props> {
    onCommentsToggle = (value: any): void => {
        this.props.updateSetting('showCommentTooltips', value === 'show');
    };

    onNamingToggle = (value: any): void => {
        this.props.updateSetting('firstNameLastName', value === 'firstLast');
    };

    onTimesheetCodeToggle = (value: any): void => {
        this.props.updateSetting('timesheetCodeOrder', value === 'alphabetical');
    };

    render() {
        return (
            <ClosableModal className="settings-modal" title="SETTINGS" closeModal={this.props.closeModal}>
                <div className="settings-item">
                    <div className="item-title">Show comments on</div>
                    <Toggle value1="dontshow" displayValue1="Double click"
                            value2="show" displayValue2="Hover"
                            currentValue={(this.props.settings.showCommentTooltips ? 'show' : 'dontshow')}
                            onChange={this.onCommentsToggle}/>
                </div>
                <div className="settings-item">
                    <div className="item-title">Naming style</div>
                    <Toggle value1="firstLast" displayValue1={`${this.props.user.impersonatedUser.firstName} ${this.props.user.impersonatedUser.lastName}`}
                            value2="lastFirst" displayValue2={`${this.props.user.impersonatedUser.lastName} ${this.props.user.impersonatedUser.firstName}`}
                            currentValue={(this.props.settings.firstNameLastName ? 'firstLast' : 'lastFirst')}
                            onChange={this.onNamingToggle}/>
                </div>
                <div className="settings-item">
                    <div className="item-title">Timesheet code order</div>
                    <Toggle value1="alphabetical" displayValue1="Alphabetical"
                            value2="lastUsed" displayValue2="Last Used"
                            currentValue={(this.props.settings.timesheetCodeOrder ? 'alphabetical' : 'lastUsed')}
                            onChange={this.onTimesheetCodeToggle}/>
                </div>
            </ClosableModal>
        );
    }
}

type Props = {
    closeModal: () => void,
    updateSetting(name: string, value: any): void,
    user: StoreUser,
    settings: StoreSettings
};

export default SettingsModal;
