import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

export interface WithRouterProps {
    location: ReturnType<typeof useLocation>,
    navigate: ReturnType<typeof useNavigate>
}
const mapStateToProps = (props: WithRouterProps) => (
    {
        location: props.location,
        navigate: props.navigate
    }
);

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type MappedProps = ConnectedProps<typeof connector>;

export function withRouter<CProps extends { router: MappedProps}>(Component: React.ComponentType<MappedProps>) {
    function ComponentWithRouterProp(props: Omit<CProps, 'router'>) {
        const location = useLocation();
        const navigate = useNavigate();
        return <Component {...(props as CProps)} location={location} navigate={navigate} />;
    }

    return ComponentWithRouterProp;
}
