import {AnyAction, Dispatch,bindActionCreators} from 'redux';

import {ConnectedProps, connect} from 'react-redux';
import {InitialState} from '../../../../reducers/initialState';
import {updateSetting} from '../../../../actions/settingsActions';
import React from 'react';

import { LocationTypes } from '../../../../types/locationTypes';
import {StoreState} from '../../../../typescript/storeTypes';
import { WithRouterProps, withRouter } from '../../../../common/WithRouter';

export class Weekend extends React.Component<Props> {
    changeHideStatus = () => {
        this.props.updateSetting('hideWeekends', !this.props.hideWeekends);
    };

    render() {
        return (this.props.user.impersonatedUser !== undefined && this.props.user.impersonatedUser.id !== InitialState.user.impersonatedUser.id && this.props.location.pathname === LocationTypes.HOME ?
            <div className="weekend-box">
                <div className="custom-checkbox no-select"
                     onClick={this.changeHideStatus}>
                    {(this.props.hideWeekends ? '\u2713' : '')}
                </div>
                <label className="no-select" onClick={this.changeHideStatus}>Hide weekends</label>
            </div> : null);
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState, props: WithRouterProps) {
    return {
        user: state.user,
        hideWeekends: state.settings.hideWeekends,
        location: props.location // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        updateSetting
    }, dispatch);
}

export default withRouter(connector(Weekend));
