import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';

export function getPresets(): Action {
    return {type: ActionTypes.GET_PRESETS_REQUESTED, payload: null};
}

export function loadPreset(location: string, name: string, data: any): Action {
    return {type: ActionTypes.LOAD_PRESET_REQUESTED, payload: {
        location: location,
        name: name,
        data: data
    }};
}

export function savePreset(location: string, name: string, data: any): Action {
    return {type: ActionTypes.SAVE_PRESET_REQUESTED, payload: {
        location: location,
        name: name,
        data: data
    }};
}

export function deletePreset(location: string, name: string): Action {
    return {type: ActionTypes.DELETE_PRESET_REQUESTED, payload: {
        location: location,
        name: name
    }};
}

export function clearActivePreset(): Action {
    return {type: ActionTypes.CLEAR_ACTIVE_PRESET_REQUESTED, payload: null};
}
