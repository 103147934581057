import {AnyAction, Dispatch, bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import {clearSuppliers, getSuppliers} from '../../../../actions/supplierActions';
import {exportWeeklyTimesheet, exportWeeklyTimesheetExcel} from '../../../../actions/timesheetActions';
import {updateSetting} from '../../../../actions/settingsActions';
import ApiHelper from '../../../../helpers/apiHelper';
import Autosuggest, {GetSuggestionValue, InputProps} from 'react-autosuggest';
import ClosableModal from './../../../../common/ClosableModal';
import ContractHoursToggle from './../../../../common/ContractHoursToggle';
import DateHelper from '../../../../helpers/dateHelper';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import TextInput from './../../../../common/TextInput';
import UserHelper from '../../../../helpers/userHelper';

import {Period, Week} from '../../../../typescript/dateTypes';
import { PropertyNameIndexer } from '../../../../typescript/customTypes';
import {     StoreState,
             StoreSupplier,
             StoreTimesheetCode,
             StoreVisibleSupplier} from '../../../../typescript/storeTypes';

import {WeeklyTimesheetToExport} from '../../../../typescript/timesheetTypes';

export class ExportWeeklyTimesheetModal extends React.Component<Props & MappedProps, State> {
    hasWeekNumber = (): boolean => !!this.props.weekNumber;
    isReadOnly = (): boolean => this.hasWeekNumber() && this.props.timesheetCodes.length === 1;

    state: State = {
        ownProjectLeader: '',
        clientProjectLeader: '',
        timesheetCodes: [],
        selectedSupplierValue: UserHelper.getDefaultCompanyNameForTemplate(this.props.user, this.props.settings),
        selectedSupplier: UserHelper.getDefaultCompanyForTemplate(this.props.user, this.props.settings),
        selectedWeek: this.hasWeekNumber() ? this.props.period.weeks.filter(week => week.weekNumber === this.props.weekNumber)[0] : null,
        downloadOpen: false,
        selectedTimesheetCodes: this.isReadOnly() ? [this.props.timesheetCodes[0]] : [],
        selectAllChecked: this.isReadOnly() ? true : false,
        hideComments: false,
        errors: {},
        supplierFilterValue: ''
    };

    pdfButton: HTMLInputElement | undefined;
    excelButton: HTMLInputElement | undefined;
    downloadButton: HTMLDivElement | undefined;
    downloadDiv: HTMLDivElement | undefined;
    downloadCaret: HTMLDivElement | undefined;

    getSuppliers: (value: string) => void = ApiHelper.debounce(this.props.getSuppliers, 500);
    onChange = (event: React.FormEvent<HTMLElement>, {newValue}: { newValue: StoreSupplier | string }) => {
        if (!(newValue instanceof Object)) {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({
                selectedSupplierValue: target.value,
                selectedSupplier: null
            });
        }
        else {
            if(!UserHelper.isImpersonating(this.props.user)) {
                this.props.updateSetting('defaultCompanyForTemplate', {id: newValue.id, name: newValue.name});
            }
            this.setState({selectedSupplierValue: newValue.name, selectedSupplier: newValue} as any);
        }
    };

    hasEntries = (timesheetCode: StoreTimesheetCode): boolean => {
        const week = this.state.selectedWeek;
        if (!week) {
            return false;
        }

        return timesheetCode.entries.filter(entry => {
            const date = DateHelper.getNormalizedDate(new Date(entry.date));

            return date.valueOf() >= week.startDate.valueOf() && date.valueOf() <= week.endDate.valueOf();}).length > 0;
    };

    isWeekSelected = (week: Week): boolean => {
        if (!this.state.selectedWeek) {
                return false;
        }
        return this.state.selectedWeek.weekNumber === week.weekNumber;
    };

    onWeekClick = (week: Week): void => {
        if (this.hasWeekNumber()) {
            return;
        }
        if(this.state.selectedWeek && this.state.selectedWeek.weekNumber === week.weekNumber) {
            this.setState({selectedWeek: null, selectedTimesheetCodes: [], selectAllChecked: false});
        }
        else {
            this.setState({selectedWeek: week, selectedTimesheetCodes: [], selectAllChecked: false});
        }
    };

    isTimesheetCodeSelected = (timesheetCode: StoreTimesheetCode): boolean => {
        if (this.state.selectedTimesheetCodes.length === 0) {
                return false;
        }

        return this.state.selectedTimesheetCodes.find(t => t.id === timesheetCode.id) !== undefined;
    };

    onTimesheetCodeClick = (timesheetCode: StoreTimesheetCode): void => {
        if (this.isReadOnly()) {
            return;
        }

        const selectedTimesheetCodes = this.state.selectedTimesheetCodes.slice();
        if (selectedTimesheetCodes.find(tsCode => tsCode.id === timesheetCode.id)) {
            const index = selectedTimesheetCodes.indexOf(timesheetCode);
            selectedTimesheetCodes.splice(index, 1);
        } else {
            selectedTimesheetCodes.push(timesheetCode);
        }

        const selectableTimesheetcodes = this.getFilteredTimesheetCodes();
        this.setState({
            selectedTimesheetCodes: selectedTimesheetCodes,
            selectAllChecked: selectableTimesheetcodes.length > 0 && selectedTimesheetCodes.length === selectableTimesheetcodes.length
        });
    };

    onSelectAll = (): void => {
        if (this.isReadOnly()) {
            return;
        }

        const selectedTimesheetCodes = [];
        const t = this.getFilteredTimesheetCodes();
        if (!this.state.selectAllChecked) {
            for (let i = 0, len = t.length; i < len; i++) {
                selectedTimesheetCodes.push(t[i]);
            }
            this.setState({selectAllChecked: true});
        }
        else {
            this.setState({selectAllChecked: false});
        }

        this.setState({selectedTimesheetCodes: selectedTimesheetCodes});
    };

    validate = (): boolean => {
        const errors: PropertyNameIndexer = {};

        if(!this.state.selectedWeek) {
            errors['selectedWeek'] = 'No week selected';
        }

        if (!this.state.selectedSupplier) {
            errors['selectedSupplier'] = 'No supplier selected';
        }

        if(this.state.selectedTimesheetCodes.length === 0) {
            errors['selectedTimesheetCodes'] = 'No timesheet code(s) selected';
        }

        this.setState({downloadOpen: false, errors: errors});

        if(Object.keys(errors).length > 0) {
            return false;
        }

        return true;
    };

    exportSelected = (): void => {
        if(!this.validate() || !this.state.selectedSupplier || !this.state.selectedWeek) {
            return;
        }

        const weeklyTimesheetToExport: WeeklyTimesheetToExport = {
            username: this.props.user.impersonatedUser.username,
            hoursPerWeek: this.props.hoursPerWeek,
            ownProjectLeader: this.state.ownProjectLeader,
            clientProjectLeader: this.state.clientProjectLeader,
            hideComments: this.state.hideComments,
            tsCodes: this.state.selectedTimesheetCodes.map(timesheetCode => timesheetCode.id),
            companyId: this.state.selectedSupplier.id,
            startDate: this.state.selectedWeek.startDate
        };

        this.props.exportWeeklyTimesheet(weeklyTimesheetToExport);
        this.props.closeModal();
    };

    exportSelectedExcel = (): void => {
        if(!this.validate() || !this.state.selectedSupplier || !this.state.selectedWeek) {
            return;
        }

        const weeklyTimesheetToExport: WeeklyTimesheetToExport = {
            username: this.props.user.impersonatedUser.username,
            hoursPerWeek: this.props.hoursPerWeek,
            ownProjectLeader: this.state.ownProjectLeader,
            clientProjectLeader: this.state.clientProjectLeader,
            hideComments: this.state.hideComments,
            tsCodes: this.state.selectedTimesheetCodes.map(timesheetCode => timesheetCode.id),
            companyId: this.state.selectedSupplier.id,
            startDate: this.state.selectedWeek.startDate
        };

        this.props.exportWeeklyTimesheetExcel(weeklyTimesheetToExport);
        this.props.closeModal();
    };

    getFilteredSuggestions = (): StoreSupplier[] => {
        return this.props.suppliers.slice().filter(s => s.name.toLocaleLowerCase().includes(this.state.supplierFilterValue.toLocaleLowerCase()));
    };

    onSuggestionsFetchRequested = ({value}: { value: string }) => {
        this.setState({supplierFilterValue: value});
    };

    getSuggestionValue: GetSuggestionValue<StoreSupplier> = (supplier: StoreSupplier): any => supplier;
    renderSuggestion = (supplier: StoreSupplier) => <div>{supplier.name}</div>;

    onBlur = (supplier: StoreSupplier) => {
        if(this.state.selectedSupplierValue === '') {
            this.setState({selectedSupplier: null});
            return;
        }

        if(this.state.selectedSupplier === null) {
            if (supplier === null) {
                this.setState({selectedSupplierValue: ''});
                return;
            }

            if (supplier !== null && !UserHelper.isImpersonating(this.props.user)) {
                this.props.updateSetting('defaultCompanyForTemplate', {id: supplier.id, name: supplier.name});
                const newSupplier = {
                    id: supplier.id,
                    name: supplier.name,
                    isWritable: false
                };
                this.setState({
                    selectedSupplier: newSupplier,
                    selectedSupplierValue: supplier.name
                });
            }
        }
    };

    clickEvent = (event: Event) => {
        if (this.state.downloadOpen &&
            event.target !== this.pdfButton &&
            event.target !== this.excelButton &&
            event.target !== this.downloadDiv &&
            event.target !== this.downloadCaret &&
            event.target !== this.downloadButton) {
            this.setState({downloadOpen: false});
        }
    };

    onTextEditChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.setState({[event.currentTarget.name]: event.currentTarget.value} as any);

    getFilteredTimesheetCodes = (): StoreTimesheetCode[] => {
        return this.props.timesheetCodes.slice().filter(timesheetCode => this.hasEntries(timesheetCode));
    };

    componentDidMount() {
        if (document.body) {
            document.body.addEventListener('click', (event: Event) => this.clickEvent(event));
        }
    }

    componentWillUnmount() {
        if (document.body) {
            document.body.removeEventListener('click', (event: Event) => this.clickEvent(event));
        }
    }

    render() {
        const inputProps: InputProps<StoreSupplier> = {
            placeholder: 'required',
            value: this.state.selectedSupplierValue as string,
            onChange: (event: React.FormEvent<HTMLElement>, newValue: { newValue: any }) => this.onChange(event, newValue),
            onBlur: (_, {highlightedSuggestion}: any) => this.onBlur(highlightedSuggestion)
        };
        return (
            <ClosableModal
                title={'EXPORT WEEKLY TIMESHEET'}
                closeModal={this.props.closeModal}
                marginBottom={19}>
                <div/>
                <ScrollBar className="export-weekly-modal-scrollbar"
                           renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>} >
                <div className="form-group">
                    <label>Hours per week:</label>
                    <ContractHoursToggle />
                </div>
                <div className="export-weekly-container">
                    <div id="weekSegment" className="form-group">
                        <label data-required={this.state.errors['selectedWeek']} className="export-weekly-timesheet-modal__required">Select week:</label>
                        <div className="weeks-container">
                            {this.props.period.weeks.map(week =>
                                <div key={week.weekNumber} className="week-select"
                                    onClick={() => this.onWeekClick(week)}>
                                    <div className={'week-select-checkbox' + (this.hasWeekNumber() ? ' readonly': '')}>
                                        <input type="checkbox"
                                                checked={this.isWeekSelected(week)}
                                                readOnly />
                                    </div>
                                    <div className="week-select-number">
                                        <div className="week-select-weeknumber">Week {week.weekNumber}</div>
                                        <div className="week-period">
                                            <div className="start-date week-period-date">
                                                {DateHelper.getFormattedDate(week.startDate,'/')}
                                            </div>
                                            <div className="week-period-dash">-</div>
                                            <div className="end-date week-period-date">
                                                {DateHelper.getFormattedDate(week.endDate,'/')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="selectTsCodeSegment" className="form-group">
                        <label data-required={this.state.errors['selectedTimesheetCodes']} className="export-weekly-timesheet-modal__required">Select timesheet code to export:</label>
                        <div className={`exportweekly-timesheetcodes-container ${(this.isReadOnly() ? ' readonly' : '')}`}>
                            {this.getFilteredTimesheetCodes().length > 0 ?
                                <div className="exportweekly-selectAll" onClick={this.onSelectAll}>
                                    <input type="checkbox" className="exportweekly-selectAll-checkbox"
                                        checked={this.state.selectAllChecked}
                                        readOnly />
                                    <div className="exportweekly-selectAll-checkbox-text">SELECT ALL</div>
                                </div> : null}
                            <div className="scrollBarContainer">
                                <ScrollBar>
                                    {this.getFilteredTimesheetCodes().map(timesheetCode =>
                                        <div key={timesheetCode.id} className="exportweekly-tscode-select"
                                            onClick={() => this.onTimesheetCodeClick(timesheetCode)}>
                                            <div className={'exportweekly-tscode-select-checkbox' + (this.isReadOnly() ? ' readonly': '')}>
                                                <input type="checkbox"
                                                    checked={this.isTimesheetCodeSelected(timesheetCode)}
                                                    readOnly />
                                            </div>
                                            <div className="exportweekly-tscode-select-name">
                                                {timesheetCode.name}
                                            </div>
                                        </div>)}
                                </ScrollBar>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label data-required={this.state.errors['selectedSupplier']} className="export-weekly-timesheet-modal__required">Company background image:</label>
                        <Autosuggest suggestions={this.getFilteredSuggestions()}
                                    onSuggestionsClearRequested={() => {return;}}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={inputProps}
                                    highlightFirstSuggestion />
                    </div>
                    <TextInput name="ownProjectLeader"
                            label="Project leader"
                            onChange={this.onTextEditChange}/>
                    <TextInput name="clientProjectLeader"
                            label="Client project leader"
                            onChange={this.onTextEditChange}/>
                    <div className="form-group">
                        <label>Hide comments</label>
                        <input className="hide-comments-checkbox" type="checkbox" checked={this.state.hideComments} onChange={() => this.setState({hideComments: !this.state.hideComments})} />
                    </div>
                    <div className="export-weekly-timesheet-modal__required_message">Fields marked with an * are required</div>
                </div>
                <div className="closable-modal-line">
                    <div className="line"/>
                </div>
                <div className="export-selected-weekly-button-container">
                    <div ref={(el: HTMLDivElement) => this.downloadButton = el} className={'export-selected-download' + (this.state.downloadOpen ? ' export-selected-download-open' : '')} onClick={() => this.setState({downloadOpen: !this.state.downloadOpen})}>
                        <div ref={(el: HTMLDivElement) => this.downloadDiv = el}>DOWNLOAD</div><i ref={(el: HTMLDivElement) => this.downloadCaret = el} className="fas fa-sort-up"/>
                        {
                            this.state.downloadOpen ? <div className="export-selected-buttons"><input
                            ref={(el: HTMLInputElement) => this.excelButton = el}
                            className="export-selected-button"
                            type="submit" value="EXCEL"
                            onClick={this.exportSelectedExcel}
                        />
                        <input
                            ref={(el: HTMLInputElement) => this.pdfButton = el}
                            className="export-selected-button"
                            type="submit" value="PDF"
                            onClick={this.exportSelected}
                        /></div> : null
                        }
                    </div>
                    <div className="closable-modal-cancel" onClick={this.props.closeModal}>CANCEL</div>
                </div>
                </ScrollBar>
            </ClosableModal>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    period: Period,
    timesheetCodes: StoreTimesheetCode[],
    weekNumber: number | undefined,
    closeModal: () => void
};

type State = {
    ownProjectLeader: string,
    clientProjectLeader: string,
    selectedSupplierValue: string | null,
    selectedSupplier: StoreVisibleSupplier | null,
    timesheetCodes: StoreTimesheetCode[],
    selectedTimesheetCodes: StoreTimesheetCode[],
    selectAllChecked: boolean,
    selectedWeek: Week | null,
    downloadOpen: boolean,
    hideComments: boolean,
    errors: PropertyNameIndexer,
    supplierFilterValue: string
};

function mapStateToProps(state: StoreState, prevProps: Props) {
    return {
        period: prevProps.period,
        user: state.user,
        timesheetCodes: prevProps.timesheetCodes,
        weekNumber: prevProps.weekNumber,
        suppliers: state.suppliers.allSuppliers,
        settings: state.settings,
        hoursPerWeek: state.settings.defaultContractHours,

        closeModal: prevProps.closeModal
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({clearSuppliers, getSuppliers, exportWeeklyTimesheet, exportWeeklyTimesheetExcel, updateSetting}, dispatch);
}

export default connector(ExportWeeklyTimesheetModal);
