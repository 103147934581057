import { Day, Period, Week } from '../typescript/dateTypes';

class DateHelper {
    static months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    static getNormalizedDate(date: Date): Date {
        date = new Date(date);
        const normalizedDate = new Date();

        normalizedDate.setDate(1);
        normalizedDate.setFullYear(date.getFullYear());
        normalizedDate.setMonth(date.getMonth());
        normalizedDate.setDate(date.getDate());

        return normalizedDate;
    }

    static isInvalidDate(date: Date): boolean {
        return date.toString() === new Date('').toString();
    }

    static createDateFromServerDate(date: string): Date {
        const year = Number.parseInt(date.substring(0,4));
        const month = Number.parseInt(date.substring(5,7)) - 1; //js month offset
        const day = Number.parseInt(date.substring(8,10));

        return new Date(year, month, day);
    }

    static dayDiff(firstDate: Date, secondDate: Date): number {
        if (firstDate.toDateString() === secondDate.toDateString()) {
            return 0;
        }

        const firstOffset = firstDate.getTimezoneOffset();
        const secondOffset = secondDate.getTimezoneOffset();

        if(firstOffset > secondOffset) {
            secondDate = this.addMinutes(secondDate, firstOffset - secondOffset);
        } else if (firstOffset < secondOffset){
            secondDate = this.addMinutes(secondDate, secondOffset - firstOffset);
        }

        return Math.floor(((secondDate as unknown as number) - (firstDate as unknown as number))/86400000) + 1; // +1 to include last day
    }

    static getPeriod(startDate: Date, endDate: Date): Period {
        const days = [];
        const weeks: Week[] = [];
        const daysInPeriod = this.dayDiff(startDate, endDate);

        for (let i = 0; i < daysInPeriod; i++) {
            const day = DateHelper.getNormalizedDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i));

            days[i] = {
                dateString: day.toDateString(),
                dayOfWeek: day.getDay(),
                dayOfMonth: day.getDate()
            };

            let start = DateHelper.getMonday(day);
            start.setHours(0, 0, 1, 0);
            start = new Date(start.getTime() - (start.getTimezoneOffset() * 60000));

            const end = DateHelper.addDays(DateHelper.getMonday(day), 6);
            end.setHours(23, 59, 59, 0);

            const week: Week = {
                weekNumber: DateHelper.getWeekNumber(day),
                startDate: start,
                endDate: end
            };

            if (weeks.findIndex(w => w.weekNumber === week.weekNumber) === -1) {
                weeks.push(week);
            }
        }
        return {
            startDate,
            endDate,
            days,
            weekDayIndexes: days.map((day, index) => !DateHelper.isWeekend(day) ? index : -1).filter(index => index !== -1),
            month: startDate.getMonth() +1,
            year: startDate.getFullYear(),
            weeks: weeks
        };
    }

    static formatDayOfWeek(day: Day): string {
        switch (day.dayOfWeek) {
            case 0:
                return 'SUN';
            case 1:
                return 'MON';
            case 2:
                return 'TUE';
            case 3:
                return 'WED';
            case 4:
                return 'THU';
            case 5:
                return 'FRI';
            case 6:
                return 'SAT';
            default:
                throw RangeError('Invalid day of week.');
        }
    }

    static formatDayOfMonth(day: Day): string {
        return ('0' + day.dayOfMonth).slice(-2);
    }

    static isWeekend(day: Day): boolean {
        return day.dayOfWeek === 0 || day.dayOfWeek === 6;
    }

    static monthName(month: number): string {
        return DateHelper.months[month -1];
    }

    static isToday(day: Day): boolean {
        return day.dateString === new Date().toDateString();
    }

    static getMonday(date: Date): Date {
        date = this.getNormalizedDate(date);
        const day = date.getDay() || 7;

        if(day !== 1) {
            date.setHours(-24 * (day - 1));
        }

        return date;
    }

    static addDays(date: Date, days: number): Date {
        const day = this.getNormalizedDate(date);
        day.setDate(day.getDate() + days);
        return day;
    }

    static addHours(date: Date, hours: number): Date {
        const day = this.getNormalizedDate(date);
        day.setHours(day.getHours() + hours);
        return day;
    }

    static addMinutes(date: Date, minutes: number): Date {
        const day = this.getNormalizedDate(date);
        day.setMinutes(day.getMinutes() + minutes);
        return day;
    }

    static getWeekNumber(date: Date): number {
        // Copy date so don't modify original
        date = this.getNormalizedDate(date);

        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        date.setDate(date.getDate() + 4 - (date.getDay()||7));
        // Get first day of year
        const yearStart: any= this.getNormalizedDate(new Date(date.getFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        const weekNo = Math.ceil((((date as unknown as number - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return weekNo;
    }

    static getFullFormattedDate(date: Date, separator?: string): string {
        separator = separator === undefined ? '' : separator;
        const pad = (n: number) => n < 10 ? '0' + n : n;
        const newDate = this.getNormalizedDate(date);
        const locale = 'en-us';
        const month = newDate.toLocaleString(locale, { month: 'long' });

        return [pad(newDate.getDate()), month, newDate.getFullYear()].join(separator);
    }

    static getFormattedDate(date: Date, separator?: string): string {
        separator = separator === undefined ? '' : separator;
        const pad = (n: number) => n < 10 ? '0' + n : n;
        const newDate = this.getNormalizedDate(date);
        return [pad(newDate.getDate()), pad(newDate.getMonth() + 1), newDate.getFullYear()].join(separator);
    }

    static getFormattedDateWithTime(date: Date, separator?: string): string {
        separator = separator === undefined ? '' : separator;
        const pad = (n: number) => n < 10 ? '0' + n : n;

        const newDate = new Date(date);

        const formattedDate = [pad(newDate.getDate()), pad(newDate.getMonth() + 1), newDate.getFullYear()].join(separator);
        const formattedTime  = [pad(newDate.getHours()), pad(newDate.getMinutes())].join(':');

        return `${formattedDate} ${formattedTime}`;
    }

    static getIsoFormattedDate(date: Date, separator?: string): string {
        separator = separator === undefined ? '' : separator;
        const pad = (n: number) => n < 10 ? '0' + n : n;
        const newDate = this.getNormalizedDate(date);
        return [newDate.getFullYear(), pad(newDate.getMonth() + 1), pad(newDate.getDate())].join(separator);
    }

    static addMonths(date: Date, months: number): Date {
        date = this.getNormalizedDate(date);
        date.setMonth(date.getMonth() + months);

        return date;
    }

    static firstDateOfMonth(date: Date): Date {
        return this.getNormalizedDate(new Date(date.getFullYear(), date.getMonth(), 1, date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    static lastDateOfMonth(date: Date): Date {
        return this.getNormalizedDate(new Date(date.getFullYear(), date.getMonth() + 1, 0, date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    static firstDateOfWeek(date: Date): Date {
        return this.getNormalizedDate(new Date(date.getFullYear(), date.getMonth(),1));
    }

    static getMondayOfGivenWeek = (dayOfTheWeek: Date) => {
        const first = dayOfTheWeek.getDate() - dayOfTheWeek.getDay() + 1;

        const monday = new Date(dayOfTheWeek.setDate(first));
        return monday;
    };

    static isBetweenDates(beginDate: Date, endDate: Date, date: Date): boolean {
        this.resetTime(beginDate);
        this.resetTime(endDate);
        this.resetTime(date);

        return date.valueOf() >= beginDate.valueOf() && date.valueOf() <= endDate.valueOf();
    }

    static resetTime(date: Date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    }

    static resetTimeZoneOffset(date: Date) : Date {
        return new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
    }

    static getDay(date: Date) {
        const day = this.getNormalizedDate(new Date(date));

        return {
            dateString: day.toDateString(),
            dayOfWeek: day.getDay(),
            dayOfMonth: day.getDate()
        };
    }

    static getDayFromDateString(dateString: string) {
        const day = this.getNormalizedDate(new Date(dateString));

        return {
            dateString: day.toDateString(),
            dayOfWeek: day.getDay(),
            dayOfMonth: day.getDate()
        };
    }

    static isExpiring(expiresOn: Date): boolean {
        return expiresOn >= new Date()
                && expiresOn <= DateHelper.addMinutes(new Date(), 10);
    }

    static isExpired(expiresOn: Date): boolean {
        return new Date() > expiresOn;
    }

    static getFirstWeekday = (days: Day[]): Day => {
        for (const day of days) {
            if(!DateHelper.isWeekend(day)) return day;
        }

        return days[0];
    };

    static periodContainsFirstOfMonth = (period: Period):boolean => period.days.find(e => e.dayOfMonth === 1) !== undefined;

    static getThisMonth = (): number => DateHelper.getNormalizedDate(new Date()).getMonth() +1;
    static getThisYear = (): number => DateHelper.getNormalizedDate(new Date()).getFullYear();

    static isSamePeriod = (period: Period, otherPeriod: Period) => period.month === otherPeriod.month && period.year === otherPeriod.year;
}

export default DateHelper;
