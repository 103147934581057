import AreYouSureModal from './../../../../common/AreYouSureModal';
import DateHelper from '../../../../helpers/dateHelper';
import DigitalSigningModal from './DigitalSigning/DigitalSigningModal';
import ExportMonthlyTimesheetModal from './ExportMonthlyTimesheetModal';
import ExportWeeklyTimesheetModal from './ExportWeeklyTimesheetModal';
import ManageClientTimesheetModal from './ManageClientTimesheetModal';
import React from 'react';

import {Period} from '../../../../typescript/dateTypes';
import {StoreTimesheetCode, StoreTimesheetEntry, StoreTimesheetFromClient} from '../../../../typescript/storeTypes';


class TimesheetCodeTotal extends React.PureComponent<Props, State> {
    state: State = {
        isAreYourSureToCompleteModalOpen: false,
        isAreYourSureToDeleteModalOpen: false,
        isExportTimesheetModalOpen: false,
        isUploadClientTimesheetModalOpen: false,
        isDigitalSigningModalOpen: false
    };

    incompleteTimesheetCode = (): void => {
        if(!this.props.canIncomplete) return;

        this.props.incompleteTimesheetCode();
    };

    completeTimesheetCode = (): void => {
        if (!this.props.canComplete) return;

        if(this.state.isAreYourSureToCompleteModalOpen) {
            this.props.completeTimesheetCode();
            this.setState({isAreYourSureToCompleteModalOpen: false});
            return;
        }

        this.setState({isAreYourSureToCompleteModalOpen: true});
    };

    entriesPresent = (): boolean => {
        return this.props.entries.find(entry => entry.totalHours > 0 || entry.comments) !== undefined;
    };

    deleteTimesheetCode = (): void => {
        if(this.entriesPresent()) return;

        if(this.state.isAreYourSureToDeleteModalOpen) {
            this.props.deleteTimesheetCode();
            this.setState({isAreYourSureToDeleteModalOpen: false});
            return;
        }

        this.setState({isAreYourSureToDeleteModalOpen: true});
    };

    openModal = (): void => {
        this.setState({isExportTimesheetModalOpen: true});
    };

    getTimesheetsFromClientLength = (): string|number => {
        if(this.props.timesheetsFromClient) {
            return this.props.timesheetsFromClient.length;
        }

        return 'X';
    };

    canGetTimesheetsFromClientInfo = () : boolean => {
        return this.props.timesheetCode.isBillable && this.props.canUploadClientTimesheet && this.props.monthViewSelected;
    };

    showClientTimesheetSection = (): boolean => {
        return this.canGetTimesheetsFromClientInfo() && (this.hasEntries() || this.getTimesheetsFromClientLength() as unknown as number > 0);
    };

    hasEntries = (): boolean => {
        return this.props.entries.some(e => e.totalHours > 0);
    };

    getDateOfFirstEntryWithHours = ():string => {
        const entry = this.props.entries.find(e => e.id !== 0);
        if(entry) return entry.date;

        return '';
    };

    componentDidUpdate(prevProps: Props): void {
        const current = this.props.timesheetCode.assignmentIds[0];
        const prev = prevProps.timesheetCode.assignmentIds[0];

        if(this.showClientTimesheetSection() && current && current !== prev) {
            this.props.getTimesheetsFromClientInfo(current);
        }
    }

    componentDidMount() {
        if(this.canGetTimesheetsFromClientInfo()) {
            this.props.getTimesheetsFromClientInfo(this.props.timesheetCode.assignmentIds[0]);
        }
    }

    render() {
        return (
            <div className={'timesheet-code-total-container' + (this.props.timesheetCode.expanded ? '' : ' align-center')}>
                <div className="timesheet-code-total">
                    {this.props.entries.reduce((acc: number, cur: StoreTimesheetEntry) => acc + cur.totalHours, 0).toFixed(2)}
                </div>
                {!this.props.timesheetCode.isReadOnly && this.entriesPresent() ?
                <div className="circle-container">
                    <div className={'circle hover' + (this.props.monthViewSelected ? ' tooltip-export-monthly' : ' tooltip-export-weekly')}>
                        <div className="export-tscode no-select" onClick={this.openModal} />
                    </div>
                    {this.state.isExportTimesheetModalOpen ?
                         this.props.monthViewSelected ?
                            <ExportMonthlyTimesheetModal
                                period={this.props.period}
                                timesheetCodes={[this.props.timesheetCode]}
                                closeModal={() => this.setState({isExportTimesheetModalOpen: false})} />
                            : <ExportWeeklyTimesheetModal
                                period={this.props.period}
                                timesheetCodes={[this.props.timesheetCode]}
                                weekNumber={DateHelper.getWeekNumber(DateHelper.getNormalizedDate(new Date(this.getDateOfFirstEntryWithHours())))}
                                closeModal={() => this.setState({isExportTimesheetModalOpen: false})} />
                    : null
                    }
                </div>
                : <div className="fixed-circle-block"/>}

                {!this.props.timesheetCode.isReadOnly && !this.entriesPresent() && this.props.monthViewSelected && !this.props.completed && this.props.canDeleteTimesheetCode && this.getTimesheetsFromClientLength() === 0 ?
                <div className="circle-container">
                    <div className="circle hover tooltip-remove-assignment">
                        <div className="trashbin" onClick={this.deleteTimesheetCode}/>
                    </div>
                </div> : <div className="fixed-circle-block"/>}
                {!this.props.timesheetCode.isReadOnly && this.props.monthViewSelected ?
                <div className="circle-container">
                    <div className={'circle ' +
                        (this.props.completed ? (this.props.canIncomplete ? ' hover-can-incomplete' : ' hover-completed') : this.props.canComplete ? ' hover' : '')
                            + (this.props.completed ? (this.props.canIncomplete ? ' tooltip-uncomplete-assignment' : '') : (this.props.canComplete ? ' tooltip-complete-assignment' : ''))}>
                        <div className={'complete-timesheet' + (((this.props.canComplete && !this.props.completed) || (this.props.canIncomplete && this.props.completed)) ? ' complete-timesheet-hover' : '')}
                                onClick={(this.props.completed ? this.incompleteTimesheetCode : this.completeTimesheetCode)}>
                                {'\u2713'}
                        </div>
                    </div>
                </div> : <div className="fixed-circle-block"/>}
                {this.showClientTimesheetSection() ?
                    <div className="circle-container">
                        <div className="circle hover manage-clienttimesheet tooltip-manage-clienttimesheet"
                            onClick={() => this.setState({isUploadClientTimesheetModalOpen: true})}>
                            <div className="manage-clienttimesheet-icon">
                                <i className="fas fa-file-contract"/>
                                <div className="manage-clienttimesheet-count">{this.getTimesheetsFromClientLength()}</div>
                            </div>
                        </div>
                    </div>
                : <div className="fixed-circle-block"/>
                }
                {this.hasEntries() ?
                    <div className="circle-container">
                        <div className="circle hover last-circle tooltip-digital-signing">
                            <div className="digital-signing" onClick={() => this.setState({isDigitalSigningModalOpen: true})}>
                                <i className="fas fa-file-signature" />
                            </div>
                        </div>
                    </div>
                : <div className="fixed-circle-block"/>}
                {this.state.isAreYourSureToCompleteModalOpen ?
                    <AreYouSureModal title={'Are you sure you want to complete this assignment?'}
                                        yes={this.completeTimesheetCode}
                                        closeModal={() => this.setState({isAreYourSureToCompleteModalOpen:false})} /> : null
                }
                {this.state.isAreYourSureToDeleteModalOpen ?
                    <AreYouSureModal title={'Are you sure you want to remove this assignment?'}
                                        yes={this.deleteTimesheetCode}
                                        closeModal={() => this.setState({isAreYourSureToDeleteModalOpen:false})} /> : null
                }
                {this.state.isUploadClientTimesheetModalOpen ?
                    <ManageClientTimesheetModal
                        timesheetCodeId={this.props.timesheetCode.id}
                        closeModal={() => this.setState({isUploadClientTimesheetModalOpen: false})} />
                : null}
                {this.state.isDigitalSigningModalOpen ?
                    <div className="digital-signing">
                        <DigitalSigningModal
                            isWeekView={!this.props.monthViewSelected}
                            timesheetCodes={[this.props.timesheetCode]}
                            closeModal={()=> this.setState({isDigitalSigningModalOpen: false})}/>
                    </div>
                : null}
            </div>
        );
    }
}

type Props = {
    entries: StoreTimesheetEntry[],
    timesheetsFromClient: StoreTimesheetFromClient[],
    completed: boolean,
    monthViewSelected: boolean,
    canIncomplete: boolean,
    canComplete: boolean,
    period: Period,
    timesheetCode: StoreTimesheetCode,

    canDeleteTimesheetCode: boolean,
    canUploadClientTimesheet: boolean,
    getTimesheetsFromClientInfo: (assignmentId: number) => void,
    completeTimesheetCode: () => void,
    incompleteTimesheetCode: () => void,
    deleteTimesheetCode: () => void
};

type State = {
    isExportTimesheetModalOpen: boolean,
    isAreYourSureToCompleteModalOpen: boolean,
    isAreYourSureToDeleteModalOpen: boolean,
    isUploadClientTimesheetModalOpen: boolean,
    isDigitalSigningModalOpen: boolean
};

export default TimesheetCodeTotal;
