import ClosableModal from './../../../../common/ClosableModal';
import React from 'react';

const TimesheetCompleteModal = (props: Props) => {
    return (
        <ClosableModal title="COMPLETE TIMESHEET"
                       closeModal={props.closeModal}
                       marginBottom={30}>
            <div className="timesheet-complete-month-year">Complete timesheet
                for {props.month} {props.year}?
            </div>
            <div className="timesheet-complete-attention">Attention:</div>
            <div className="timesheet-complete-message">There are currently {props.days} day(s) with
                less than 7.6 hours submitted.
            </div>
            <div className="closable-modal-line">
                <div className="line"/>
            </div>
            <div className="timesheet-complete-button-container">
                <input className="timesheet-complete-complete" type="submit" value="COMPLETE"
                       onClick={props.complete}/>
                <div className="closable-modal-cancel" onClick={props.closeModal}>CANCEL</div>
            </div>
        </ClosableModal>
    );
};

type Props = {
    closeModal: () => void,
    days: number,
    complete: () => void,
    month: string,
    year: number
};

export default TimesheetCompleteModal;
