import { StoreActuals } from '../../../../typescript/storeTypes';
import React from 'react';

export class ImportActualsRow extends React.Component<Props> {

    render() {
        return (
            <div className={`import-actuals-row ${this.props.row.processStatus === undefined || this.props.row.processStatus === 'Ready to process' ? '' : 'in-error'}`}>
                <div className="import-actuals-row-username">{this.props.row.username}</div>
                <div className="import-actuals-row-tscode">{this.props.row.tsCode}</div>
                <div className="import-actuals-row-workday">{this.props.row.workDay}</div>
                <div className="import-actuals-row-workmonth">{this.props.row.workMonth}</div>
                <div className="import-actuals-row-workyear">{this.props.row.workYear}</div>
                <div className="import-actuals-row-actualhours">{this.props.row.actualHours}</div>
                <div className="import-actuals-row-extrahours">{this.props.row.extraHours}</div>
                <div className="import-actuals-row-standbyhours">{this.props.row.standByHours}</div>
                <div className="import-actuals-row-interventionhours">{this.props.row.interventionHours}</div>
                <div className="import-actuals-row-comments">{this.props.row.comments}</div>
                <div className="import-actuals-row-processstatus">{this.props.row.processStatus}</div>
            </div>
        );
    }
}

type Props = {
    row: StoreActuals
};

export default ImportActualsRow;
