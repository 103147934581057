import {StoreTimesheetEntry} from '../../../../typescript/storeTypes';

import React from 'react';

class TimesheetCodeDayTotal extends React.Component<Props> {
    shouldComponentUpdate(nextProps: Props) {
        if (nextProps.entries.length !== this.props.entries.length)
            return true;

        for (let i = 0; i < nextProps.entries.length; i++)
            if (nextProps.entries[i] !== this.props.entries[i])
                return true;

        return false;
    }

    render() {
        const totalHours = this.props.entries.reduce((acc: number, cur: StoreTimesheetEntry) => acc + cur.totalHours, 0);
        return (
            <div className="day-hours-container">
                <div className="day-hours">
                    <input className="hours-input" value={totalHours ? + totalHours.toFixed(2) : ''} readOnly/>
                </div>
            </div>
        );
    }
}

type Props = {
    entries: StoreTimesheetEntry[]
};

export default TimesheetCodeDayTotal;
