import { Action } from '../../../../typescript/actionTypes';
import { Filter } from '../../../../typescript/sentInvoiceTypes';
import { StoreSentInvoice } from '../../../../typescript/storeTypes';
import ApiHelper from '../../../../helpers/apiHelper';
import DateHelper from '../../../../helpers/dateHelper';
import FormatHelper from '../../../../helpers/formatHelper';
import InfiniteScroll from 'react-infinite-scroller';
import React from 'react';
import TextInput from '../../../../common/TextInput';

class SentInvoice extends React.Component<Props, State> {
    state: State = {
        currentPage: 1,
        sortBy: 'invoicedate',
        sortOrder: 'desc',
        filter: {
            supplier: '',
            invoicenumber: '',
            customer: '',
            amount: '',
            paid: ''
        }
    };

    callResetSentInvoices = (): void => {
        this.props.clearSentInvoices();
        this.setState({currentPage: 1}, () => {
            if(this.props.supplierId === 0 || this.props.invoiceDateRangeStart === null || this.props.invoiceDateRangeEnd === null) return;
            this.props.getSentInvoices(this.props.supplierId, 1, this.props.invoiceDateRangeStart, this.props.invoiceDateRangeEnd, this.state.filter, this.state.sortBy, this.state.sortOrder);
        });
    };

    fetchSentInvoices = (): void => {
        const pageToUse = this.state.currentPage + 1;

        this.setState({currentPage: pageToUse}, () => {
            this.props.getSentInvoices(this.props.supplierId, pageToUse, this.props.invoiceDateRangeStart, this.props.invoiceDateRangeEnd, this.state.filter, this.state.sortBy, this.state.sortOrder);
        });
    };

    changeFilteringDebounce: () => void = ApiHelper.debounce(() => this.changeFiltering(), 500);

    changeFiltering = () => {
        localStorage.setItem('sentInvoice_Filter', JSON.stringify(this.state.filter));

        this.callResetSentInvoices();
    };

    changeSorting = (sortBy: string) => {
        let sortOrder = this.state.sortOrder;
        if(this.state.sortBy === sortBy) {
            sortOrder = this.state.sortOrder === 'desc' ? 'asc' : 'desc';
        } else {
            sortOrder = 'asc';
        }
        localStorage.setItem('sentInvoice_SortBy', sortBy);
        localStorage.setItem('sentInvoice_SortOrder', sortOrder);
        this.props.clearSentInvoices();
        this.setState({currentPage: 1, sortOrder: sortOrder, sortBy: sortBy}, () => {
            this.props.getSentInvoices(this.props.supplierId, 1, this.props.invoiceDateRangeStart, this.props.invoiceDateRangeEnd, this.state.filter, this.state.sortBy, this.state.sortOrder);
        });
    };

    renderHeaderReadonlyItem = (name: string, title: string) => {
        return (
            <div className={`sent-invoice__list-header__${name}`}>
                <i className={`fas ${this.state.sortBy !== name ? '' : this.state.sortOrder === 'asc' ? 'fa-sort-up' : 'fa-sort-down'}`}  onClick={() => this.changeSorting(name)}/>
                <div id={`js-sent-invoice__list-header__${name}__title`} className={`sent-invoice__list-header__${name}__title`} onClick={() => this.changeSorting(name)}>{title}</div>
            </div>
        );
    };

    renderFilterPlaceholder = (name: string) => {
        return (
            <div className={`sent-invoice__list-filter__${name}`}/>
        );
    };

    renderFilterTextBox = (name: string) => {
        return (
            <div className={`sent-invoice__list-filter__${name}`}>
                <div className={`sent-invoice__list-filter__${name}__input`}>
                    <TextInput search name={`filter__${name}`} value={this.state.filter[name]} onChange={(event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
                        const filter = Object.assign({}, this.state.filter);
                        filter[name] = event.currentTarget.value;
                        this.setState({filter: filter} as any, this.changeFilteringDebounce);
                    }}/>
                </div>
            </div>
        );
    };

    renderFilterCheckbox = (name: string) => {
        return (
            <div className={`sent-invoice__list-filter__${name}`}>
                <div className={`sent-invoice__list-filter__${name}__input`}>
                    <div className="" onClick={() => {
                            const filter = Object.assign({}, this.state.filter);
                            if(filter[name] === '1')
                            {
                                filter[name] = '0';
                            } else if(filter[name] === '0') {
                                filter[name] = '';
                            } else {
                                filter[name] = '1';
                            }
                            this.setState({filter: filter} as any, this.changeFilteringDebounce);
                        }} >{this.state.filter[name] === '' ? 'ALL' :
                            (this.state.filter[name] === '1' ?
                                <i className="fas fa-check"/> :
                                <i className="fas fa-times"/> )
                        }</div>
                    </div>
                </div>
        );
    };

    sortInvoices = (): StoreSentInvoice[] => {
        const sentInvoices = this.props.sentInvoices.slice();

        sentInvoices.sort((i1,i2) => i1.currentCount - i2.currentCount);

        return sentInvoices;
    };

    hasMoreSentInvoices = (): boolean => {
        if(this.props.sentInvoices.length === 0) return false;

        const currentCount = Math.max(...this.props.sentInvoices.map(i => i.currentCount));
        const totalCount = this.props.sentInvoices[0].totalCount;

        return currentCount < totalCount;
    };

    componentDidMount() {
        const localStorageItem = localStorage.getItem('sentInvoice_Filter');
        const filter =  JSON.parse(localStorageItem as string);
        if(filter && filter !== this.state.filter) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({filter: filter});
        }

        const sortBy =  localStorage.getItem('sentInvoice_SortBy');
        if(sortBy && sortBy !== this.state.sortBy) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({sortBy: sortBy});
        }
        const sortOrder =  localStorage.getItem('sentInvoice_SortOrder');
        if(sortOrder && sortOrder !== this.state.sortOrder) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({sortOrder: sortOrder});
        }
    }

    componentWillUnmount() {
        this.props.clearSentInvoices();
    }

    render() {
        return (
            <div className="sent-invoice">
                <React.Fragment>
                    {this.props.supplierId > 0 ?
                    <React.Fragment>
                        <div className="sent-invoice__list-item sent-invoice__list-item--fixed">
                            {this.renderHeaderReadonlyItem('invoicedate', 'Invoice Date')}
                            {this.renderHeaderReadonlyItem('supplier', 'Supplier')}
                            {this.renderHeaderReadonlyItem('invoicenumber', 'Invoice Number')}
                            {this.renderHeaderReadonlyItem('customer', 'Customer')}
                            {this.renderHeaderReadonlyItem('amount', 'Amount')}
                            {this.renderHeaderReadonlyItem('paid', 'Paid')}
                        </div>
                        <div className="sent-invoice__list-filter">
                            {this.renderFilterPlaceholder('invoicedate')}
                            {this.renderFilterTextBox('supplier')}
                            {this.renderFilterTextBox('invoicenumber')}
                            {this.renderFilterTextBox('customer')}
                            {this.renderFilterTextBox('amount')}
                            {this.renderFilterCheckbox('paid')}
                        </div>
                    </React.Fragment> : null}
                    {this.props.sentInvoices.length > 0 ?
                        <div className="sent-invoice__scrollbarcontainer">
                            <InfiniteScroll
                                id="infini-sent-invoice"
                                loadMore={this.fetchSentInvoices.bind(this)}
                                loader={<div key="sent-invoice-loading" className="sent-invoice__loading"><div className="fas fa-circle-notch fa-spin"/></div>}
                                hasMore={this.hasMoreSentInvoices()}
                                pageStart={1}
                                initialLoad={false}
                                useWindow={false}
                            >
                                {this.sortInvoices().map(i =>
                                    <div key={i.invoiceId} id={`${i.invoiceId}`} onDoubleClick={() => this.props.getInvoice(i.invoiceId, i.invoiceNumber)} className="sent-invoice__list-item">
                                        <div className="sent-invoice__list-item__invoicedate">{DateHelper.getFormattedDate(i.invoiceDate, '/')}</div>
                                        <div className={`sent-invoice__list-item__supplier ${i.supplierId === this.props.supplierId ? 'sent-invoice__list-item__owner' : ''}`}>{i.supplier}</div>
                                        <div className="sent-invoice__list-item__invoicenumber">{i.invoiceNumber}</div>
                                        <div className={`sent-invoice__list-item__customer ${i.customerId === this.props.supplierId ? 'sent-invoice__list-item__owner' : ''}`}>{i.customer}</div>
                                        <div className="sent-invoice__list-item__amount">{FormatHelper.getFormatted2DecimalNumber(i.amount)}€</div>
                                        <div className="sent-invoice__list-item__paid">{i.paid ? <i className="fas fa-check" aria-hidden="true" /> : <i className="fas fa-times" aria-hidden="true" />}</div>
                                        <div className="sent-invoice__list-item__download"
                                            onClick={() => this.props.getInvoice(i.invoiceId, i.invoiceNumber)}>
                                                <i className="fas fa-cloud-download-alt fa-2x" />
                                        </div>
                                    </div>
                                )}
                            </InfiniteScroll>
                        </div>
                    : null }
                </React.Fragment>
            </div>
        );
    }
}

type Props = {
    supplierId: number,
    sentInvoices: StoreSentInvoice[],
    invoiceDateRangeStart: string,
    invoiceDateRangeEnd: string,

    getSentInvoices(supplierId: number, page: number, invoiceDateRangeStart: string, invoiceDateRangeEnd: string, filter: Filter, sortBy: string, sortOrder: string): Action,
    getInvoice(invoiceId: number, invoiceNumber: string): Action,
    clearSentInvoices(): Action
};

type State = {
    currentPage: number,
    sortBy: string,
    sortOrder: string,
    filter: Filter
};

export default SentInvoice;
